import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ShareIcon from "@mui/icons-material/Share";
import PlaceIcon from "@mui/icons-material/Place";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { axiosAPI } from "../../Axios/Axios";
import { differenceInDays } from "date-fns";
import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import {
  errorNotify,
  useStyles,
  warningNotify,
  succesNotify,
} from "../../CommonCode/Commonfunc";
import EnquiryMessageModal from "./EnquiryMessageModal";
import { useSelector } from "react-redux";
import JobSeekerActivationModal from "../JobseekerProfile/JobseekerDashboard/JobSeekerActivationModal";
import CustomButton from "../../Components/CustomButton";
import BackBtn from "../../../Images/left-arrow.png";
import { Textarea } from "@mui/joy";
import { profanitySend } from "../../Profanity/Profanity";
import "./jobseekerstyle.css"


export default function NewJobDetailedPage({ jobid, savefn, refreshState }) {
  const redirect = useNavigate();
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)
  const classes = useStyles();
  const { id } = useParams();
  const [isButtonDisable, setButtonDisable] = useState(false);
  const [jobDetails, setJobDetails] = useState({});
  const [similarJobs, setSimilarJobs] = useState([]);
  const [openEnquiryModal, setEnquiryModal] = useState(false);
  const [showSkeleton, setshowSkeleton] = useState(true);
  const uid = localStorage.getItem("uid");
  const [openActivationModal, setActivationModal] = useState(false);
  const jobSeekerLoginDetails = useSelector(
    (state) => state.jobSeekerloginDetails.loginDetails
  );

  const getjobdetails = async () => {
    if (id || jobid) {
      const result = await axiosAPI.post(
        `/getJobInfo?jobid=${jobid ? jobid : id}`
      );
      const { success, data } = result.data;

      if (!result.data.success) {
        redirect(`/jobList`);
      }
      if (success === true) {
        setJobDetails(data);
        setshowSkeleton(false);
      } else {
        setshowSkeleton(false);
      }
    }
  };

  useEffect(() => {
    getjobdetails();
  }, [refreshState]);

  const getSmiliarJobs = async () => {
    try {
      const result = await axiosAPI.post("/getSimilarJobs", {
        jobid: jobid ? jobid : id,
      });
      const { success, data, message } = result.data;
      if (success) {
        setSimilarJobs(data);
      } else {
        //// console.log(message);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };
  useEffect(() => {
    getjobdetails();
    getSmiliarJobs();
  }, [id, jobid]);

  useEffect(() => {
    const getProfile = async () => {
      try {
        const uid = localStorage.getItem("uid");
        const postdata = {
          jobseekerid: uid,
        };
        const result = await axiosAPI.post("/profile", postdata);
        const { success, data, message } = result.data;

        if (
          data.city === null ||
          data.currentEmployer === null ||
          data.education.length === 0 ||
          data.designation === null ||
          data.experienceLevel === null ||
          data.skills.length === 0 ||
          data.totalExperience === null
        ) {
          localStorage.setItem("applystatus", false);
        } else {
          localStorage.setItem("applystatus", true);
        }
      } catch (error) {
        //// console.log(error.message);
      }
    };

    getProfile();
  }, []);

  const ApplyjobProfanity = () => {
    if (jobDetails.receiveCoverLetter) {
      const data = {
        coverLetter: coverLetter,
      };

      let profanityDetected;
      for (const key in data) {
        if (profanitySend(ProfanityList, data[key])) {
          profanityDetected = true;
          break;
        } else {
          profanityDetected = false;
        }
      }

      if (profanityDetected == false) {
        ApplyJob();
      } else {
        warningNotify(Labels.ProfanityLabel);
      }
    } else {
      ApplyJob();
    }
  };

  const ApplyJob = async () => {
    if (localStorage.getItem("applystatus") == "true") {
      setButtonDisable(true);
      if (jobDetails.receiveCoverLetter) {
        if (coverLetter) {
          const postData = {
            jobid: jobid ? jobid : id,
            jobseekerid: uid,
            coverLetter,
          };
          try {
            const result = await axiosAPI.post("/applyjob", postData);
            const { success, message } = result.data;

            if (success == true) {
              const jobData = {
                jobId: jobDetails.eja_id,
                jobName: jobDetails.jobName,
              };
              localStorage.setItem("appliedJobDetails", success);
              setButtonDisable(false);
              succesNotify(Labels.jobAppliedSuccess);
              redirect("/similarJobs", { state: jobData });
            } else {
              setButtonDisable(false);
              errorNotify(message);
            }
          } catch (error) {
            setButtonDisable(false);
            errorNotify(error);
          }
        } else {
          errorNotify(Labels.writeCoverLetter);
          setButtonDisable(false);
        }
      } else {
        setButtonDisable(false);
        const postData = {
          jobid: jobid ? jobid : id,
          jobseekerid: uid,
          coverLetter,
        };
        try {
          const result = await axiosAPI.post("/applyjob", postData);
          const { success, message } = result.data;

          if (success === true) {
            const jobData = {
              jobId: jobDetails.eja_id,
              jobName: jobDetails.jobName,
            };
            localStorage.setItem("appliedJobDetails", success);
            setButtonDisable(false);
            succesNotify(Labels.jobAppliedSuccess);
            redirect("/similarJobs", { state: jobData });
          } else {
            setButtonDisable(false);
            errorNotify(message);
          }
        } catch (error) {
          setButtonDisable(false);
          errorNotify(error);
        }
      }
    } else {
      warningNotify(Labels.FillData);
      redirect("/jobseekerProfile");
    }
  };

  const handleLinkClick = () => {
    const email = ""; // Replace with the recipient's email address
    const subject = "Job Application";
    const body = `Job Link : https://50plus.zone/detailedJobView/${
      jobid ? jobid : id
    }`;

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  const sentEnquiryMessage = () => {
    if (!jobSeekerLoginDetails.activatedOn) {
      setActivationModal(true);
    } else {
      setEnquiryModal(true);
    }
  };
  const Labels = useSelector((state) => state.allLabels.labels);

  const [openCoverLetter, setOpenCoverLetter] = useState(false);
  const [coverLetter, setCoverLetter] = useState("");

  const handlecompanynameClick = (employerId) => {
    redirect(`/Companydetailview/${employerId}`);
    //// console.log(`Employer ID clicked: ${employerId}`);
  };

  const savefn1 = async (data) => {
    try {
      savefn(data);
    } catch (error) {
      const savefn = async (data) => {
        const postdata1 = {
          language: "en",
          jobid: data.eja_id,
          type: data.save_status ? "unsave" : "save",
          language: localStorage.getItem("language"),
        };

        try {
          const result = await axiosAPI.post("/saveJob", postdata1);
          const { status, message } = result.data; // Destructure status and message
          if (status === true) {
            succesNotify(message); // Notify success
            getjobdetails();
          }
        } catch (error) {
          //// console.log(error.message);
        }
      };

      savefn(data);
    }
  };

  const daysRemaining = jobDetails?.remainDays;

  const goToPreviousRoute = () => {
    if (window.history.length <= 2) {
      window.location.href = "/jobseekerDashboard";
    } else {
      window.history.back();
    }
  };

  return (
    <>
      <EnquiryMessageModal
        open={openEnquiryModal}
        handleClose={setEnquiryModal}
        jobId={jobid}
        id={id}
      />
      <JobSeekerActivationModal
        open={openActivationModal}
        handleClose={setActivationModal}
      />

      {id && (
        <div className="BackArrow" onClick={goToPreviousRoute}>
            <ArrowBackSharpIcon style={{color:"white" ,fontWeight:600}}/>
        </div>
       
      )}

      <Grid
        item
        xs={12}
        md={12}
        lg={12}
        sx={{
          background: "white",
          padding: "20px 10px",
          height: `85vh`,
          overflowY: "auto",
        }}
      >
        {showSkeleton ? (
          <>
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* Profile Picture Skeleton */}
              <Skeleton variant="circular" width={60} height={60} />
              <Skeleton
                variant="rectangular"
                width={`100%`}
                height={100}
                style={{ marginLeft: 16 }}
              />
            </div>
            <br />
            <Skeleton variant="rectangular" width={"100%"} height={150} />
            <br />
            <Skeleton variant="rectangular" width={"100%"} height={150} />
          </>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                mb={1}
                spacing={4}
              >
                <Stack direction="row">
                  <ShareIcon
                    onClick={handleLinkClick}
                    sx={{
                      color: "#005F8E",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#C0C0C0",
                      },

                      width: 30,
                      height: 30,
                    }}
                  />
                </Stack>
                <Stack direction="row">
                  {jobDetails.save_status === 0 ? (
                    <BookmarkBorderOutlinedIcon
                      onClick={() => {
                        savefn1(jobDetails);
                      }}
                      sx={{
                        color: "#005F8E",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#C0C0C0",
                        },

                        width: 30,
                        height: 30,
                      }}
                    />
                  ) : null}
                  {jobDetails.save_status === 1 ? (
                    <BookmarkAddedIcon
                      onClick={() => {
                        savefn1(jobDetails);
                      }}
                      sx={{
                        color: "#005F8E",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#C0C0C0",
                        },

                        width: 30,
                        height: 30,
                      }}
                    />
                  ) : null}
                </Stack>
              </Stack>
            </Grid>
            {/* Logo Grid */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={3}>
              <Box
                className={classes.box30}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "top",
                  height: "100%",
                }}
              >
                <Avatar
                  src={
                    jobDetails.companyLogo ? `${jobDetails.companyLogo}` : null
                  }
                  style={{ width: 150, height: 160 }}
                >
                  {jobDetails.companyLogo ? null : (
                    <>
                      {jobDetails?.jobName
                        ? jobDetails.jobName
                            .split(" ")
                            .map((word) => word[0].toUpperCase())
                            .join("")
                        : null}
                    </>
                  )}
                </Avatar>
              </Box>
            </Grid>
            {/* Content Grid */}
            <Grid item xs={12} sm={12} md={12} lg={12} xl={9}>
              <Box className={classes.box70}>
                <Typography
                  variant="h5"
                  sx={{ color: "#005F8E", fontWeight: 600 }}
                >
                  {jobDetails?.jobName}
                </Typography>
                <Typography
                  variant="subtilte1"
                  sx={{ fontSize: "1.2rem", cursor: "pointer" }}
                  onClick={() => handlecompanynameClick(jobDetails?.employerid)}
                >
                  {jobDetails?.employer}
                </Typography>

                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "1.2rem",
                    color: "#808080",
                    margin: 0,
                  }}
                >
                  {jobDetails?.companyAddress}, {jobDetails?.country}
                  &nbsp;
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1.1rem", color: "#808080" }}
                >
                  {jobDetails?.createdOn} - {jobDetails?.jobseekers}{" "}
                  {jobDetails?.jobseekers == 1
                    ? Labels?.Applicant
                    : Labels?.Applicants}
                  <br />
                  {daysRemaining > 0 ? (
                    <span
                      style={{
                        color:
                          daysRemaining && daysRemaining < 5
                            ? "red"
                            : "#808080",
                      }}
                    >
                      {daysRemaining}{" "}
                      {daysRemaining == 1
                        ? Labels.dayRemaining
                        : Labels.daysRemaining}
                    </span>
                  ) : (
                    <span style={{ color: "red" }}>{Labels.expired} </span>
                  )}
                </Typography>
              </Box>

              <Box className={classes.box70}>
                <Stack direction="row" spacing={1}>
                  <Grid container direction="row">
                    <Grid item xs={4} direction="column">
                      {jobDetails.jobTypes ? (
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          mx={1}
                          //   my={2}
                        >
                          {jobDetails.jobTypes
                            .split(",")
                            .map((jobType, i) => (
                              <Chip
                                key={jobType}
                                label={jobType}
                                size="medium"
                                sx={{
                                  color: "#005F8E",
                                  backgroundColor:"#F3FBFF",
                                  
                                  borderRadius: 1,
                                  width: "100%",
                                  fontSize: "1.1rem",
                                  margin: "10px 12px 10px 0",
                                  border: "1px solid #005F8E",
                                }}
                              />
                            ))
                            .slice(0, 2)}
                        </Stack>
                      ) : null}

                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        mx={1}
                        // my={2}
                      >
                        <Chip
                          key={""}
                          label={`${jobDetails?.typePercentage}0%`}
                          size="medium"
                          sx={{
                            color: "#005F8E",
                            backgroundColor:"#F3FBFF",
                            
                            borderRadius: 1,
                            width: "100%",
                            fontSize: "1.1rem",
                            margin: "10px 12px 10px 0",
                            border: "1px solid #005F8E",
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={4} direction="column">
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        mx={1}
                        // my={2}
                        // style={{ border: '1px solid red' }}
                      >
                        <Chip
                          // key={index}
                          label={jobDetails?.officePresenceName}
                          size="medium"
                          sx={{
                            color: "#005F8E",
                            backgroundColor:"#F3FBFF",
                            
                            borderRadius: 1,
                            width: "100%",
                            fontSize: "1.1rem",
                            margin: "10px 12px 10px 0",
                            border: "1px solid #005F8E",
                          }}
                        />
                      </Stack>

                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        mx={1}
                        // my={2}
                      >
                        <Chip
                          key={""}
                          label={
                            jobDetails.officePercentage == 100
                              ? `${jobDetails.officePercentage}%`
                              : jobDetails.officePercentage == 0
                              ? `${jobDetails.remotePercentage}%`
                              : jobDetails.officePercentage > 0 &&
                                jobDetails.officePercentage < 100
                              ? `${jobDetails.officePercentage}% (${Labels.Office})`
                              : null
                          }
                          size="medium"
                          sx={{
                            color: "#005F8E",
                            backgroundColor:"#F3FBFF",
                            
                            borderRadius: 1,
                            width: "100%",
                            fontSize: "1.1rem",
                            margin: "10px 12px 10px 0",
                            border: "1px solid #005F8E",
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={4} direction="column">
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        mx={1}
                        // my={2}
                      >
                        <Chip
                          key={""}
                          label={`${jobDetails?.workingHours} hrs/${jobDetails?.hourPeriod}`}
                          size="medium"
                          sx={{
                            color: "#005F8E",
                            backgroundColor:"#F3FBFF",
                            
                            borderRadius: 1,
                            width: "100%",
                            fontSize: "1.1rem",
                            margin: "10px 12px 10px 0",
                            border: "1px solid #005F8E",
                          }}
                        />
                      </Stack>

                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        mx={1}
                        // my={2}
                      >
                        <Chip
                          key={""}
                          label={`${jobDetails?.salary} ${jobDetails?.currencySymbol}/${jobDetails?.salaryPeriodName} `}
                          size="medium"
                          sx={{
                            color: "#005F8E",
                            backgroundColor:"#F3FBFF",
                            
                            borderRadius: 1,
                            width: "100%",
                            fontSize: "1.1rem",
                            margin: "10px 12px 10px 0",
                            border: "1px solid #005F8E",
                          }}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
                <hr />
                <Stack>
                  <Typography fontSize="1.2rem" my={2}>
                    {jobDetails?.seniorityLevel}
                  </Typography>

                  <Grid container spacing={2}>
                    {jobDetails?.travelPercentage?.length ? (
                      <>
                        <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                          <Typography> {Labels.Travelling} </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                          <Typography>:</Typography>
                        </Grid>
                        <Grid item xs={7} sm={7} md={7} lg={8} xl={9}>
                          <Typography>
                            {jobDetails?.travelPercentage} %
                          </Typography>
                        </Grid>
                      </>
                    ) : null}

                    {/* ___________________________________________- */}

                    {jobDetails?.companySize?.length ? (
                      <>
                        <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                          <Typography> {Labels?.Employees} </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                          <Typography>:</Typography>
                        </Grid>
                        <Grid item xs={7} sm={7} md={7} lg={8} xl={9}>
                          <Typography>{jobDetails?.companySize}</Typography>
                        </Grid>
                      </>
                    ) : null}

                    {/* ___________________________________________- */}

                    {jobDetails?.qualifications?.length ? (
                      <>
                        <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                          <Typography> {Labels.Required} </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                          <Typography>:</Typography>
                        </Grid>
                        <Grid item xs={7} sm={7} md={7} lg={8} xl={9}>
                          <Typography fontSize="1.2rem">
                            {jobDetails.qualifications &&
                              Array.isArray(jobDetails.qualifications) &&
                              jobDetails.qualifications.map((data, index) => (
                                <>
                                  {" "}
                                  {data.educationName}
                                  {index !==
                                    jobDetails.qualifications.length - 1 && ","}
                                </>
                              ))}
                          </Typography>
                        </Grid>
                      </>
                    ) : null}

                    {/* ___________________________________________- */}
                    {jobDetails?.additionalQualification && (
                      <>
                        <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                          <Typography> {Labels?.Nicetohave} </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                          <Typography>:</Typography>
                        </Grid>
                        <Grid item xs={7} sm={7} md={7} lg={8} xl={9}>
                          <Typography>
                            {jobDetails.additionalQualification}
                          </Typography>
                        </Grid>
                      </>
                    )}

                    {/* ___________________________________________- */}
                  </Grid>
                </Stack>
                <hr />
                <Stack spacing={2} direction="row">
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <Box sx={{ flexGrow: 1, width: "100%" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Typography sx={{ fontWeight: "bold" }} variant="p">
                            {Labels.languageRequired}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography sx={{ fontWeight: "bold" }} variant="p">
                            {Labels.Spoken}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography sx={{ fontWeight: "bold" }} variant="p">
                            {Labels.Written}
                          </Typography>
                        </Grid>

                        {/* Table Rows */}
                        {jobDetails?.languages?.map((lang, index) => (
                          <React.Fragment key={index}>
                            <Grid item xs={4}>
                              <Typography>{lang.language}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography>{lang.spoken}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography>{lang.written}</Typography>
                            </Grid>
                          </React.Fragment>
                        ))}
                      </Grid>
                    </Box>

                    <Stack direction="row">
                      {jobDetails.additionalLanguage?.length ? (
                        <>
                          <Typography my={2} fontSize="1.1rem" fontWeight={500}>
                            {Labels?.Nicetohave}:&nbsp;
                          </Typography>

                          {Array.isArray(jobDetails.additionalLanguage) &&
                            jobDetails.additionalLanguage.map((data, index) => (
                              <Typography my={2} fontSize="1.1rem" key={index}>
                                &nbsp;{data.language1}
                                {index !==
                                  jobDetails?.additionalLanguage.length - 1 &&
                                  ","}
                                &nbsp;
                              </Typography>
                            ))}
                        </>
                      ) : null}
                    </Stack>
                  </Stack>
                </Stack>
                <hr />
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    my: 2,
                    flexDirection: {
                      xs: "column",
                      md: "row",
                    },
                  }}
                >
                  <Stack direction="row" spacing={2} my={2}>
                    {jobDetails?.status_name === "Applied" ? (
                      <Button
                        variant="contained"
                        disabled
                        sx={{
                          textTransform: "capitalize",
                          borderRadius: "10px",
                          backgroundColor: "#005F8E",
                          "&:hover": {
                            backgroundColor: "#005F8E",
                          },
                          "&:active": {
                            backgroundColor: "#005F8E",
                          },
                          "&:disabled": {
                            color: "white",
                            backgroundColor: "grey",
                          },
                        }}
                      >
                        {Labels?.Applied}
                      </Button>
                    ) : jobDetails?.status_name === "Shortlisted" ? (
                      <Button
                        variant="contained"
                        disabled
                        sx={{
                          textTransform: "capitalize",
                          borderRadius: "10px",
                          backgroundColor: "#005F8E",
                          "&:hover": {
                            backgroundColor: "#005F8E",
                          },
                          "&:active": {
                            backgroundColor: "#005F8E",
                          },
                          "&:disabled": {
                            color: "white",
                            backgroundColor: "grey",
                          },
                        }}
                      >
                        {Labels.Shortlisted}
                      </Button>
                    ) : (
                      <>
                        <Stack>
                          <CustomButton
                            variant="contained"
                            sx={{
                              textTransform: "capitalize",
                              borderRadius: "10px",
                              backgroundColor: "#005F8E",
                              "&:hover": {
                                backgroundColor: "#005F8E",
                              },
                              "&:active": {
                                backgroundColor: "#005F8E",
                              },
                              "&:disabled": {
                                color: "white",
                                backgroundColor: "#005F8E",
                              },
                            }}
                            label={Labels?.ApplyNow}
                            onClick={() => {
                              if (jobDetails.receiveCoverLetter === 1) {
                                setOpenCoverLetter(true);
                              } else {
                                ApplyjobProfanity();
                                // Handle the case when receiveCoverLetter is not 1 (e.g., show an error message)
                              }
                            }}
                            disabled={isButtonDisable}
                          />
                        </Stack>
                      </>
                    )}
                    <Stack>
                      <Button
                        variant="contained"
                        sx={{
                          textTransform: "capitalize",
                          borderRadius: "10px",
                          backgroundColor: "#005F8E",
                          "&:hover": {
                            backgroundColor: "#005F8E",
                          },
                          "&:active": {
                            backgroundColor: "#005F8E",
                          },
                          "&:disabled": {
                            color: "white",
                            backgroundColor: "#005F8E",
                          },
                        }}
                        onClick={sentEnquiryMessage}
                      >
                        {Labels?.enquiry}
                      </Button>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>

              <Box className={classes.box70}>
                {/* --------------------------------who is hiring-------------------------- */}
                {jobDetails.recruiterName != null ? (
                  <Stack>
                    <Box
                      sx={{
                        margin: "15px 10px",
                      }}
                    >
                      {" "}
                      <Typography
                        sx={{
                          fontWeight: 600,
                        }}
                      >
                        {Labels?.WhoisHiring}
                      </Typography>
                    </Box>

                    <Stack direction="row">
                      <Box
                        sx={{
                          minHeight: "80px",
                          maxHeight: "80px",
                          overflowY: "hidden",
                          margin: "10px",
                        }}
                      >
                        <Avatar
                          src={
                            jobDetails.recruiterLogo
                              ? `${jobDetails.recruiterLogo}`
                              : null
                          }
                          alt=" "
                          style={{ width: 70, height: 70, margin: "10px" }}
                        />
                      </Box>

                      <Box
                        sx={{
                          minHeight: "80px",
                          maxHeight: "80px",
                          overflowY: "hidden",
                          margin: "15px",
                        }}
                      >
                        {jobDetails?.recruiterName != null ? (
                          <Typography
                            variant="subtitle1"
                            sx={{
                              color: "#005F8E",
                              fontWeight: 600,
                              // color: jobId === val.eja_id ? "#fff" : "#000",
                            }}
                          >
                            {jobDetails?.recruiterName?.length > 20
                              ? jobDetails?.recruiterName.slice(0, 20) + "..."
                              : jobDetails?.recruiterName}
                          </Typography>
                        ) : null}
                        {jobDetails.designation != null ? (
                          <Typography
                            variant="body1"
                            sx={{
                              color: "#2B4947",
                              // fontWeight: 600,
                              // color: jobId === val.eja_id ? "#fff" : "#000",
                            }}
                          >
                            {jobDetails?.designation?.length > 20
                              ? jobDetails?.designation.slice(0, 20) + "..."
                              : jobDetails?.designation}
                          </Typography>
                        ) : null}
                      </Box>
                    </Stack>
                  </Stack>
                ) : null}
                {/* -------------------- Category -------------------- */}

                <Box
                  sx={{
                    margin: "15px 10px",
                  }}
                >
                  <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                    {Labels?.categories}
                  </Typography>
                  <Chip
                    label={jobDetails?.jobCategoryName}
                    size="medium"
                    sx={{
                      color: "#005F8E",
                      background: "#F3FBFF",
                      borderRadius: 1,
                      minWidth: 120,
                      fontSize: "1.1rem",
                      margin: "10px 12px 10px 0",
                      border: "1px solid #005F8E",
                    }}
                  />
                </Box>
                {/* -------------------- Job Description -------------------- */}
                {jobDetails.Jobinformations?.length ? (
                  <Box
                    sx={{
                      margin: "15px 10px",
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                      {Labels?.jobDescription}
                    </Typography>

                    {jobDetails?.whatYouWillDo ? (
                      <div>
                        <Typography
                          sx={{ fontWeight: 500 }}
                          variant="subtitle1"
                        >
                          {Labels.Whatyouwilldo}
                        </Typography>
                        <Typography variant="body2">
                          {jobDetails.whatYouWillDo}
                        </Typography>
                      </div>
                    ) : null}

                    {jobDetails?.whoWeWouldLikeToHave ? (
                      <div>
                        <Typography
                          sx={{ fontWeight: 500 }}
                          variant="subtitle1"
                        >
                          {Labels.WhoWeWouldLikeToHave}
                        </Typography>
                        <Typography variant="body2">
                          {jobDetails.whoWeWouldLikeToHave}
                        </Typography>
                      </div>
                    ) : null}

                    {jobDetails?.weWorkWith ? (
                      <div>
                        <Typography
                          sx={{ fontWeight: 500 }}
                          variant="subtitle1"
                        >
                          {Labels.WeWorkWith}
                        </Typography>
                        <Typography variant="body2">
                          {jobDetails.weWorkWith}
                        </Typography>
                      </div>
                    ) : null}

                    {jobDetails?.WouldBeAdvantageToHave ? (
                      <div>
                        <Typography
                          sx={{ fontWeight: 500 }}
                          variant="subtitle1"
                        >
                          {Labels.WouldBeAdvantageToHave}
                        </Typography>
                        <Typography variant="body2">
                          {jobDetails.WouldBeAdvantageToHave}
                        </Typography>
                      </div>
                    ) : null}
                  </Box>
                ) : null}

                <Divider />
                {jobDetails?.companyName && (
                  <Box sx={{ margin: "15px 10px" }}>
                    <Typography
                      sx={{ margin: "15px auto 0px", fontWeight: 500 }}
                      variant="subtitle1"
                    >
                      {Labels.Hiringfor} {jobDetails?.companyName}
                    </Typography>
                  </Box>
                )}

                {jobDetails?.Experience && (
                  <Box
                    sx={{
                      margin: "15px 10px",
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                      {Labels?.Experience}
                    </Typography>
                    <Typography variant="body1" sx={{ my: 2 }}>
                      {jobDetails?.experianceYears}
                    </Typography>
                  </Box>
                )}

                {jobDetails?.CompanyLink && (
                  <Box
                    sx={{
                      margin: "15px 10px",
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      {Labels?.CompanyLink}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#000000A1" }}>
                      {jobDetails.companyLink}&nbsp;
                    </Typography>
                  </Box>
                )}
              </Box>

              <Dialog
                open={openCoverLetter}
                onClose={() => setOpenCoverLetter(false)}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ p: 1.5 }}
                >
                  <Typography variant="h6" sx={{ color: "#005F8E" }}>
                    {Labels.CoverLetter}
                  </Typography>
                  <HighlightOffIcon
                    sx={{
                      cursor: "pointer",
                      color: "#005F8E",
                    }}
                    onClick={() => setOpenCoverLetter(false)}
                  />
                </Stack>
                <DialogContent>
                  <Box
                    sx={{
                      maxHeight: "80vh",
                      overflowY: "auto",
                      overflowX: "hidden",
                      p: 1.5,
                      minWidth: "300px",
                    }}
                  >
                    <Textarea
                      autoFocus
                      type="text"
                      multiline
                      minRows={7}
                      width="100%"
                      variant="outlined"
                      placeholder={Labels.Typehere}
                      value={coverLetter}
                      onChange={(e) => setCoverLetter(e.target.value)}
                    />
                  </Box>
                </DialogContent>
                <DialogActions>
                  {/* <Button onClick={() => setOpenCoverLetter(false)}>Cancel</Button> */}
                  {/* <Button onClick={ApplyJob} color="success"> */}
                  <Button
                    variant="solid"
                    fullWidth
                    sx={{
                      background: "#005F8E",
                      color: "white",
                      borderRadius: 3,
                      "&:hover": {
                        backgroundColor: "#005F8E",
                      },
                      "&:active": {
                        backgroundColor: "#005F8E",
                      },
                      "&:disabled": {
                        color: "white",
                        backgroundColor: "#005F8E",
                      },
                    }}
                    disabled={isButtonDisable}
                    onClick={ApplyjobProfanity}
                    // disabled={isButtonDisable}
                  >
                    {Labels?.ApplyNow}
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
}
