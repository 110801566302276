import { Stack, Paper, Typography, Grid, Box } from "@mui/material";
import React from "react";
import company1 from "../../Images/company1.png";
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import company2 from "../../Images/company2.png";
import "./Style.css";
import { useSelector } from "react-redux";
const AboutCompany = () => {
  const Labels = useSelector((state) => state.allLabels.labels);
  
  return (
    <Stack>
      <Paper elevation={0} sx={{ padding: "2px 0" }}>
        <Typography
          component="h2"
          variant="h2"
          color="inherit"
          sx={{ color: "#626262", display: { xs: "none", md: "block" },fontWeight:600 ,fontSize: { xs: "1.5rem", lg: "2.5rem" },}}
        >
          {Labels?.About}
        </Typography>

        <Typography
          component="h2"
          variant="h2"
          color="inherit"
          sx={{ color: "#005F8E", display: { xs: "none", md: "block" },fontWeight:600,fontSize: { xs: "1.5rem", lg: "2.5rem" }, }}
        >
          {Labels?.ourCompany}
        </Typography>
      {/* ------------------------------mobile view about our company------------------------------------   */}
        <Typography
          component="h2"
          variant="h4"
          color="inherit"
          sx={{ color: "#626262" , display: { sm: "block", md: "none" },fontSize: { xs: "1.5rem", lg: "2.5rem" },fontWeight:600}}
        >
          {Labels?.About}
        </Typography>

        <Typography
          component="h2"
          variant="h4"
          color="inherit"
          sx={{ color: "#005F8E" , display: { sm: "block", md: "none" },fontSize: { xs: "1.5rem", lg: "2.5rem" },fontWeight:600}}
        >
          {Labels?.ourCompany}
        </Typography>
        <Grid
          container
          spacing={3}
          sx={{ marginBottom: { xs: 0, md: 15 }, marginTop: 0 }}
        >
          <Grid item xs={12} md={6}>
            <Box sx={{ lineHeight: 2, textAlign: "justify", fontSize: { md: "16px", lg: "19px" },}}>
              Lorem ipsum, or lipsum as it is sometimes known, is dummy text
              used in laying out print, graphic or web designs. The passage is
              attributed to an unknown typesetter in the 15th century who is
              thought to have scrambled parts of Cicero's De Finibus Bonorum et
              Malorum for use in a type specimen book. Lorem ipsum, or lipsum as
              it is sometimes known, is dummy text used in laying out print,
              graphic or web designs. The passage is attributed to an unknown
              typesetter in the 15th century who is thought to have scrambled
              parts of Cicero's De Finibus Bonorum et Malorum for use in a type
              specimen book.
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box
              sx={{
                position: "relative",
                display: { sm: "none", md: "block" },
              }}
            >
              <img
                src={company1}
                alt="Image 1"
                height="400px"
                className="about-img1"
              />
              <img
                src={company2}
                alt="Image 2"
                height="350px"
                className="about-img2"
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Stack>
  );
};

export default AboutCompany;
