import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyProfile } from "../../redux/CompanyDetailSlice";
import {profanityfn} from "../Profanity/Profanity";
export default function BusinessCategory({
  value,
  setValue,
  style,
  isDisable,
  placeholder,
}) {
  const [businessCategory, setBusinessCategory] = useState([]);
  const usertype = localStorage.getItem("userType");
  // const dispatch = useDispatch();
  // const allCompanyprofile = useSelector(
  //   (state) => state.allCompanyprofile.companyDetails
  // );
  // const getProfile = () => {
  //   dispatch(getCompanyProfile());
  // };
  // useEffect(() => {
  //   getProfile();
  // }, []);

  useEffect(() => {
    const getBusinessCategory = async () => {
      const result = await axiosAPI.get("/getBusinessCategory");
      const { success, data } = await result.data;
      if (success === true) {
        setBusinessCategory(data);
      } else {
        setBusinessCategory([]);
      }
    };
    getBusinessCategory();
  }, []);
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)

  const handleChange = (event) => {
    setValue(profanityfn(ProfanityList,event.target.value));
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <FormControl sx={{ width: "100%" }}>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        onChange={handleChange}
        fullWidth
        displayEmpty
        readOnly={usertype == "Recruiter" ? true : false}
        // multiple
        variant="outlined"
        sx={{
          padding: "10px 12px",
          fontSize: "14px",
          fontFamily: "Poppins",
          borderColor: "#005F8E", // Change border color
        
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: '#005F8E',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#005F8E',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#000000',
            },
            // '.MuiSvgIcon-root ': {
            //   fill: "white !important",
            // },
          ...style,
        }}
        // renderValue={(selected) => {
        //   if (selected.length === 0) {
        //     return (
        //       <>
        //         <span style={{ color: "#bcbcbc" }}>{Labels?.BusinessIndustry}</span>
        //       </>
        //     );
        //   }
        //   return selected
        //     .map(
        //       (val) => businessCategory.find((item) => item.id === val)?.name
        //     )
        //     .join(", ");
        // }}
      >
        <MenuItem value="" disabled>
          <span style={{ color: "#bcbcbc", fontSize: "14px" }}>
            {placeholder || Labels?.BusinessIndustry}
          </span>
        </MenuItem>

        {businessCategory &&
          businessCategory.map((val, index) => {
            return (
              <MenuItem key={index} value={val.id}>
                {val.name}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}
