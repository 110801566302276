import Axios from "axios";

export const axiosAPI = Axios.create({
  baseURL: "https://jobportal.devm2.com/API/userapi",
  // baseURL:'http://192.168.25.117/50PlusJobseeker/API/50PlusJobSeekerAPI/public/userapi',
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en-GB,en",
  },
});
export const axiosupload = Axios.create({
  baseURL: "https://jobportal.devm2.com/API/userapi",
  // baseURL:'http://192.168.25.117/50PlusJobseeker/API/50PlusJobSeekerAPI/public/userapi',
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
    "Accept-Language": "en-GB,en",
  },
});

axiosAPI.interceptors.request.use((config) => {
  const token = localStorage.getItem("token") || localStorage.getItem("employerToken");
  const language = localStorage.getItem("language")!=null?localStorage.getItem("language"):'en';
  if (token) {
    config.headers.authorization = "Bearer " + token;
  }
  config.params = {
    ...config.params,
    language: language,
  };
  return config;
});

axiosAPI.interceptors.response.use((response) => {
  const { message } = response.data;
  if (
    ( message === "Unauthorised." ||
      message === "Invalid Session." ||
      message === "Unauthorised Authentication.") &&
    !localStorage.getItem("sessionTimeoutNotified")
  ) {
    localStorage.setItem("sessionTimeoutNotified", true);
    localStorage.removeItem("language");
    localStorage.removeItem("token");
    localStorage.removeItem("employerToken");
    localStorage.removeItem("uid");
    localStorage.removeItem("employerType");
    localStorage.removeItem("userType");
    localStorage.removeItem("resettedByAdmin");
    window.location.href = "/landingpage";
  } else {
    return response;
  }
});
