import React, { useRef } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import '../Landinpage1/Style.css';
import { Grid } from "@mui/material";
// import required modules
import { Autoplay, FreeMode, Pagination } from 'swiper/modules';
import discord from "../../Images/discord.png";
import discover from "../../Images/discover.png";
import dribble from "../../Images/dribble.png";
import dropbox from "../../Images/Dropbox.png";
import airnb from"../../Images/airnb.png";
import capitalone from "../../Images/capitalone.png";
import Shipbob from "../../Images/Shipbob.png"
import Slack from "../../Images/slack.png"
import Walmart from "../../Images/walmart.png"

import "./style.css"

export default function Adswiper() {
    const progressCircle = useRef(null);
    const progressContent = useRef(null);

    return (
      <>
        <Grid xs={12} mt={14} mb={5}>
          <Swiper
            slidesPerView={4}
            spaceBetween={20}
            loop={true} // Continuous looping
            freeMode={true} // Smooth, non-snapping scrolling
            
            speed={5000} // Speed of transition for smooth effect
            autoplay={{
              delay: 0, // No delay to keep the slides moving continuously
              disableOnInteraction: false,
            }}
            allowTouchMove={false} // Disable user interaction for pure autoplay effect
            loopedSlides={6} // Ensures the loop works with multiple visible slides
           
            pagination={{
              clickable: true,
              el: '.swiper-pagination',
            }}
            breakpoints={{
              // When the window width is >= 640px (mobile)
              0: {
                slidesPerView: 3, // Show 1 slide at a time on mobile
                spaceBetween: 10, // Smaller space between slides
              },
              // When the window width is >= 768px (tablet)
              768: {
                slidesPerView: 4, // Show 2 slides at a time on tablets
                spaceBetween: 15,
              },
              // When the window width is >= 1024px (desktop)
              1024: {
                slidesPerView: 4, // Show 4 slides at a time on desktops
                spaceBetween: 25,
              }
            }}
            modules={[Autoplay, FreeMode, Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img src={discord} alt="Discord" className="responsive-image"  />
            </SwiperSlide>
            <SwiperSlide>
              <img src={capitalone} alt="Capital One" className="responsive-image"  />
            </SwiperSlide>
            <SwiperSlide>
              <img src={dribble} alt="Dribble" className="responsive-image"  />
            </SwiperSlide>
            <SwiperSlide>
              <img src={dropbox} alt="Dropbox" className="responsive-image"  />
            </SwiperSlide>
            <SwiperSlide>
              <img src={airnb} alt="Airbnb" className="responsive-image" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={discover} alt="Discover"  className="responsive-image" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Shipbob} alt="Discover"  className="responsive-image" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Slack} alt="Discover"  className="responsive-image" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Walmart} alt="Discover"  className="responsive-image" />
            </SwiperSlide>
            <div className="autoplay-progress" slot="container-end">
              <svg viewBox="0 0 48 48" ref={progressCircle}>
                <circle cx="24" cy="24" r="20"></circle>
              </svg>
              <span ref={progressContent}></span>
            </div>
          </Swiper>
        </Grid>
      </>
    );
}
