import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { FormControl } from "@mui/material";
import { useSelector } from "react-redux";
import {profanityfn}  from "../Profanity/Profanity";

export default function CompanyType({ value, setValue, style, isDisable }) {
  const [companyType, setcompanyType] = useState([]);
  useEffect(() => {
    const getCompanyType = async () => {
      const result = await axiosAPI.get("/getEmployerType");
      const { success, data } = await result.data;
      if (success === true) {
        setcompanyType(data);
      } else {
        setcompanyType([]);
      }
    };
    getCompanyType();
  }, []);
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)


  const handleChange = (event) => {
    setValue(profanityfn(ProfanityList,event.target.value));
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <FormControl sx={{ width: "100%" }}>
      <Select
        value={value}
        onChange={(event) => handleChange(event)}
        variant="outlined"
        sx={{ padding: "10px 12px", fontFamily: "Poppins",borderColor: "#005F8E", // Change border color
        
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: '#005F8E',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#005F8E',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#000000',
          }, ...style }}
        displayEmpty
        // disabled={isDisable}
       
      >
        <MenuItem value="" disabled>
          <span style={{ color: "#bcbcbc" }}>{Labels?.CompanyType}</span>
        </MenuItem>
        {companyType &&
          companyType.map((val, index) => (
            <MenuItem key={index} name={val.name} value={val.id}>
              {val.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
