import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Grid,
  Avatar,
  Stack,
  Typography,
} from "@mui/material";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import worfromhome from "../../Images/worfromhome5.png";
import PlaceIcon from "@mui/icons-material/Place";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { PUBLIC_IMAGE_FOLDER } from "../Axios/Constant";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Group1506 from "../../Images/Group 1506.png";

export default function MostSearchedJobs() {
  const redirect = useNavigate();
  const Labels = useSelector((state) => state.allLabels.labels);
  const [jobId, setjobId] = useState("");
  const allList = useSelector((state) => state.allList.list);
  const postData = {
    search: "",
    skills: "",
    city: "",
    category: "",
    type: "",
  };
  const viewDetailedJob = (data) => {
    redirect(`/jobSearchPrelogin/${data.jobid}`);
  };
  return (
    <Stack sx={{ mb:{ xs: "0rem", sm: "6rem" } }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center", // Align items vertically centered
          pl: 2,
          pt: { xs: 5, lg: 1 },
          justifyContent: "space-between", // Ensure items are spaced out
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center", // Align items vertically centered
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "1.7rem", lg: "2.5rem" },
              fontWeight: "600",
              color: "#626262",
              mr: 2, // Add space between the Typography components
            }}
          >
            {Labels?.Featured		}
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "1.7rem", lg: "2.5rem" },
              fontWeight: "600",
              color: "#005F8E",
              
            }}
          >
             {Labels?.Jobs	}
          </Typography>
        </Box>
        <Button
          endIcon={<ArrowForwardIcon />}
          sx={{
            color: "#4B4B4B",

            mt: { xs: 2, md: 0 },
          }}
          onClick={() => redirect("/jobSearchPrelogin", { state: postData })}
        >
          {Labels?.viewAll}
        </Button>
      </Box>

      <Grid
        container
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid
          item
          md={7}
          sm={12}
          xs={12}
          sx={{
            // backgroundColor: "rgba(178, 207, 207, 0.3)",
            paddingBottom: { xs: "30px", md: "0px" },
            paddingTop: { xs: "60px", md: "0px" },
          }}
        >
          <Stack
            direction="column"
            justifyContent="space-evenly"
            marginLeft={3}
            marginRight={2}
            sx={{
              height: "100%",
              alignItems: { xs: "center", md: "flex-end" },
              py: 2,
              mt: { xs: 0, md: 5 },
            }}
          >
            <Grid container spacing={2}>
              {allList?.mostSearchedJobs &&
                allList?.mostSearchedJobs.map((data, index) => (
                  // <Grid item md={4} sm={6} xs={12} key={data.jobid}>
                  //   <Box
                  //     sx={{
                  //       display: "flex",
                  //       flexDirection: "column",
                  //       justifyContent: "center",
                  //       alignItems: "center",
                  //       background: "white",
                  //       textAlign: "center",
                  //       borderRadius: "5px",
                  //       py: { xs: 4, sm: 3, lg: 3 },
                  //       width: "100%",
                  //       height:"100%",
                  //       minHeight:"150px",
                  //       cursor: "pointer",
                  //       "&:hover": {
                  //         boxShadow:
                  //           "0 0 1px rgba(0,0,0,.125),0 1px 3px rgba(0,0,0,.2)",
                  //       },
                  //     }}
                  //     onClick={(e) => viewDetailedJob(data)}
                  //   >
                  //     <Typography
                  //       variant="h6"
                  //       sx={{ mb: "4px", height: "32px", overflow: "hidden" }}
                  //     >
                  //       {data?.jobName.length > 15
                  //         ? data?.jobName.slice(0, 15) + "..."
                  //         : data?.jobName}
                  //     </Typography>
                  //     <Stack direction="row">
                  //       <PlaceIcon
                  //         sx={{
                  //           color: "#4CAF50",
                  //           fontSize: "1.3rem",
                  //         }}
                  //       />
                  //       <Typography sx={{ fontSize: "14px" ,}}>
                  //         {data?.address}
                  //       </Typography>
                  //     </Stack>
                  //   </Box>
                  // </Grid>
                  <Grid
                    key={index}
                    item
                    xs={12}
                    sm={6}
                    md={5.2}
                    sx={{ width: { xs: "100%", xl: "100%" } }}
                  >
                    <Card
                      sx={{
                        borderRadius: "17px",
                        boxShadow: "0px 4px 39px rgba(0, 0, 0, 0.1)",
                        cursor: "pointer",
                        height: "100%",
                        display: "flex", // Center the content inside the Card
                        flexDirection: "column", // Ensure content stacks vertically
                        alignItems: "center", // Horizontally center the content
                        justifyContent: "center", // Vertically center the content
                      }}
                      onClick={(e) => viewDetailedJob(data)}
                    >
                      <CardContent sx={{ width: "100%", textAlign: "center" }}>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: "#000000",
                            textTransform: "capitalize",
                            height: "40px",
                            overflow: "hidden",
                          }}
                        >
                          {data?.jobName.length > 20
                            ? data?.jobName.slice(0, 20) + "..."
                            : data?.jobName}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center", // Center the content horizontally
                            alignItems: "center",
                            margin: "10px 0",
                          }}
                        >
                          <PlaceOutlinedIcon
                            sx={{ color: "#4CAF50", marginRight: 1 }}
                          />
                          <Typography
                            sx={{
                              color: "#2B4947",
                              fontSize: {
                                xs: "11px",
                                lg: "13px",
                              },
                              fontWeight: 500,
                              color: "#47CCC2",
                            }}
                          >
                            {data?.address.length > 25
                              ? data?.address.slice(0, 25) + "..."
                              : data?.address}
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center", // Center the content horizontally
                            alignItems: "center",
                            margin: "10px 0",
                          }}
                        >
                          
                          <Typography
                            sx={{
                              color: "#2B4947",
                              fontSize: {
                                xs: "11px",
                                lg: "13px",
                              },
                              fontWeight: 500,
                              color: "#47CCC2",
                            }}
                          >
                            {data?.country.length > 25
                              ? data?.country.slice(0, 25) + "..."
                              : data?.country}
                          </Typography>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </Stack>
        </Grid>
        <Grid item md={5} display={{ sm: "none", md: "block" }}>
          <img src={Group1506} alt="joboffer" className="workFromHome-img" />
        </Grid>
      </Grid>
    </Stack>
  );
}
