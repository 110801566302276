
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyArNPu7Xlx5JHDP0bGILlS8eamiv8IHe8Y",
  authDomain: "naranjo-jobs.firebaseapp.com",
  projectId: "naranjo-jobs",
  storageBucket: "naranjo-jobs.appspot.com",
  messagingSenderId: "301922394234",
  appId: "1:301922394234:web:ff5cd1c28de68f288c5bc3",
  measurementId: "G-JCG3ETFZ31"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app)

export { app, auth };
