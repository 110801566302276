import React from "react";
import "./Style.css";
import jobseeker from "../../Images/jobseeker.png";
import Group1508 from "../../Images/Group 1508.png";
import { Paper, Typography, Grid, Stack, Box } from "@mui/material";
import vectorlabel from "../../Images/Vector.png";
import CustomButton from "../Components/CustomButton";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const icons = [
  { src: vectorlabel, alt: "Job Company 1", text: "Company 1" },
  { src: vectorlabel, alt: "Job Company 2", text: "Company 2" },
  { src: vectorlabel, alt: "Job Company 3", text: "Company 3" },
];
const Advertisement2 = () => {
  const redirect = useNavigate();
  const redirecttosignUp = () => {
    redirect("/signup");
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <Stack mb={5}>
      <Paper
        sx={{
          color: "#fff",
          position: "relative",
          boxShadow: "none",
          mt: 2,
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Stack
              direction="column"
              justifyContent="center"
              sx={{
                // mx: { xs: "0px", md: "auto" },
                width: { sx: "100%", md: "100%" },
                mt: { sx: 0, MozFontLanguageOverride: -10 },
              }}
            >
              <Typography
                sx={{
                  color: "#626262",
                  fontWeight: "600",
                  fontSize: { xs: "1.5rem", lg: "2.5rem" },
                  display: "flex", // Ensure inline content stays on the same line
                  alignItems: "center", // Align items vertically in the center
                }}
              >
                 {Labels?.FindYourDream}&nbsp;
                <Box component="span" sx={{ color: "#005F8E" }}>
                   Job
                </Box>
              </Typography>

              <Typography
                sx={{
                  color: "#626262",
                  fontWeight: "600",
                  fontSize: { xs: "1.5rem", lg: "2.5rem" },
                  mb: 2,
                }}
              >
                {Labels?.inoneplace}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="center"
              sx={{ mx: "auto", width: { sx: "100%", md: "100%" } }}
            >
              <Typography
                sx={{
                  fontWeight: 400,
                  color: "#000",
                  fontSize: { md: "16px", lg: "19px" },
                  paddingRight: { xs: 1, lg: "77px" },
                  lineHeight: 2,
                  textAlign: "justify",
                }}
              >
                Discover your ideal job with ease. Whether you're just starting
                your career or looking to take the next step, our platform
                brings together thousands of opportunities tailored to your
                skills and ambitions. Let us help you find the perfect match in
                just one place. With personalized job recommendations and
                advanced search features, you'll connect with top employers who
                value your unique talents. Start your journey towards a
                fulfilling career today—your dream job is waiting!
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{
                mx: "auto",
                width: { xs: "100%", md: "75%" },
                flexWrap: "wrap",
                gap: 2,
                mt: 10,
              }}
            >
              {icons.map((icon, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: { xs: "100%", sm: "auto" },
                    flex: 1,
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={icon.src}
                    alt={icon.alt}
                    style={{
                      marginRight: "8px",
                      maxWidth: "50px",
                      height: "auto",
                    }} // Adjust size as needed
                  />
                  <Typography
                    sx={{
                      fontSize: { xs: "0.875rem", md: "1rem" },
                      color: "text.primary",
                    }}
                  >
                    {icon.text}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              background: "#fff",
              display: " flex",
              alignItems: "flex-end",
              justifyContent: "center",
              display: { xs: "none", sm: "none", md: "block" },
            }}
          >
            <img
              src={Group1508}
              className="mikeImg"
              alt={"background image"}
              width="100%"
            />
          </Grid>
        </Grid>
      </Paper>
    </Stack>
  );
};

export default Advertisement2;
