import React, { useState, useEffect } from "react";
import {
  Avatar,
  Button,
  Card,
  Grid,
  Box,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Divider,
  Skeleton,
} from "@mui/material";
import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
  parseISO,
} from "date-fns";
import {
  errorNotify,
  isValidEmail,
  succesNotify,
  validatePhoneNumber,
  warningNotify,
} from "../../CommonCode/Commonfunc";
import { useNavigate } from "react-router-dom";
import PublicIcon from "@mui/icons-material/Public";
import CustomButton from "../../Components/CustomButton";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import "../../Landinpage1/Style.css";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { axiosAPI } from "../../Axios/Axios";
import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import dummyLogo from "../../../Images/dummyLogo.png";
import { infoNotify, useStyles } from "../../CommonCode/Commonfunc";
import TextfieldCustom from "../../Components/TextfieldCustom";
import { useSelector } from "react-redux";
import DeleteGroup from "./deleteGroup";
import { Item } from "semantic-ui-react";

function MyGroupsList() {
  const redirect = useNavigate();
  const classes = useStyles();
  const Labels = useSelector((state) => state.allLabels.labels);
  const [groups, setGroups] = useState([]);
  const [view, setView] = useState("module");
  const [loading, setloading] = useState(false);
  const [loading2, setloading2] = useState(false);

  const handleChange = (event, nextView) => {
    if (nextView !== null) {
      setView(nextView);
    }
  };
  const resettedByAdmin = localStorage.getItem("resettedByAdmin");

  const getGroups = async () => {
    try {
      const result = await axiosAPI.post("/getMyGroupsList");
      const { data, status } = result.data;
      if (status) {
        setloading(true);
        setGroups(data);
      } else {
        setloading(true);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };
  // ---------------Search Groups--------------------
  const [searchKeyword, setSearchKeyword] = useState("");
  const searchGroups = async () => {
    if (!searchKeyword) {
      getGroups();
    } else {
      try {
        const result = await axiosAPI.post("/getMyGroupsList", {
          search: searchKeyword,
        });
        const { data, status, message } = result.data;
        if (status) {
          setGroups(data);
        } else {
          infoNotify(message);
        }
      } catch (error) {
        //// console.log(error.meesage);
      }
    }
  };
  // -------------------- Joined Group List --------------------
  const [joinedGroupList, setJoinedGroupList] = useState([]);

  const getJoinedGroupList = async () => {
    try {
      const result = await axiosAPI.post("/myJoinedGroupsList");
      const { status, data } = result.data;
      if (status) {
        setJoinedGroupList(data);
        setloading2(true);
      } else {
        setloading2(true);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };
  // -------------------- Delete Group --------------------
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [groupid, setGroupId] = useState("");
  const deleteGroup = (id) => {
    setDeleteModal(true);
    setGroupId(id);
    getGroups();
  };
  const closeDeleteGroupModal = () => {
    setDeleteModal(false);
  };
  // -------------------- Time Difference --------------------
  const getTimeDifference = (createdOn) => {
    const dateNow = new Date();
    const createdDate = parseISO(createdOn);

    const diffInSeconds = differenceInSeconds(dateNow, createdDate);
    if (diffInSeconds < 60) {
      return `${diffInSeconds} ${Labels?.secAgo}`;
    }

    const diffInMinutes = differenceInMinutes(dateNow, createdDate);
    if (diffInMinutes < 60) {
      return `${diffInMinutes} ${Labels?.minAgo}`;
    }

    const diffInHours = differenceInHours(dateNow, createdDate);
    if (diffInHours < 24) {
      return `${diffInHours} ${Labels?.hourAgo}`;
    }

    const diffInDays = differenceInDays(dateNow, createdDate);
    if (diffInDays < 30) {
      return `${diffInDays}  ${Labels?.daysago}`;
    }

    const diffInMonths = differenceInMonths(dateNow, createdDate);
    return `${diffInMonths} ${Labels?.monthAgo}`;
  };
  useEffect(() => {
    getGroups();
    getJoinedGroupList();
    window.scrollTo(0, 0);
  }, []);
  const viewDetailedGroup = (id) => {
    redirect(`/group-detail/${id}`);
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" sx={{ color: "#005F8E" }}>
          {Labels?.MyGroups}
        </Typography>
        <CustomButton
          onClick={() => redirect("/create-group")}
          label={Labels?.CreateGroup}
        />
      </Stack>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          sx={{ alignItems: "center" }}
        >
          <TextfieldCustom
            label={Labels?.searchGroupName}
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
          />
          <CustomButton
            label={Labels?.search}
            style={{ borderRadius: "10px", margin: "10px" }}
            onClick={searchGroups}
          />
          <CustomButton
            label={Labels?.Reset}
            style={{ borderRadius: "10px", margin: "10px", background: "white",
              color: "#005F8E",
              border: "2px solid #005F8E",
              "&:hover": {
                backgroundColor: "white",
                color: "#005F8E",
                border: "2px solid #005F8E",
              },
              "&:active": {
                backgroundColor: "white",
                color: "#005F8E",
                border: "2px solid #005F8E",
              },
              "&:disabled": { color: "white", backgroundColor: "#005F8E" }}}
            onClick={() => {
              getGroups();
              setSearchKeyword("");
            }}
          />
        </Stack>
        <div style={{ margin: "15px 0" }}>
          <ToggleButtonGroup value={view} exclusive onChange={handleChange}>
            <ToggleButton value="module" aria-label="module">
              <ViewModuleIcon />
            </ToggleButton>
            <ToggleButton value="list" aria-label="list">
              <ViewListIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} md={8} lg={8} xl={9}>
          {loading ? (
            groups.length > 0 ? (
              <div>
                {view === "module" ? (
                  <Grid container spacing={2}>
                    {groups &&
                      groups.map((val, i) => (
                        <Grid item xs={12} md={6} lg={6} key={i}>
                          <Card
                            sx={{
                              borderRadius: "15px",
                              boxShadow: "0px 4px 39px rgba(0, 0, 0, 0.1)",
                              cursor: "pointer",
                              padding: "10px 0",
                              "&:hover": {
                                backgroundColor: "#ffffffe5",
                              },
                            }}
                            onClick={() => viewDetailedGroup(val.id)}
                          >
                            <Stack direction="row">
                              <Avatar
                                src={`${val.grpLogoPath}`}
                                alt=" "
                                style={{
                                  maxWidth: 70,
                                  maxHeight: 70,
                                  margin: "10px",
                                }}
                              />

                              <Box
                                sx={{
                                  margin: "10px",
                                }}
                              >
                                <Typography
                                  variant="subtitle1"
                                  sx={{ color: "#005F8E", fontWeight: 600 }}
                                >
                                  {val.groupName}
                                </Typography>
                                {val.statusKey == "Expired" ||
                                val.statusKey == "In-Active" ||
                                val.statusKey == "Closed" ? (
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color: "#ED4C5C",
                                      background: "rgb(255, 246, 247)",
                                      p: 0.75,
                                      borderRadius: 1,
                                    }}
                                  >
                                    {val.status}
                                  </Typography>
                                ) : null}
                                <Stack direction="row" alignItems="center">
                                  <PublicIcon sx={{ fontSize: 17 }} />
                                  <Typography
                                    variant="body2"
                                    sx={{ color: "#000" }}
                                  >
                                    &nbsp;{val.categoryName}
                                  </Typography>
                                </Stack>
                              </Box>
                            </Stack>

                            <Typography
                              sx={{
                                overflowY: "hidden",
                                //--------------------------------changed height-------------------------------------------------------------------------
                                minHeight: "70px",
                                maxHeight: "70px",
                                lineHeight: "23px",
                                fontSize: "15px",
                                fontWeight: 300,
                                color: "#716D6DD9",
                                margin: "10px",
                              }}
                            >
                              {val.description.length > 75
                                ? val.description.slice(0, 100) + "..."
                                : val.description}
                            </Typography>
                            <div
                              style={{
                                margin: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Stack direction="row">
                                <Typography variant="body2">
                                  {val.createdOn}
                                </Typography>
                                &nbsp;-&nbsp;
                                <Typography variant="body2">
                                  {val.membersCount}&nbsp;
                                  {val.membersCount == 1
                                    ? Labels?.Member
                                    : Labels?.Members}
                                </Typography>
                              </Stack>
                              <Stack
                                direction="row"
                                spacing={1}
                                justifyContent="right"
                              >
                                <VisibilityOutlinedIcon
                                  sx={{ color: "#005F8E" }}
                                />
                                <DeleteOutlinedIcon
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    deleteGroup(val.id);
                                  }}
                                  sx={{
                                    color: "#d32f2f",
                                  }}
                                />
                              </Stack>
                            </div>
                          </Card>
                        </Grid>
                      ))}
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    {groups &&
                      groups.map((val, i) => (
                        <Grid item xs={12} key={i}>
                          <Card
                            sx={{
                              borderRadius: "17px",
                              boxShadow: "0px 4px 39px rgba(0, 0, 0, 0.1)",
                              cursor: "pointer",
                              display: { xs: "block", md: "flex" },
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "10px 5px",
                              "&:hover": {
                                backgroundColor: "#ffffffe5",
                              },
                            }}
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={3} lg={1}>
                                <Avatar
                                  src={`${val.grpLogoPath}`}
                                  alt=" "
                                  style={{
                                    maxWidth: 70,
                                    maxHeight: 70,
                                    margin: "10px",
                                  }}
                                />
                              </Grid>
                              <Grid item xs={9} lg={4}>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ color: "#005F8E", fontWeight: 600 }}
                                >
                                  {val.groupName}
                                </Typography>
                                {val.statusKey == "Expired" ||
                                val.statusKey == "In-Active" ||
                                val.statusKey == "Closed" ? (
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color: "#ED4C5C",
                                      background: "rgb(255, 246, 247)",
                                      p: 0.75,
                                      borderRadius: 1,
                                    }}
                                  >
                                    {val.status}
                                  </Typography>
                                ) : null}
                                <Stack direction="row" alignItems="center">
                                  <PublicIcon sx={{ fontSize: 17 }} />
                                  <Typography
                                    variant="body2"
                                    sx={{ color: "#000" }}
                                  >
                                    &nbsp;{val.categoryName}
                                  </Typography>
                                </Stack>
                                <Stack direction="row">
                                  <Typography variant="body2">
                                    {val.createdOn}
                                  </Typography>
                                  &nbsp;-&nbsp;
                                  <Typography variant="body2">
                                    {val.membersCount}&nbsp;{Labels?.Members}
                                  </Typography>
                                </Stack>
                              </Grid>
                              <Grid item xs={12} lg={5}>
                                <Typography
                                  sx={{
                                    overflowY: "hidden",
                                    lineHeight: "23px",
                                    fontSize: "15px",
                                    fontWeight: 300,
                                    color: "#716D6DD9",
                                    margin: "10px",

                                    textAlign: "left",
                                  }}
                                >
                                  {val.description.length > 75
                                    ? //-----------------------------------------changed length -------------------------------------------------------------------------
                                      val.description.slice(0, 100) + "..."
                                    : val.description}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} lg={1}>
                                <Stack
                                  direction="row"
                                  justifyContent="right"
                                  //-----------------changed icon position------------------------------
                                  my={4}
                                >
                                  <VisibilityOutlinedIcon
                                    sx={{ color: "#005F8E" }}
                                    onClick={() => viewDetailedGroup(val.id)}
                                  />
                                  <DeleteOutlinedIcon
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      deleteGroup(val.id);
                                    }}
                                    sx={{
                                      color: "#d32f2f",
                                    }}
                                  />
                                </Stack>
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>
                      ))}
                  </Grid>
                )}
              </div>
            ) : (
              <Typography variant="body1" sx={{ p: 1,color:"#000" }}>
                {Labels?.noGroupFound}
              </Typography>
            )
          ) : (
            <>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Item>
                    <Skeleton variant="rounded" width={`100%`} height={200} />
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    <Skeleton variant="rounded" width={`100%`} height={200} />
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    <Skeleton variant="rounded" width={`100%`} height={200} />
                  </Item>
                </Grid>
                <Grid item xs={6}>
                  <Item>
                    <Skeleton variant="rounded" width={`100%`} height={200} />
                  </Item>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={3}>
          <Card
            sx={{
              borderRadius: 1,
              padding: 0,
              boxShadow: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 20,
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ color: "#005F8E", fontWeight: 500 }}
              >
                {Labels?.JoinedGroups}
              </Typography>
            </div>
            <Divider />
            {!loading2 ? (
              <>
                <Skeleton
                  variant="rounded"
                  width={`95%`}
                  height={70}
                  style={{ margin: "5px auto" }}
                />
                <Skeleton
                  variant="rounded"
                  width={`95%`}
                  height={70}
                  style={{ margin: "5px auto" }}
                />
                <Skeleton
                  variant="rounded"
                  width={`95%`}
                  height={70}
                  style={{ margin: "5px auto" }}
                />
                <Skeleton
                  variant="rounded"
                  width={`95%`}
                  height={70}
                  style={{ margin: "5px auto" }}
                />
                <Skeleton
                  variant="rounded"
                  width={`95%`}
                  height={70}
                  style={{ margin: "5px auto" }}
                />
              </>
            ) : (
              <Box
                sx={{ height: { xs: "50vh", md: "70vh" }, overflow: "auto" }}
                className={classes.customColoredScrollbar}
              >
                {joinedGroupList.length ? (
                  joinedGroupList.map((val, i) => (
                    <Card
                      key={i}
                      sx={{
                        borderRadius: 1,
                        boxShadow: "none",
                        borderBottom: "1px solid #005F8E33",
                        cursor: "pointer",
                        p: 1,

                        "&:hover": {
                          backgroundColor: "#ffffffe5",
                        },
                      }}
                      onClick={() => viewDetailedGroup(val.groupid)}
                    >
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                      >
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Avatar
                            src={`${val.grpLogoPath}`}
                            alt=" "
                            style={{
                              maxWidth: 30,
                              maxHeight: 30,
                            }}
                          />
                          <Stack>
                            <Typography
                              variant="subtitle1"
                              sx={{ color: "#005F8E", fontWeight: 600 }}
                            >
                              {val.groupName}
                            </Typography>

                            <Stack direction="row" alignItems="center">
                              <PublicIcon sx={{ fontSize: 17 }} />
                              <Typography
                                variant="body2"
                                sx={{ color: "#000" }}
                              >
                                &nbsp;{val.categoryName}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                        {/* ----------------------removed time--------------------------------------------------- */}
                        {/* <Stack>
                      <Typography variant="caption">
                        {getTimeDifference(val.createdOn)}
                      </Typography>
                    </Stack> */}
                      </Stack>
                    </Card>
                  ))
                ) : (
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#000", fontWeight: 600, margin: "20px" }}
                  >
                    {Labels.noGroupFound}
                  </Typography>
                )}
              </Box>
            )}
          </Card>
        </Grid>
      </Grid>

      {/* -------------------- Delete Dialog -------------------- */}
      <>
        <DeleteGroup
          open={openDeleteModal}
          handleClose={closeDeleteGroupModal}
          groupId={groupid}
        />
      </>
    </>
  );
}

export default MyGroupsList;
