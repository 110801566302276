import {
  Box,
  Button,
  Dialog,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment, useState } from "react";
import { axiosAPI } from "../../../Axios/Axios";
import { errorNotify, succesNotify } from "../../../CommonCode/Commonfunc";
import { useDispatch, useSelector } from "react-redux";
import { updateLoginDetails } from "../../../../redux/JobSeekerLoginDetailSlice";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function JobSeekerActivationModal({ open, handleClose }) {
  const dispatch = useDispatch();
  const [activationCode, setActivationCode] = useState("");
  const [resendButton, setResendButton] = useState(false);
  const activateAccount = async (e) => {
    e.preventDefault();
    if (!activationCode) {
      errorNotify(Labels.activationCodeRequired);
      return;
    } else {
      const result = await axiosAPI.post("/activateUser", {
        code: activationCode,
        language:localStorage.getItem("language")
      });
      const { data, message, success } = result.data;
      if (success) {
        succesNotify(message);
        dispatch(updateLoginDetails(data));
        handleClose(false);
      } else {
        setResendButton(true);
        errorNotify(data.error);
      }
    }
  };
  const Labels = useSelector((state) => state.allLabels.labels);

  const resendActivationCode = async (e) => {
    e.preventDefault();
    const result = await axiosAPI.post("/resendCode");
    const { data, message, status } = result.data;
    if (status) {
      succesNotify(Labels.activationMail);
    } else {
      errorNotify(message);
    }
  };
  const createGroup = () => {};
  const createForum = () => {};
  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
        <Typography variant="h6" sx={{ color: "#136E9B" }}>
          {Labels.PleaseActivateYourAccount}
        </Typography>
        <HighlightOffIcon
          sx={{
            cursor: "pointer",
            color: "#136E9B",
          }}
          onClick={() => handleClose(false)}
        />
      </Stack>

      <Box sx={{ p: 1.5 }}>
        <TextField
          onChange={(e) => setActivationCode(e.target.value)}
          variant="outlined"
          fullWidth
          placeholder={Labels.Activationcode}
        />
      </Box>
      <Stack
        direction="row"
        justifyContent="center"
        spacing={1}
        sx={{ p: 1.5 }}
      >
        {resendButton ? (
          <Button
            variant="contained"
            onClick={resendActivationCode}
            sx={{
              textTransform: "capitalize",
              border: "1px solid #136E9B",
              backgroundColor: "#136E9B",
              borderRadius: "0px 2px",
              "&:hover": {
                backgroundColor: "#136E9B",
              },
              "&:active": {
                backgroundColor: "#136E9B",
              },
              "&:disabled": { color: "white", backgroundColor: "#136E9B" },
            }}
          >
            {Labels.ResendCode}
          </Button>
        ) : null}
        <Button
          variant="solid"
          onClick={activateAccount}
          sx={{
            color: "white",
            textTransform: "capitalize",
            border: "1px solid #136E9B",
            backgroundColor: "#136E9B",

            "&:hover": {
              backgroundColor: "#136E9B",
            },
            "&:active": {
              backgroundColor: "#136E9B",
            },
            "&:disabled": { color: "white", backgroundColor: "#136E9B" },
          }}
        >
          {Labels.Activate}
        </Button>
      </Stack>
    </Dialog>
  );
}
