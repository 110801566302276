import React, { useEffect, useState } from "react";
import {
  HighlightOffOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  Dialog,
  OutlinedInput,
  Stack,
  Switch,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { axiosAPI } from "../../Axios/Axios";

function EmployerTwoFactorModel({ open, handleClose }) {
  const Labels = useSelector((state) => state.allLabels.labels);
  const [Ischecked, setIsChecked] = useState(false);
  const [backdropOpen, setBackdrop] = useState(false);
  const handleToggle = () => {
    setIsChecked((prevChecked) => {
      sendValueToAPI(!prevChecked);
      return !prevChecked;
    });
  };

  const tokens = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("employerToken"),
    },
  };

  const getEmployerData = async () => {
    setBackdrop(true);
    const result = await axiosAPI.post("/getCompanyProfile");
    const { status, data, message } = result.data;
    if (status) {
      setIsChecked(data.twoFactorPreferred == 1 ? true : false);
    }
  };

  const sendValueToAPI = (value) => {
    axiosAPI
      .post("/enableDisableTFA", { twoFactorPreferred: value ? 1 : 0 }, tokens)
      .then((res) => {
        getEmployerData();
        handleClose(false)
      })
      .catch((err) => {
        //// console.log(err);
      });
  };

  useEffect(() => {
    getEmployerData();
  }, []);

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 1.5 }}
      >
        <Typography variant="h6" sx={{ color: "#005F8E" }}>
         
        </Typography>
        <HighlightOffOutlined
          onClick={() => handleClose(false)}
          sx={{ color: "#005F8E", cursor: "pointer" }}
        />
      </Stack>
      <Paper elevation={0} sx={{ mb: 2, width: "100%" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 1.5 }}
        >
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 600, color: "#005F8E" }}
          >
            {Labels?.EnableTwoFactor}
          </Typography>
          <Switch checked={Ischecked} color="success" onChange={handleToggle} />
        </Stack>
      </Paper>
    </Dialog>
  );
}

export default EmployerTwoFactorModel;
