import {
  Box,
  Button,
  Card,
  Chip,
  Container,
  Grid,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Skeleton,
} from "@mui/material";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import React, { useState, useEffect, useRef } from "react";
import {
  parseISO,
  differenceInDays,
  differenceInHours,
  differenceInMonths,
  isSameDay,
} from "date-fns";
import { PUBLIC_IMAGE_FOLDER } from "../Axios/Constant";
import dummyLogo from "../../Images/dummyLogo.png";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import BusinessIcon from "@mui/icons-material/Business";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ShareIcon from "@mui/icons-material/Share";
import { axiosAPI } from "../Axios/Axios";
import JobSerachSideGrid from "./JobSerachSideGrid";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import TextfieldCustom from "../Components/TextfieldCustom";
import CustomButton from "../Components/CustomButton";
import { infoNotify, useStyles } from "../CommonCode/Commonfunc";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import "../Landinpage1/Style.css";
import { Avatar } from "@material-ui/core";
import "./style.css";
import { useSelector } from "react-redux";
import JobDetailedview from "./JobDetailedview";
import { profanityfn } from "../Profanity/Profanity";

const JobSerachPreloginGrid = () => {
  const topRef = useRef(null);
  const [jobId, setjobId] = useState("");
  const [expanded, setExpanded] = useState(true);
  const [loading, setloading] = useState(true);

  const classes = useStyles();
  const redirect = useNavigate();
  const location = useLocation(); // location.state is null
  const postData = location.state || {}; // which makes postData null
  const { jobsearch, city, skills, category, type, industryID } = postData;
  const [jobs, setjobs] = useState([]);

  const [formdata, setformData] = useState({
    jobsearch: "",
    city: "",
  });

  useEffect(() => {
    setformData({
      jobsearch: jobsearch ? jobsearch : "",
      city: city ? city : "",
    });
  }, []);

  const [filtercate, setfiltercate] = useState([]);
  const [selectedJobId, setSelectedJobId] = useState(null);

  const cardRefs = useRef([]);

  const Labels = useSelector((state) => state.allLabels.labels);

  const getjobs = async () => {
    try {
      const result = await axiosAPI.post("/searchjobs", postData);
      const { data, success } = result.data;
      if (success === true) {
        setloading(false);
        setjobs(data);
        setJobDetail(false);
      } else {
        setloading(false);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };

  const getjobsManual = async (searchdata) => {
    try {
      const result = await axiosAPI.post("/searchjobs", searchdata);
      const { data, success } = result.data;
      if (success === true) {
        setloading(false);
        setjobs(data);
      } else {
        setloading(false);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };

  const [view, setView] = useState("module");

  const { id } = useParams();

  useEffect(() => {
    if (id && !loading) {
      setSelectedJobId(id);
      setJobDetail(true);
    }
  }, [id, loading]);

  useEffect(() => {
    if (selectedCardRef.current) {
      selectedCardRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [selectedJobId]);

  const handleCardClick = (val, index) => {
    if (val){ setSelectedJobId(val);
      console.log(val,"vaa");
      
      setJobDetail(true);
      if (cardRefs.current[index]) {
        cardRefs.current[index].scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
      setExpanded(false);}
   
  };

  // ------------------Search job by industries------------------------

  const ProfanityList = useSelector((state) => state.Profanity.wordlist);

  const setJobSerach = (e) => {
    const value = e.target.value;
    setformData({
      ...formdata,
      [e.target.name]: profanityfn(ProfanityList, value),
    });
  };
  const searchJobs = (e) => {
    e.preventDefault();
    if (!formdata.jobsearch && !formdata.city) {
      infoNotify(Labels.jobTitleLocation);
    } else {
      const searchData = {
        jobsearch: formdata.jobsearch,
        city: formdata.city,
        skills: skills ? skills : "",
        category: category ? category : "",
        type: type ? type : "",
        industryID: industryID ? industryID : "",
      };
      getjobsManual(searchData);
      redirect("/jobSearchPrelogin", { state: searchData });
    }
  };

  const [resetbox, setresetbox] = useState(true);

  const resetFields = () => {
    setformData({ jobsearch: "", city: "" });
    const searchData = {
      jobsearch: "",
      city: "",
      skills: "",
      category: "",
      type: "",
      industryID: "",
    };
    setresetbox(!resetbox);
    getjobsManual(searchData);
    redirect("/jobSearchPrelogin", { state: searchData });
  };

  const handleClick = () => {
    resetFields();
  };

  const [jobDetail, setJobDetail] = useState(false);

  const containerRef = useRef(null);
  const selectedCardRef = useRef(null);

  const viewDetailedJob = (id) => {
    setJobDetail(true);
    setjobId(id);
  };

  const theme = useTheme();
  const isWebView = useMediaQuery(theme.breakpoints.up("md")); // Only true for 'md' and larger screens

  useEffect(() => {
    if (isWebView) {
      window.scrollTo(0, 0);
    }
  }, [selectedJobId]);

  const handleLinkClick = (jobid) => {
    const email = ""; // Replace with the recipient's email address
    const subject = "Job Application";
    // const body = `Job Link : localhost:3000/jobSearchPrelogin/${jobid}`
    const body = `Job Link :   https://50plus.zone/jobSearchPrelogin/${jobid}`;

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  useEffect(() => {
    getjobs();
  }, []);

  useEffect(() => {
    if (!isEmptyObject(postData)) {
      getjobs();
    }
  }, [postData]);

  const savefn = () => {
    // infoNotify(Labels.applyMessage);
    infoNotify(Labels.LoginRequired);
    setTimeout(() => {
      redirect("/login");
    }, 1000);
  };

  return (
    <Container
      maxWidth="100%"
      sx={{
        margin: "0 auto ",
        padding: "0 0 !important",
        background: "#EAEFF1",
        minHeight: "100vh",
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          px: 5,
          pb: 2,
          paddingTop: {
            xs: "55px",
            sm: "85px",
          },
          "@media (max-width: 599px)": {
            px: 1,
          },
        }}
      >
        <Grid item xs={12} md={4} lg={3} xl={2}>
          <JobSerachSideGrid
            setfiltercate={setfiltercate}
            resetbox={resetbox}
          />
        </Grid>
        <Grid item xs={12} md={8} lg={9} xl={10}>
          {/* {showContent && ( // Conditionally render based on the showContent state */}
          <Stack
            direction={{ md: "column", lg: "row" }}
            spacing={2}
            sx={{ margin: "10px 0px" }}
          >
            <TextfieldCustom
              label={Labels?.JobTitle}
              style={{
                borderRadius: "10px",
                margin: "5px 0px",
              }}
              name="jobsearch"
              value={formdata.jobsearch}
              onChange={(e) => setJobSerach(e)}
            />
            <TextfieldCustom
              label={Labels?.JobLocation}
              style={{
                borderRadius: "10px",
                margin: "5px 0px",
              }}
              name="city"
              value={formdata.city}
              onChange={(e) => setJobSerach(e)}
            />

            <CustomButton
              label={Labels?.search}
              style={{
                borderRadius: "10px",
                border: "2px solid #005F8E",
                maxHeight: "40px",
                position: "relative",
                top: "7px",
                margin: "5px 0px",
              }}
              onClick={(e) => {
                searchJobs(e);
              }}
            />

            <CustomButton
              label={Labels?.Reset}
              style={{
                borderRadius: "10px",
                maxHeight: "40px",
                position: "relative",
                top: "7px",
                background: "white",
                color: "#005F8E",
                border: "2px solid #005F8E",
                "&:hover": {
                  backgroundColor: "white",
                  color: "#005F8E",
                  border: "2px solid #005F8E",
                },
                "&:active": {
                  backgroundColor: "white",
                  color: "#005F8E",
                  border: "2px solid #005F8E",
                },
                "&:disabled": { color: "white", backgroundColor: "#005F8E" },
              }}
              onClick={() => {
                handleClick();
              }}
            />
          </Stack>
          {/* )} */}

          <div className="flex" style={{}}>
            <p style={{ margin: "10px 10px 10px 0", color: "#005F8E" }}>
              {Labels?.Showing}&nbsp;
              <span style={{ color: "#000" }}>{jobs.length}&nbsp;</span>
              {Labels?.Jobs}&nbsp;
              {jobsearch ? (
                <span style={{ color: "#005F8E" }}>{jobsearch}&nbsp;</span>
              ) : null}
              {city ? (
                <>
                  {Labels?.in}&nbsp;
                  <span style={{ color: "#000" }}>{city}&nbsp;</span>
                </>
              ) : null}
            </p>
          </div>

          <Grid container spacing={2} sx={{ padding: "12px" }}>
            <Grid
              item
              xs={12}
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                  xl: "none",
                },
              }}
            >
              <Accordion expanded={expanded}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  onClick={() => {
                    setExpanded(!expanded);
                  }}
                >
                  {Labels.viewAll} {Labels.JobsList}
                </AccordionSummary>
                <AccordionDetails sx={{ background: "rgb(234,239,241)" }}>
                  <Grid
                    item
                    xs={12}
                    sm={jobDetail ? 12 : 12}
                    md={jobDetail ? 6 : 12}
                    lg={jobDetail ? 6 : 12}
                    xl={jobDetail ? 4 : 12}
                  >
                    <div style={{ position: "relative" }}>
                      <Grid
                        container
                        spacing={2}
                        className="scrollable-container-Jobsearchpostlogin"
                        ref={containerRef}
                        style={{ height: jobDetail ? "85vh" : `100%` }}
                      >
                        {loading ? (
                          <>
                            <Grid
                              item
                              xs={12}
                              sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                              md={jobDetail ? 12 : view == "module" ? 6 : 12}
                              lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                            >
                              <Skeleton
                                variant="rectangular"
                                width={`100%`}
                                style={{
                                  minHeight:
                                    view == "module" ? "350px" : "100px",
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                              md={jobDetail ? 12 : view == "module" ? 6 : 12}
                              lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                            >
                              <Skeleton
                                variant="rectangular"
                                width={`100%`}
                                style={{
                                  minHeight:
                                    view == "module" ? "350px" : "100px",
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                              md={jobDetail ? 12 : view == "module" ? 6 : 12}
                              lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                            >
                              <Skeleton
                                variant="rectangular"
                                width={`100%`}
                                style={{
                                  minHeight:
                                    view == "module" ? "350px" : "100px",
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                              md={jobDetail ? 12 : view == "module" ? 6 : 12}
                              lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                            >
                              <Skeleton
                                variant="rectangular"
                                width={`100%`}
                                style={{
                                  minHeight:
                                    view == "module" ? "350px" : "100px",
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                              md={jobDetail ? 12 : view == "module" ? 6 : 12}
                              lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                            >
                              <Skeleton
                                variant="rectangular"
                                width={`100%`}
                                style={{
                                  minHeight:
                                    view == "module" ? "350px" : "100px",
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                              md={jobDetail ? 12 : view == "module" ? 6 : 12}
                              lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                            >
                              <Skeleton
                                variant="rectangular"
                                width={`100%`}
                                style={{
                                  minHeight:
                                    view == "module" ? "350px" : "100px",
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                              md={jobDetail ? 12 : view == "module" ? 6 : 12}
                              lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                            >
                              <Skeleton
                                variant="rectangular"
                                width={`100%`}
                                style={{
                                  minHeight:
                                    view == "module" ? "350px" : "100px",
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                              md={jobDetail ? 12 : view == "module" ? 6 : 12}
                              lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                            >
                              <Skeleton
                                variant="rectangular"
                                width={`100%`}
                                style={{
                                  minHeight:
                                    view == "module" ? "350px" : "100px",
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                              md={jobDetail ? 12 : view == "module" ? 6 : 12}
                              lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                            >
                              <Skeleton
                                variant="rectangular"
                                width={`100%`}
                                style={{
                                  minHeight:
                                    view == "module" ? "350px" : "100px",
                                }}
                              />
                            </Grid>
                          </>
                        ) : (
                          jobs &&
                          jobs.map((val, i) => (
                            <Grid
                              item
                              xs={12}
                              sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                              md={jobDetail ? 12 : view == "module" ? 6 : 12}
                              lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                              key={i}
                              ref={
                                (selectedJobId || id) == val.eja_id
                                  ? selectedCardRef
                                  : null
                              }
                            >
                              <Card
                                sx={{
                                  backgroundColor:
                                    selectedJobId === val.eja_id
                                      ? "#C0EAFF"
                                      : "",
                                  borderRadius: "17px",
                                  border: "1px solid #EAEAEA",
                                  boxShadow: "none",
                                  cursor: "pointer",
                                  display: "flex",
                                  // justifyContent: "space-between",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  padding: "10px 5px",
                                  position: "relative",
                                  // border: "1px solid grey",
                                  minHeight:
                                    view == "module" ? "200px" : "200px",
                                }}
                                // onClick={() => {
                                //   handleCardClick(val.eja_id);
                                // }}
                              >
                                {(val.status == "Applied" ||
                                  val.status == 4) && (
                                  <span class={`stamp-${view} is-applied`}>
                                    {Labels.Applied}
                                  </span>
                                )}
                                {val.status == "Shortlisted" && (
                                  <span class={`stamp-${view} is-shortlisted`}>
                                    {Labels.Shortlisted}
                                  </span>
                                )}

                                <Grid container spacing={2} direction="row">
                                  <Grid
                                    sx={{
                                      width: "90%",
                                      margin: "auto",
                                      position: "absolute",
                                      top: "10px",
                                      left: "25px",
                                    }}
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                  >
                                    <Grid item direction="row">
                                      <ShareIcon
                                        onClick={() => {
                                          handleLinkClick(val.eja_id);
                                        }}
                                        sx={{
                                          color: "#005F8E",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </Grid>
                                    
                                  </Grid>

                                  {/* Avatar */}
                                  <Grid
                                    item
                                    xs={3}
                                    onClick={() => {
                                      viewDetailedJob(`${val.eja_id}`);
                                      handleCardClick(val);
                                    }}
                                    sm={
                                      jobDetail ? 3 : view == "module" ? 3 : 2
                                    }
                                    md={
                                      jobDetail ? 3 : view == "module" ? 3 : 2
                                    }
                                    lg={
                                      jobDetail ? 3 : view == "module" ? 3 : 2
                                    }
                                    sx={{ position: "relative", top: "30px" }}
                                    direction="row"
                                  >
                                    <Box
                                      display="flex"
                                      justifyContent="center"
                                      alignItems="center"
                                      height="100%"
                                    >
                                      <Avatar
                                        src={
                                          val.companyLogo
                                            ? `${val.companyLogo}`
                                            : null
                                        }
                                        alt=" "
                                        style={{
                                          minWidth: 60,
                                          minHeight: 60,
                                          borderRadius: 5,
                                          border: "1px solid #EAEAEA",
                                          margin: "25px",
                                        }}
                                      >
                                        {val.companyLogo ? null : (
                                          <>
                                            {val?.jobName
                                              ? val.jobName
                                                  .split(" ")
                                                  .map((word) =>
                                                    word[0].toUpperCase()
                                                  )
                                                  .join("")
                                              : null}
                                          </>
                                        )}
                                      </Avatar>
                                    </Box>
                                  </Grid>

                                  {/* Job details */}
                                  <Grid
                                    item
                                    xs={9}
                                    onClick={() => {
                                      viewDetailedJob(`${val.eja_id}`);
                                      handleCardClick(val);
                                    }}
                                    sm={
                                      jobDetail ? 9 : view == "module" ? 9 : 4
                                    }
                                    md={
                                      jobDetail ? 9 : view == "module" ? 9 : 4
                                    }
                                    lg={
                                      jobDetail ? 9 : view == "module" ? 9 : 4
                                    }
                                    sx={{ position: "relative", top: "30px" }}
                                    //   onClick={() =>
                                    //     redirect(`/detailedJobView/${val.eja_id}`)
                                    //   }
                                  >
                                    <Box
                                      sx={{
                                        direction: "row",
                                        Width: 100,
                                      }}
                                    >
                                      <Typography
                                        variant="h6"
                                        sx={{
                                          color: "#000000",
                                          fontWeight: 600,
                                        }}
                                      >
                                        {val.jobName.length > 20
                                          ? val.jobName.slice(0, 18) + "..."
                                          : val.jobName}
                                      </Typography>
                                      <Typography
                                        variant="subtitle1"
                                        sx={{ color: "#00000" }}
                                      >
                                        {val.employer.length > 20
                                          ? val.employer.slice(0, 18) + "..."
                                          : val.employer}
                                      </Typography>
                                      <Typography variant="body2">
                                        {val.city}
                                      </Typography>
                                    </Box>
                                  </Grid>

                                  {/* Job Box */}
                                  <Grid
                                    item
                                    xs={12}
                                    onClick={() => {
                                      viewDetailedJob(`${val.eja_id}`);
                                      handleCardClick(val);
                                    }}
                                    sm={
                                      jobDetail ? 12 : view == "module" ? 12 : 6
                                    }
                                    md={
                                      jobDetail ? 12 : view == "module" ? 12 : 5
                                    }
                                    lg={
                                      jobDetail ? 12 : view == "module" ? 12 : 5
                                    }
                                   
                                    //   onClick={() =>redirect(`/detailedJobView/${val.eja_id}`)}
                                  >
                                    <Box
                                      sx={{
                                        position: "relative", // Ensure the parent container is a positioning context
                                        display: "flex",
                                        paddingRight: 4,
                                        minHeight: "0px", // Set a minimum height to create space for other content
                                      }}
                                    >
                                      <Grid item xs={4} direction="column">
                                        {val.jobTypes ? (
                                          <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={1}
                                            mx={1}
                                          >
                                            {val.jobTypes
                                              .split(",")
                                              .map((jobType, i) => (
                                                <Chip
                                                  key={jobType}
                                                  label={jobType}
                                                  size="small"
                                          sx={{
                                            fontWeight:
                                              selectedJobId === val.eja_id
                                                ? 600
                                                : 400,
                                                backgroundColor:
                                    selectedJobId === val.eja_id
                                      ? "#C0EAFF"
                                      : "#F3FBFF",
                                                // backgroundColor:
                                                // "#F3FBFF",
                                            color: "#005F8E",
                                            // background: "#FFFFFF",
                                            fontSize: "12px",
                                            borderRadius: 1,
                                            border: "1px solid #005F8E",
                                            width: "100px", // Set your desired fixed width
                                            whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                            wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                          }}
                                                />
                                              ))
                                              .slice(0, 2)}
                                          </Stack>
                                        ) : null}

                                        <Stack
                                          direction="row"
                                          alignItems="center"
                                          spacing={1}
                                          mx={1}
                                          my={2}
                                        >
                                          <Chip
                                            key={""}
                                            label={`${val?.typePercentage}0%`}
                                            size="small"
                                            sx={{
                                              fontWeight:
                                                selectedJobId === val.eja_id
                                                  ? 600
                                                  : 400,
                                                  backgroundColor:
                                      selectedJobId === val.eja_id
                                        ? "#C0EAFF"
                                        : "#F3FBFF",
                                                  // backgroundColor:
                                                  // "#F3FBFF",
                                              color: "#005F8E",
                                              // background: "#FFFFFF",
                                              fontSize: "12px",
                                              borderRadius: 1,
                                              border: "1px solid #005F8E",
                                              width: "100px", // Set your desired fixed width
                                              whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                              wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                            }}
                                          />
                                        </Stack>
                                      </Grid>
                                        <Grid item xs={4} direction="column">
                                          <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={1}
                                            mx={1}

                                            // style={{ border: '1px solid red' }}
                                          >
                                            <Chip
                                              // key={index}
                                              label={val?.officePresence}
                                              size="small"
                                          sx={{
                                            fontWeight:
                                              selectedJobId === val.eja_id
                                                ? 600
                                                : 400,
                                                backgroundColor:
                                    selectedJobId === val.eja_id
                                      ? "#C0EAFF"
                                      : "#F3FBFF",
                                                // backgroundColor:
                                                // "#F3FBFF",
                                            color: "#005F8E",
                                            // background: "#FFFFFF",
                                            fontSize: "12px",
                                            borderRadius: 1,
                                            border: "1px solid #005F8E",
                                            width: "100px", // Set your desired fixed width
                                            whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                            wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                          }}
                                            />
                                          </Stack>

                                          <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={1}
                                            mx={1}
                                            my={2}
                                          >
                                            <Chip
                                              key={""}
                                              label={
                                                val.officePercentage == 100
                                                  ? `${val.officePercentage}%`
                                                  : val.officePercentage == 0
                                                  ? `${val.remotePercentage}%`
                                                  : val.officePercentage > 0 &&
                                                    val.officePercentage < 100
                                                  ? `${val.officePercentage}% (${Labels.Office})`
                                                  : null
                                              }
                                              size="small"
                                          sx={{
                                            fontWeight:
                                              selectedJobId === val.eja_id
                                                ? 600
                                                : 400,
                                                backgroundColor:
                                    selectedJobId === val.eja_id
                                      ? "#C0EAFF"
                                      : "#F3FBFF",
                                                // backgroundColor:
                                                // "#F3FBFF",
                                            color: "#005F8E",
                                            // background: "#FFFFFF",
                                            fontSize: "12px",
                                            borderRadius: 1,
                                            border: "1px solid #005F8E",
                                            width: "100px", // Set your desired fixed width
                                            whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                            wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                          }}
                                            />
                                          </Stack>
                                        </Grid>
                                        <Grid item xs={4} direction="column">
                                          <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={1}
                                            mx={1}
                                          >
                                            <Chip
                                              key={""}
                                              label={`${val?.workingHours} hrs/${val?.hourPeriod}`}
                                              size="small"
                                              sx={{
                                                fontWeight:
                                                  selectedJobId === val.eja_id
                                                    ? 600
                                                    : 400,
                                                    backgroundColor:
                                        selectedJobId === val.eja_id
                                          ? "#C0EAFF"
                                          : "#F3FBFF",
                                                    // backgroundColor:
                                                    // "#F3FBFF",
                                                color: "#005F8E",
                                                // background: "#FFFFFF",
                                                fontSize: "12px",
                                                borderRadius: 1,
                                                border: "1px solid #005F8E",
                                                width: "100px", // Set your desired fixed width
                                                whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                                wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                              }}
                                            />
                                          </Stack>

                                          <Stack
                                            direction="row"
                                            alignItems="center"
                                            spacing={1}
                                            mx={1}
                                            my={2}
                                          >
                                            <Chip
                                              key={""}
                                              label={`${val?.salary} ${val?.currency}/${val?.salaryPeriod} `}
                                              size="small"
                                          sx={{
                                            fontWeight:
                                              selectedJobId === val.eja_id
                                                ? 600
                                                : 400,
                                                backgroundColor:
                                    selectedJobId === val.eja_id
                                      ? "#C0EAFF"
                                      : "#F3FBFF",
                                                // backgroundColor:
                                                // "#F3FBFF",
                                            color: "#005F8E",
                                            // background: "#FFFFFF",
                                            fontSize: "12px",
                                            borderRadius: 1,
                                            border: "1px solid #005F8E",
                                            width: "100px", // Set your desired fixed width
                                            whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                            wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                          }}
                                            />
                                          </Stack>
                                        </Grid>
                                    </Box>
                                    
                                  </Grid>
                                  
                                    
                                 
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      margin: "0px 12px",
                                      justifyContent: "space-between",
                                      width: "100%",
                                    }}
                                  >
                                    <Typography
                                      variant="body2"
                                      sx={{ marginLeft: 2 }}
                                    >
                                      {val.days}
                                    </Typography>
                                    <Typography variant="body2">
                                      {val.jobseekers}{" "}
                                      {val.jobseekers == 1
                                        ? Labels?.Applicant
                                        : Labels?.Applicants}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Card>
                            </Grid>
                          ))
                        )}
                      </Grid>
                    </div>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={2}>
                <Grid
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "block",
                      lg: "block",
                      xl: "block",
                    },
                  }}
                  item
                  xs={12}
                  sm={jobDetail ? 12 : 12}
                  md={jobDetail ? 6 : 12}
                  lg={jobDetail ? 6 : 12}
                  xl={jobDetail ? 4 : 12}
                >
                  <div style={{ position: "relative" }}>
                    <Grid
                      container
                      spacing={2}
                      className="scrollable-container-Jobsearchpostlogin"
                      ref={containerRef}
                      style={{ height: jobDetail ? "85vh" : `100%` }}
                    >
                      {loading ? (
                        <>
                          <Grid
                            item
                            xs={12}
                            sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                            md={jobDetail ? 12 : view == "module" ? 6 : 12}
                            lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                          >
                            <Skeleton
                              variant="rectangular"
                              width={`100%`}
                              style={{
                                minHeight: view == "module" ? "350px" : "100px",
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                            md={jobDetail ? 12 : view == "module" ? 6 : 12}
                            lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                          >
                            <Skeleton
                              variant="rectangular"
                              width={`100%`}
                              style={{
                                minHeight: view == "module" ? "350px" : "100px",
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                            md={jobDetail ? 12 : view == "module" ? 6 : 12}
                            lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                          >
                            <Skeleton
                              variant="rectangular"
                              width={`100%`}
                              style={{
                                minHeight: view == "module" ? "350px" : "100px",
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                            md={jobDetail ? 12 : view == "module" ? 6 : 12}
                            lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                          >
                            <Skeleton
                              variant="rectangular"
                              width={`100%`}
                              style={{
                                minHeight: view == "module" ? "350px" : "100px",
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                            md={jobDetail ? 12 : view == "module" ? 6 : 12}
                            lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                          >
                            <Skeleton
                              variant="rectangular"
                              width={`100%`}
                              style={{
                                minHeight: view == "module" ? "350px" : "100px",
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                            md={jobDetail ? 12 : view == "module" ? 6 : 12}
                            lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                          >
                            <Skeleton
                              variant="rectangular"
                              width={`100%`}
                              style={{
                                minHeight: view == "module" ? "350px" : "100px",
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                            md={jobDetail ? 12 : view == "module" ? 6 : 12}
                            lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                          >
                            <Skeleton
                              variant="rectangular"
                              width={`100%`}
                              style={{
                                minHeight: view == "module" ? "350px" : "100px",
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                            md={jobDetail ? 12 : view == "module" ? 6 : 12}
                            lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                          >
                            <Skeleton
                              variant="rectangular"
                              width={`100%`}
                              style={{
                                minHeight: view == "module" ? "350px" : "100px",
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                            md={jobDetail ? 12 : view == "module" ? 6 : 12}
                            lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                          >
                            <Skeleton
                              variant="rectangular"
                              width={`100%`}
                              style={{
                                minHeight: view == "module" ? "350px" : "100px",
                              }}
                            />
                          </Grid>
                        </>
                      ) : (
                        jobs &&
                        jobs.map((val, i) => (
                          <Grid
                            item
                            xs={12}
                            sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                            md={jobDetail ? 12 : view == "module" ? 6 : 12}
                            lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                            key={i}
                            ref={
                              (selectedJobId || id) == val.eja_id
                                ? selectedCardRef
                                : null
                            }
                          >
                            <Card
                              sx={{
                                backgroundColor:
                                  (selectedJobId || id) == val.eja_id
                                    ? "#C0EAFF"
                                    : "",
                                border: "1px solid #EAEAEA",
                                borderRadius: "17px",
                                boxShadow: "none",
                                cursor: "pointer",
                                display: "flex",
                                // justifyContent: "space-between",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "5px",
                                position: "relative",
                                minHeight: view == "module" ? "270px" : "150px",
                                overflow: "auto",
                              }}
                              
                            >
                              {(val.status == "Applied" || val.status == 4) && (
                                <span class={`stamp-${view} is-applied`}>
                                  {Labels.Applied}
                                </span>
                              )}
                              {val.status == "Shortlisted" && (
                                <span class={`stamp-${view} is-shortlisted`}>
                                  {Labels.Shortlisted}
                                </span>
                              )}

                              <Grid container spacing={2} direction="row">
                              <Grid
                                sx={{
                                  width: "90%",
                                  margin: "auto",
                                  position: "absolute",
                                  top: "10px",
                                  left: "25px",
                                }}
                                container
                                direction="row"
                                justifyContent="space-between"
                              >
                                <Grid item direction="row">
                                  <ShareIcon
                                    onClick={() => {
                                      handleLinkClick(val.eja_id);
                                    }}
                                    sx={{
                                      color: "#005F8E",
                                      cursor: "pointer",
                                    }}
                                  />
                                </Grid>
                                
                              </Grid>
                                {/* Avatar */}
                                <Grid
                                item
                                xs={3}
                                onClick={() => {
                                  viewDetailedJob(`${val.eja_id}`);
                                  handleCardClick(val.eja_id);
                                }}
                                sm={jobDetail ? 3 : view == "module" ? 3 : 2}
                                md={jobDetail ? 3 : view == "module" ? 3 : 2}
                                lg={jobDetail ? 3 : view == "module" ? 3 : 2}
                                sx={{ position: "relative", top: "30px" }}
                                direction="row"
                              >
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  height="100%"
                                >
                                  <Avatar
                                    src={
                                      val.companyLogo
                                        ? `${val.companyLogo}`
                                        : null
                                    }
                                    alt=" "
                                    style={{
                                      minWidth: 60,
                                      minHeight: 60,
                                      margin: "25px",
                                      borderRadius: 5,
                                      border: "1px solid #EAEAEA",
                                    }}
                                  >
                                    {val.companyLogo ? null : (
                                      <>
                                        {val?.jobName
                                          ? val.jobName
                                              .split(" ")
                                              .map((word) =>
                                                word[0].toUpperCase()
                                              )
                                              .join("")
                                          : null}
                                      </>
                                    )}
                                  </Avatar>
                                </Box>
                              </Grid>
                                  {/* Job details */}
                              <Grid
                                item
                                xs={9}
                                onClick={() => {
                                  viewDetailedJob(`${val.eja_id}`);
                                  handleCardClick(val.eja_id);
                                }}
                                sm={jobDetail ? 9 : view == "module" ? 9 : 4}
                                md={jobDetail ? 9 : view == "module" ? 9 : 4}
                                lg={jobDetail ? 9 : view == "module" ? 9 : 4}
                                sx={{ position: "relative", top: "30px" }}
                                //   onClick={() =>
                                //     redirect(`/detailedJobView/${val.eja_id}`)
                                //   }
                              >
                                <Box
                                  sx={{
                                    direction: "row",
                                    Width: 100,
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    sx={{ color: "#000000", fontWeight: 600 }}
                                  >
                                    {val.jobName}
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ color: "#000000" }}
                                  >
                                    {val.employer}
                                  </Typography>
                                  <Typography variant="body2">
                                    {val.city}
                                  </Typography>

                                  <Typography variant="body2">
                                    {val.days}
                                  </Typography>
                                  <Typography variant="body2">
                                    {val.jobseekers}{" "}
                                    {val.jobseekers?.length == 1
                                      ? Labels?.Applicant
                                      : Labels?.Applicants}
                                  </Typography>
                                </Box>
                              </Grid>

                               {/* Job Box */}
                              <Grid
                                item
                                xs={12}
                                onClick={() => {
                                  viewDetailedJob(`${val.eja_id}`);
                                  handleCardClick(val.eja_id);
                                }}
                                sm={jobDetail ? 12 : view == "module" ? 12 : 6}
                                md={jobDetail ? 12 : view == "module" ? 12 : 5}
                                lg={jobDetail ? 12 : view == "module" ? 12 : 5}
                                mt={2}
                                //   onClick={() =>redirect(`/detailedJobView/${val.eja_id}`)}
                              >
                                <Box
                                  sx={{
                                    position: "relative", // Ensure the parent container is a positioning context
                                    display: "flex",
                                    paddingRight: 4,
                                    minHeight: "0px", // Set a minimum height to create space for other content
                                  }}
                                >
                                  <Grid item xs={4} direction="column">
                                    {val.jobTypes ? (
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={1}
                                        mx={1}
                                        my={2}
                                      >
                                        {val.jobTypes
                                          .split(",")
                                          .map((jobType, i) => (
                                            <Chip
                                              key={jobType}
                                              label={jobType}
                                              size="small"
                                              sx={{
                                                fontWeight:
                                                  selectedJobId === val.eja_id
                                                    ? 600
                                                    : 400,
                                                backgroundColor:
                                                  selectedJobId === val.eja_id
                                                    ? "#C0EAFF"
                                                    : "#F3FBFF",
                                                // backgroundColor:
                                                // "#F3FBFF",
                                                color: "#005F8E",
                                                // background: "#FFFFFF",
                                                fontSize: "12px",
                                                borderRadius: 1,
                                                border: "1px solid #005F8E",
                                                width: "100px", // Set your desired fixed width
                                                whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                                wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                              }}
                                            />
                                          ))
                                          .slice(0, 2)}
                                      </Stack>
                                    ) : null}

                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={1}
                                      mx={1}
                                      my={2}
                                    >
                                      <Chip
                                        key={""}
                                        label={`${val?.typePercentage}0%`}
                                        size="small"
                                        sx={{
                                          fontWeight:
                                            selectedJobId === val.eja_id
                                              ? 600
                                              : 400,
                                          backgroundColor:
                                            selectedJobId === val.eja_id
                                              ? "#C0EAFF"
                                              : "#F3FBFF",
                                          // backgroundColor:
                                          // "#F3FBFF",
                                          color: "#005F8E",
                                          // background: "#FFFFFF",
                                          fontSize: "12px",
                                          borderRadius: 1,
                                          border: "1px solid #005F8E",
                                          width: "100px", // Set your desired fixed width
                                          whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                          wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                        }}
                                      />
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={4} direction="column">
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={1}
                                      mx={1}
                                      my={2}
                                      // style={{ border: '1px solid red' }}
                                    >
                                      <Chip
                                        // key={index}
                                        label={val?.officePresence}
                                        size="small"
                                        sx={{
                                          fontWeight:
                                            selectedJobId === val.eja_id
                                              ? 600
                                              : 400,
                                          backgroundColor:
                                            selectedJobId === val.eja_id
                                              ? "#C0EAFF"
                                              : "#F3FBFF",
                                          // backgroundColor:
                                          // "#F3FBFF",
                                          color: "#005F8E",
                                          // background: "#FFFFFF",
                                          fontSize: "12px",
                                          borderRadius: 1,
                                          border: "1px solid #005F8E",
                                          width: "100px", // Set your desired fixed width
                                          whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                          wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                        }}
                                      />
                                    </Stack>

                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={1}
                                      mx={1}
                                      my={2}
                                    >
                                      <Chip
                                        key={""}
                                        label={
                                          val.officePercentage == 100
                                            ? `${val.officePercentage}%`
                                            : val.officePercentage == 0
                                            ? `${val.remotePercentage}%`
                                            : val.officePercentage > 0 &&
                                              val.officePercentage < 100
                                            ? `${val.officePercentage}% (${Labels.Office})`
                                            : null
                                        }
                                        size="small"
                                        sx={{
                                          fontWeight:
                                            selectedJobId === val.eja_id
                                              ? 600
                                              : 400,
                                          backgroundColor:
                                            selectedJobId === val.eja_id
                                              ? "#C0EAFF"
                                              : "#F3FBFF",
                                          // backgroundColor:
                                          // "#F3FBFF",
                                          color: "#005F8E",
                                          // background: "#FFFFFF",
                                          fontSize: "12px",
                                          borderRadius: 1,
                                          border: "1px solid #005F8E",
                                          width: "100px", // Set your desired fixed width
                                          whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                          wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                        }}
                                      />
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={4} direction="column">
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={1}
                                      mx={1}
                                      my={2}
                                    >
                                      <Chip
                                        key={""}
                                        label={`${val?.workingHours} hrs/${val?.hourPeriod}`}
                                        size="small"
                                        sx={{
                                          fontWeight:
                                            selectedJobId === val.eja_id
                                              ? 600
                                              : 400,
                                          backgroundColor:
                                            selectedJobId === val.eja_id
                                              ? "#C0EAFF"
                                              : "#F3FBFF",
                                          // backgroundColor:
                                          // "#F3FBFF",
                                          color: "#005F8E",
                                          // background: "#FFFFFF",
                                          fontSize: "12px",
                                          borderRadius: 1,
                                          border: "1px solid #005F8E",
                                          width: "100px", // Set your desired fixed width
                                          whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                          wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                        }}
                                      />
                                    </Stack>

                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={1}
                                      mx={1}
                                      my={2}
                                    >
                                      <Chip
                                        key={""}
                                        label={`${val?.salary} ${val?.currency}/${val?.salaryPeriod} `}
                                        size="small"
                                        sx={{
                                          fontWeight:
                                            selectedJobId === val.eja_id
                                              ? 600
                                              : 400,
                                          backgroundColor:
                                            selectedJobId === val.eja_id
                                              ? "#C0EAFF"
                                              : "#F3FBFF",
                                          // backgroundColor:
                                          // "#F3FBFF",
                                          color: "#005F8E",
                                          // background: "#FFFFFF",
                                          fontSize: "12px",
                                          borderRadius: 1,
                                          border: "1px solid #005F8E",
                                          width: "100px", // Set your desired fixed width
                                          whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                          wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                        }}
                                      />
                                    </Stack>
                                  </Grid>
                                </Box>
                              </Grid>
                                
                              </Grid>
                            </Card>
                          </Grid>
                        ))
                      )}
                    </Grid>
                  </div>
                </Grid>

                {jobDetail && jobs.length ? (
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={8}>
                    <JobDetailedview jobId={jobId} />
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default JobSerachPreloginGrid;
