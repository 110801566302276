import {
  Box,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useRef, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Button, Textarea } from "@mui/joy";
import { errorNotify, succesNotify,warningNotify } from "../../CommonCode/Commonfunc";
import { axiosAPI, axiosupload } from "../../Axios/Axios";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import TextfieldCustom from "../../Components/TextfieldCustom";
import { useSelector } from "react-redux";
import {profanityfn,profanitySend}  from "../../Profanity/Profanity";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {xs:`80%`, sm:'40%'},
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

export default function EnquiryMessageModal({ open, handleClose, jobId,id }) {
  const Labels = useSelector((state) => state.allLabels.labels);

  const [message, setMessage] = useState("");

  const [btndisable,setbtndisable] = useState(false)

  const sendChatProfanity = () => {
    const data = {
      "message": message,
  };

    let profanityDetected;
    for (const key in data) {
      if (profanitySend(ProfanityList, data[key])) {
        profanityDetected = true;
        break;
      } else {
        profanityDetected = false;
      }
    }

    if (profanityDetected == false) {
      sendMessage();
    } else {
      warningNotify(Labels.ProfanityLabel);
    }
  };
  
  const sendMessage = async () => {
    if (!message || message.trim().length==0) {
      errorNotify(Labels.writeMessage);
      return;
    } else if (message.length > 1000) {
      errorNotify(Labels.messageValidation);
      return;
    } else {
      setbtndisable(true)
      const result = await axiosAPI.post("/saveEnquiry", {
        jobid: jobId || id,
        message: message,
      });

      const { status, message: responseMessage } = result.data;
      if (status) {
        succesNotify(responseMessage);
        handleClose(false);
        setMessage("");
        setbtndisable(false)
      } else {
        errorNotify(responseMessage);
        setbtndisable(false)
      }
    }
  };

  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)

  return (
    <Fragment>
      <Modal
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                color: "#005F8E",
                fontWeight: 600,
                fontSize: "17px",
              }}
            >
              {Labels.enquiry}
            </Typography>
            <HighlightOffIcon
              sx={{
                color: "#005F8E",
                cursor: "pointer",
              }}
              onClick={() => handleClose(false)}
            />
          </Box>

          <Box
            sx={{
              maxHeight: "80vh",
              overflowY: "auto",
              overflowX: "hidden",
              p: 1.5,
            }}
          >
            <Textarea
              minRows={5}
              placeholder={Labels.Typehere}
              value={message}
              onChange={(e) => setMessage(profanityfn(ProfanityList,e.target.value))}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              pt: 1,
            }}
          >
            <Button
              variant="solid"
              sx={{
                background: "#005F8E",
                ml: 0.5,
                width: 90,
                borderRadius: "5px",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontStyle: "normal",
                fontSize: "15px",
                "&:hover": {
                  backgroundColor: "#005F8E",
                },
                "&:active": {
                  backgroundColor: "#005F8E",
                },
                "&:disabled": { color:"white",
                  backgroundColor: "grey",
                },
              }}
              disabled={btndisable}
              onClick={sendChatProfanity}
            >
              {Labels.Send}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
}
