import {
  Box,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
  FormControl,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Card,
  Avatar,
  Button,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  errorNotify,warningNotify,
  succesNotify,
  useStyles,
} from "../../CommonCode/Commonfunc";
import { useNavigate, useParams } from "react-router-dom";
import { axiosAPI, axiosupload } from "../../Axios/Axios";
import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import ReplyIcon from "@mui/icons-material/Reply";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import SendIcon from "@mui/icons-material/Send";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CommentsDisabledIcon from "@mui/icons-material/CommentsDisabled";
import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
  parseISO,
} from "date-fns";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForum from "./DeleteForum";
import DeleteForumComment from "./deleteForumComment";
import DeleteForumReply from "./deleteForumReply";
import CloseForum from "./closeForum";
import {profanityfn,profanitySend}  from "../../Profanity/Profanity";

function ForumDetails() {
  const { id } = useParams();
  const inputRef = useRef(null);
  const redirect = useNavigate();
  const [myforumInfo, setmyForumInfo] = useState({});

  const classes = useStyles();
  const [attachment, setAttachment] = useState("");
  const [refreshState, setRefreshState] = useState(false);

  // -------------------- Employer Data --------------------
  const employerLoginData = useSelector(
    (state) => state.createLoginDetails.loginDetails
  );
  const [employerData, setEmployerData] = useState({});
  const getEmployerData = async () => {
    const postData = {
      userid: employerLoginData.uld_id,
    };
    const result = await axiosAPI.post("/employerProfile", postData);
    const { success, data, message } = result.data;
    if (success) {
      setEmployerData(data);
    } else {
      //// console.log(message);
    }
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  const tokens = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("employerToken"),
    },
  };

  const [companyProfilee, setcompanyProfilee] = useState({});
  
  // -------------------- Employer Data --------------------
  useEffect(() => {
    axiosAPI
      .post("/getCompanyProfile")
      .then((res) => {
        setcompanyProfilee(res.data.data);
      })
      .catch((err) => {
        //// console.log(err);
      });
  }, []);

  // -------------------- Forum Details --------------------

  const getForumDetails = async () => {
    try {
      if (id) {
        const result = await axiosAPI.get(`/forumDetails?forumid=${id}`);
        const { status, data } = result.data;
        if (status) {
          setmyForumInfo(data);
        } else {
          setmyForumInfo({});
          redirect(`/forumList`)
        }
      }
    } catch (error) {
      redirect(`/forumList`)
      //// console.log(error.message);
    }
  };

  // -------------------- All Forum --------------------
  const [selectedForumID, setSelectedForumID] = useState(null);
  const [allForum, setAllForum] = useState([]);
  const [loading, setloading] = useState(true);

  const getAllForum = async () => {
    try {
      const result = await axiosAPI.post("/getForumList");
      const { data, status } = result.data;
      if (status) {
        setAllForum(data);
        setSelectedForumID(data.forumID);
        setloading(false);
      } else {
        setloading(false);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };

  const viewDetailedForum = (id) => {
    redirect(`/forum-detail/${id}`);
  };
  // -------------------- Time Difference --------------------
  const getTimeDifference = (createdOn) => {
    const dateNow = new Date();
    const createdDate = parseISO(createdOn);

    const diffInSeconds = differenceInSeconds(dateNow, createdDate);
    if (diffInSeconds < 60) {
      return `${diffInSeconds} ${Labels?.secAgo}`;
    }

    const diffInMinutes = differenceInMinutes(dateNow, createdDate);
    if (diffInMinutes < 60) {
      return `${diffInMinutes} ${Labels?.minAgo}`;
    }

    const diffInHours = differenceInHours(dateNow, createdDate);
    if (diffInHours < 24) {
      return `${diffInHours} ${Labels?.hourAgo}`;
    }

    const diffInDays = differenceInDays(dateNow, createdDate);
    if (diffInDays < 30) {
      return `${diffInDays}  ${Labels?.daysago}`;
    }

    const diffInMonths = differenceInMonths(dateNow, createdDate);
    return `${diffInMonths} ${Labels?.monthAgo}`;
  };

  // -------------------- Edit Hover --------------------
  const [editHover, setEditHover] = useState(false);
  const handleEditMouseEnter = () => {
    setEditHover(true);
  };
  const handleEditMouseLeave = () => {
    setEditHover(false);
  };

  // -------------------- Delete Hover --------------------
  const [deleteHover, setDeleteHover] = useState(false);
  const handleDeleteMouseEnter = () => {
    setDeleteHover(true);
  };

  const handleDeleteMouseLeave = () => {
    setDeleteHover(false);
  };

  const [openDeleteModal, setDeleteModal] = useState(false);
  const closeDeleteForumModal = () => {
    setDeleteModal(false);
    setApiCalled(Math.random());
  };

  // -------------------- Close Hover --------------------
  const [closeHover, setCloseHover] = useState(false);
  const handleCloseMouseEnter = () => {
    setCloseHover(true);
  };
  const handleCloseMouseLeave = () => {
    setCloseHover(false);
  };

  const [openCloseModal, setCloseModal] = useState(false);
  const closeClosedForumModal = () => {
    setCloseModal(false);
    setApiCalled(Math.random());
  };

  // -------------------- useEffect --------------------
  const [isApiCalled, setApiCalled] = useState(0);

  useEffect(() => {
    getForumDetails();
    getAllForum();
    getForumDiscussion();
    const intervalId = setInterval(getForumDiscussion, 60000);
    return () => clearInterval(intervalId);
  }, [isApiCalled, id]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getEmployerData();
  }, []);

  // -------------------- Post Message Response --------------------
  const [forumDiscussion, setForumDiscussion] = useState({});
  const [btnview, setbtnview] = useState(false);
  const getForumDiscussion = async () => {
    try {
      const result = await axiosAPI.post("/getGroupDiscussion", {
        forumid: id,
        type: "forum",
      });
      const { status, data } = result.data;
      if (status) {
        setForumDiscussion(data);
        setbtnview(true);
        // setApiCalled(Math.random());
      } else {
        setForumDiscussion({});
        setbtnview(true);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };

  // -------------------- Add Comment --------------------
  const [commentContent, setCommentContent] = useState("");
  const [attachmentPreview, setAttachmentPreview] = useState(null);

  const [btndisable, setbtndisable] = React.useState(false);

  const profanityCheckerAddComent = (e,itemid) => {

    const data = {
      "message": commentContent,
      "forumid": id,
      "type": "forum",
      "parentID": itemid,
      "language": localStorage.getItem("language")
  };

    let profanityDetected;
    for (const key in data) {
      if (profanitySend(ProfanityList, data[key])) {
        profanityDetected = true;
        break;
      } else {
        profanityDetected = false;
      }
    }

    if (profanityDetected == false) {
      addPostComment(e,itemid);
    } else {
      warningNotify(Labels.ProfanityLabel);
    }
  };

  const addPostComment = async (e, parentId) => {
    e.preventDefault();
    setbtndisable(true);

    try {
      const data = new FormData();
      data.append("attachment", attachment);
      data.append("message", commentContent);
      data.append("forumid", id);
      data.append("type", "forum");
      data.append("parentID", parentId);
      data.append('language',localStorage.getItem("language"))
      const result = await axiosupload.post("/addReply", data, tokens);
      const { status, message } = result.data;
      if (status) {
        succesNotify(message);
        setApiCalled(Math.random());
        setCommentContent("");
        setAttachmentPreview(null);
        setAttachment(null);
        setbtndisable(false);
      } else {
        errorNotify(message);
        setbtndisable(false);
      }
    } catch (error) {
      //// console.log(error.message);
      setbtndisable(false);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setAttachment(selectedFile);
    const reader = new FileReader();
    reader.onload = (e) => {
      setAttachmentPreview(e.target.result);
    };
    reader.readAsDataURL(selectedFile);
    setRefreshState((prev) => !prev);
  };

  const removeAttachment = () => {
    setAttachment(null);
    setAttachmentPreview(null);
  };

  // -------------------- Delete Comment --------------------
  const [commentDeleteModal, setCommentDeleteModal] = useState(false);
  const [commentId, setCommentId] = useState("");

  const deleteComment = (event, id) => {
    event.stopPropagation();
    setCommentId(id);
    setCommentDeleteModal(true);
  };
  const closeDeleteCommentModal = () => {
    setCommentDeleteModal(false);
    setApiCalled(Math.random());
  };
  // -------------------- Add Reply --------------------
  const [replyBoxStatus, setReplyBoxStatus] = useState({});

  const openReplyBox = (postId) => {
    setReplyBoxStatus((prevState) => ({
      ...prevState,
      [postId]: " ",
    }));
  };

  const closeReplyBox = (postId) => {
    setReplyBoxStatus((prevState) => ({
      ...prevState,
      [postId]: "",
    }));
  };
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)

  const onChangeReply = (e, postId) => {
    setReplyBoxStatus((prevState) => ({
      ...prevState,
      [postId]: profanityfn(ProfanityList,e.target.value),
    }));
  };

  const profanityCheckerAddComentReply = (e,itemid) => {

    const data = {
      "message": commentContent,
      "forumid": id,
      "type": "forum",
      "parentID": itemid,
      "language": localStorage.getItem("language")
  };

    let profanityDetected;
    for (const key in data) {
      if (profanitySend(ProfanityList, data[key])) {
        profanityDetected = true;
        break;
      } else {
        profanityDetected = false;
      }
    }

    if (profanityDetected == false) {
      addCommentReply(e,itemid);
    } else {
      warningNotify(Labels.ProfanityLabel);
    }
  };


  const addCommentReply = async (e, parentID) => {
    e.preventDefault();
    setbtndisable(true);

    try {
      const postData = {
        message: replyBoxStatus[parentID],
        forumid: id,
        type: "forum",
        parentID: parentID,
        language:localStorage.getItem("language")
      };
      const result = await axiosAPI.post("/addCommentReply", postData);
      const { status, message } = result.data;
      if (status) {
        setbtndisable(false);

        succesNotify(message);
        setApiCalled(Math.random());
        setReplyBoxStatus((prevState) => ({
          ...prevState,
          [parentID]: " ",
        }));
      } else {
        setbtndisable(false);

        setReplyBoxStatus((prevState) => ({
          ...prevState,
          [parentID]: " ",
        }));
        errorNotify(message);
      }
    } catch (error) {
      setbtndisable(false);

      //// console.log(error.message);
    }
  };

  // -------------------- Delete Reply --------------------
  const [replyDeleteModal, setReplyDeleteModal] = useState(false);
  const [replyId, setReplyId] = useState("");

  const deleteReply = (event, id) => {
    event.stopPropagation();
    setReplyId(id);
    setReplyDeleteModal(true);
  };
  const closeDeleteReplyModal = () => {
    setReplyDeleteModal(false);
    setApiCalled(Math.random());
  };

  return (
    <>
      {/* -------------------- Delete Dialog -------------------- */}
      <>
        <DeleteForum
          open={openDeleteModal}
          handleClose={closeDeleteForumModal}
          forumId={id}
        />
        <CloseForum
          open={openCloseModal}
          handleClose={closeClosedForumModal}
          forumId={id}
        />
        <DeleteForumComment
          open={commentDeleteModal}
          handleClose={closeDeleteCommentModal}
          commentId={commentId}
        />
        <DeleteForumReply
          open={replyDeleteModal}
          handleClose={closeDeleteReplyModal}
          replyId={replyId}
        />
      </>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={8}>
          <Paper
            elevation={0}
            sx={{
              my: 1,
              pt: 1,
              position: "relative",
              background: "transparent",
            }}
          >
            <Box>
              {btnview ? (
                forumDiscussion.length > 0 ? (
                  forumDiscussion.map((item, i) => (
                    <div style={{ background: "white", margin: "0px auto" }}>
                      <Box sx={{ overflow: "auto", height: "auto" }}>
                        <Box
                          key={i}
                          sx={{
                            border: "1px solid #0000001A",
                            borderRadius: 1,
                            p: 2,
                            m: 2,
                            zIndex: 2,
                          }}
                          className={classes.customScrollbar}
                        >
                          <Stack
                            direction="row"
                            spacing={2}
                            alignItems="center"
                          >
                            {item.postedLogo?.endsWith("/") ? null : (
                              <Avatar
                                src={`${item.postedLogo}`}
                                alt=" "
                                style={{
                                  maxWidth: 30,
                                  maxHeight: 30,
                                  margin: "10px",
                                }}
                              />
                            )}
                            <Box>
                              <Typography variant="subtitle2">
                                {item.postedName}
                              </Typography>
                              <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center"
                              >
                                <Typography variant="body2">
                                  {item.createdOn}
                                </Typography>
                              </Stack>
                            </Box>
                          </Stack>
                          <Box sx={{ mt: 1 }}>
                            <Typography variant="body1">
                              {item.message}
                            </Typography>

                            {item.attachmentsPath ? (
                              <img
                                src={
                                  item.attachmentsPath === null ||
                                  "" ||
                                  item.attachmentsPath?.endsWith("/")
                                    ? null
                                    : `${item.attachmentsPath}`
                                }
                                alt=""
                                style={{
                                  maxHeight: 200,
                                  margin: "10px 0",
                                }}
                              />
                            ) : null}
                          </Box>
                          {item.comments.length > 0 ? (
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={0.5}
                            >
                              <ChatBubbleOutlineIcon />
                              <Typography variant="caption">
                                {item.comments.length}{" "}
                                {item.comments.length === 1
                                  ? Labels?.comment
                                  : Labels?.comments}
                              </Typography>
                            </Stack>
                          ) : null}
                        </Box>
                        <Box
                          sx={{
                            overflow: "auto",
                            maxHeight: "60vh",
                            background: "#fff",
                            position: "relative",
                            borderRadius: 1,
                            mt: 1,
                            zIndex: 1,
                          }}
                        >
                          {item.comments.length > 0 ? (
                            item.comments.map((item, index) => (
                              <Box
                                key={item.commentid}
                                sx={{
                                  zIndex: 2,
                                  my: 0.75,
                                  borderRadius: 1,
                                }}
                                className={classes.customScrollbar}
                              >
                                <Box sx={{ px: 2, py: 0.5 }}>
                                  <Stack
                                    direction="row"
                                    spacing={2}
                                    alignItems="center"
                                  >
                                    <Avatar
                                      src={`${item.commentedUserlogo}`}
                                      alt=" "
                                      style={{
                                        maxWidth: 25,
                                        maxHeight: 25,
                                      }}
                                    />

                                    <Box
                                      sx={{
                                        background: "#F3F2F2",
                                        borderRadius: 1,
                                        p: 1,
                                        minWidth: "50%",
                                      }}
                                    >
                                      <Typography variant="subtitle2">
                                        {item.commentedUsername}
                                      </Typography>
                                      <Typography variant="body2">
                                        {item.comment}
                                      </Typography>
                                      {item.commentPath?.endsWith(
                                        "/"
                                      ) ? null : (
                                        <img
                                          width={`300px`}
                                          src={item.commentPath}
                                        />
                                      )}
                                    </Box>
                                  </Stack>

                                  <Stack
                                    direction="row"
                                    spacing={2}
                                    alignItems="center"
                                    sx={{ ml: 5 }}
                                  >
                                    <Typography variant="caption">
                                      {item.commentCreated}
                                    </Typography>

                                    <Stack
                                      sx={{ cursor: "pointer" }}
                                      direction="row"
                                      spacing={0.5}
                                      alignItems="center"
                                      onClick={() => {
                                        if (!replyBoxStatus[item.commentid]) {
                                          openReplyBox(item.commentid);
                                        } else {
                                          closeReplyBox(item.commentid);
                                        }
                                      }}
                                    >
                                      <ReplyIcon />
                                      {item.replies.length > 0 ? (
                                        <Typography variant="caption">
                                          {item.replies.length}
                                        </Typography>
                                      ) : null}
                                      <Typography variant="caption">
                                        {Labels?.reply}
                                      </Typography>
                                    </Stack>
                                    {employerData?.userid === item.fromUser ? (
                                      <Stack
                                        sx={{ cursor: "pointer" }}
                                        direction="row"
                                        spacing={0.5}
                                        alignItems="center"
                                        onClick={(event) =>
                                          deleteComment(event, item.commentid)
                                        }
                                      >
                                        <DeleteIcon />
                                        <Typography variant="caption">
                                          {Labels?.delete}
                                        </Typography>
                                      </Stack>
                                    ) : null}
                                  </Stack>
                                </Box>
                                {/* -------------------- Reply -------------------- */}
                                {replyBoxStatus[item.commentid] ? (
                                  <Box
                                    sx={{
                                      overflow: "auto",
                                      maxHeight: "50vh",
                                      background: "#fff",
                                      // border: "1px solid #0000001A",
                                      position: "relative",
                                      borderRadius: 1,
                                      px: 4,
                                      my: 2,
                                      zIndex: 3,
                                    }}
                                  >
                                    {item.replies.length > 0 ? (
                                      item.replies.map((item, index) => (
                                        <Box
                                          key={item.replyid}
                                          sx={{
                                            zIndex: 2,
                                            px: 2,
                                            py: 0.5,
                                            my: 0.75,
                                            borderRadius: 1,
                                          }}
                                          className={classes.customScrollbar}
                                        >
                                          <Stack
                                            direction="row"
                                            spacing={2}
                                            alignItems="center"
                                            sx={{ ml: 5 }}
                                          >
                                            <Avatar
                                              src={`${item.repliedUserlogo}`}
                                              alt=" "
                                              style={{
                                                maxWidth: 25,
                                                maxHeight: 25,
                                              }}
                                            />
                                            <Box
                                              sx={{
                                                background: "#F1F6F6",
                                                borderRadius: 1,
                                                p: 1,
                                                minWidth: "50%",
                                              }}
                                            >
                                              <Typography variant="subtitle2">
                                                {item.repliedUsername}
                                              </Typography>
                                              <Typography variant="body2">
                                                {item.reply}
                                              </Typography>
                                            </Box>
                                          </Stack>
                                          <Stack
                                            direction="row"
                                            spacing={2}
                                            alignItems="center"
                                            sx={{ ml: 10 }}
                                          >
                                            <Typography variant="caption">
                                              {item.replyCreated}
                                            </Typography>
                                            {employerData?.userid ===
                                            item.fromUser ? (
                                              <Stack
                                                sx={{ cursor: "pointer" }}
                                                direction="row"
                                                spacing={0.5}
                                                alignItems="center"
                                                onClick={(event) =>
                                                  deleteReply(
                                                    event,
                                                    item.replyid
                                                  )
                                                }
                                              >
                                                <DeleteIcon />
                                                <Typography variant="caption">
                                                  {Labels?.delete}
                                                </Typography>
                                              </Stack>
                                            ) : null}
                                          </Stack>
                                        </Box>
                                      ))
                                    ) : (
                                      <Typography variant="body1" sx={{ p: 1 }}>
                                        {Labels?.noReplyAdded}
                                      </Typography>
                                    )}

                                    {/* -------------------- Reply Input -------------------- */}
                                    {myforumInfo?.statusKey === "Active" ? (
                                      <ReplyInput
                                        Labels={Labels}
                                        value={replyBoxStatus[item.commentid]}
                                        employerData={employerData}
                                        onChangeReply={onChangeReply}
                                        addCommentReply={profanityCheckerAddComentReply}
                                        parentID={item.commentid}
                                        companyProfilee={companyProfilee}
                                        btndisable={btndisable}
                                      />
                                    ) : null}
                                  </Box>
                                ) : null}
                              </Box>
                            ))
                          ) : (
                            <Typography variant="body1" sx={{ p: 1 }}>
                              {Labels?.noCommentAdded}
                            </Typography>
                          )}
                        </Box>
                      </Box>

                      {myforumInfo?.statusKey === "Active" ? (
                        <>
                          <Divider />
                          <Box
                            sx={{
                              background: "#fff",
                              position: "sticky",
                              bottom: 0,
                              px: 2,
                            }}
                          >
                            {attachmentPreview ? (
                              <>
                                <img
                                  src={attachmentPreview}
                                  alt=""
                                  style={{
                                    maxWidth: "100px",
                                    maxHeight: "100px",
                                  }}
                                />
                                <CloseOutlinedIcon onClick={removeAttachment} />
                              </>
                            ) : null}
                            <Stack
                              direction="row"
                              alignItems="center"
                              sx={{ width: "100%" }}
                            >
                              <Avatar
                                src={`${companyProfilee?.companyLogo}`}
                                sx={{
                                  maxWidth: 25,
                                  maxHeight: 25,
                                }}
                              />
                              <FormControl
                                sx={{ m: 1, width: "100%" }}
                                variant="outlined"
                              >
                                <OutlinedInput
                             
                                  placeholder={Labels?.Typehere}
                                  onChange={(e) =>
                                    setCommentContent(profanityfn(ProfanityList,e.target.value),item.id)
                                  }
                                  value={commentContent}
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      e.preventDefault();
                                      profanityCheckerAddComent(e, item.id);
                                    }
                                  }}
                                  endAdornment={
                                    <InputAdornment position="start">
                                      {/* Use label to trigger the file input */}
                                      <label htmlFor="attachment-input">
                                        <IconButton component="span">
                                          <CameraAltIcon />
                                        </IconButton>
                                      </label>
                                      <input
                                        type="file"
                                        id="attachment-input"
                                        hidden
                                        onChange={handleFileChange}
                                      />
                                    </InputAdornment>
                                  }
                                />
                              </FormControl>
                              <Button
                                sx={{
                                  background: "#005F8E",
                                  "&:hover": {
                                    background: "#005F8E",
                                  },
                                  "&:active": {
                                    backgroundColor: "#005F8E",
                                  },
                                  "&:disabled": { color:"white",
                                    backgroundColor: "#005F8E",
                                  },
                                }}
                                variant="contained"
                                endIcon={<SendIcon />}
                                disabled={btndisable}
                                onClick={(e) => profanityCheckerAddComent(e, item.id)}
                              >
                                {Labels?.Send}
                              </Button>
                            </Stack>
                          </Box>
                        </>
                      ) : (
                        <Box
                          sx={{
                            // width: "100%",
                            backgroundColor: "#ff6161",
                            color: "#fff",
                            textAlign: "center",
                            padding: 1,

                            borderRadius: "5px",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{ fontWeight: 600 }}
                          >
                            {Labels?.forumStatus} {myforumInfo?.status}
                          </Typography>
                        </Box>
                      )}
                    </div>
                  ))
                ) : (
                  <Box sx={{ overflow: "auto", height: "75vh" }}>
                    <Typography variant="body1" sx={{ textAlign: "center" }}>
                      {Labels?.noPostAdded}
                    </Typography>
                  </Box>
                )
              ) : (
                <>
                  <Skeleton variant="rectangular" width={`100%`} height={60} />
                  <br />
                  <Skeleton variant="rectangular" width={`100%`} height={200} />
                  <br />
                  <Skeleton variant="rectangular" width={`100%`} height={200} />
                  <br />
                  <Skeleton variant="rectangular" width={`100%`} height={200} />
                </>
              )}
            </Box>
          </Paper>
        </Grid>
        {/* ---------------------------- forum logo and other forum---------------------------- */}
        <Grid item xs={12} md={6} lg={4}>
          <Paper
            elevation={0}
            sx={{ p: 2, my: 1, overflow: "auto" }}
            className={classes.customScrollbar}
          >
            <Grid container spacing={2}>
              <Grid item xs={11}>
                <Stack direction="row" justifyContent="center">
                  <Avatar
                    src={
                      myforumInfo?.grpLogoPath === null || "" ? (
                        <Box
                          sx={{
                            width: 40,
                            height: 40,
                            borderRadius: "100%",
                            background: "#65AC98",
                            mx: "auto",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            p: 3,
                          }}
                        >
                          {myforumInfo?.forumName ? (
                            <Typography variant="h3" sx={{ color: "#fff" }}>
                              {myforumInfo?.forumName.slice(0, 1)}
                            </Typography>
                          ) : (
                            ""
                          )}
                        </Box>
                      ) : (
                        `${myforumInfo?.frmLogoPath}`
                      )
                    }
                    alt={myforumInfo?.forumName}
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "100%",
                    }}
                  />
                </Stack>
                <Box
                  sx={{
                    margin: "10px 0",
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ color: "#005F8E", fontWeight: 600 }}
                  >
                    {myforumInfo?.forumName}
                  </Typography>

                  <Typography variant="caption" sx={{ color: "#005F8E" }}>
                    {myforumInfo?.categoryName}
                  </Typography>
                  <Typography variant="body2">
                    {myforumInfo?.description}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={1}>
                {employerData.userid === myforumInfo?.ownerid ? (
                  <Stack
                    direction="column"
                    alignItems="end"
                    spacing={1}
                    sx={{ float: "right", width: 50 }}
                  >
                    <Box
                      sx={{
                        border: editHover
                          ? "1px solid #2e7d32"
                          : "1px solid #00000040",
                        borderRadius: editHover ? 10 : "100%",
                        display: "flex",
                        p: 0.5,
                        justifyContent: "end",
                        alignItems: "center",
                        cursor: "pointer",
                        background: editHover ? "#2e7d32" : "transparent",
                        color: editHover ? "#fff" : "#000",
                      }}
                      onMouseEnter={handleEditMouseEnter}
                      onMouseLeave={handleEditMouseLeave}
                    >
                      {editHover ? (
                        <Typography variant="body2" sx={{ p: "0 5px" }}>
                          {Labels?.edit}
                        </Typography>
                      ) : null}
                      <EditIcon
                        onClick={() => redirect(`/edit-forum/${id}`)}
                        sx={{
                          width: 25,
                          height: 25,
                          color: editHover ? "#fff" : "#2e7d32",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        border: deleteHover
                          ? "1px solid #d32f2f"
                          : "1px solid #00000040",
                        borderRadius: deleteHover ? 10 : "100%",
                        display: "flex",
                        p: 0.5,
                        justifyContent: "end",
                        alignItems: "center",
                        cursor: "pointer",
                        background: deleteHover ? "#d32f2f" : "transparent",
                        color: deleteHover ? "#fff" : "#000",
                      }}
                      onMouseEnter={handleDeleteMouseEnter}
                      onMouseLeave={handleDeleteMouseLeave}
                    >
                      {deleteHover ? (
                        <Typography variant="body2" sx={{ p: "0 5px" }}>
                          {Labels?.delete}
                        </Typography>
                      ) : null}
                      <DeleteIcon
                        onClick={() => setDeleteModal(true)}
                        sx={{
                          width: 28,
                          height: 28,
                          color: deleteHover ? "#fff" : "#d32f2f",
                        }}
                      />
                    </Box>
                    {myforumInfo?.statusKey !== "Closed" ? (
                      <Box
                        sx={{
                          border: closeHover
                            ? "1px solid #d32f2f"
                            : "1px solid #00000040",
                          borderRadius: closeHover ? 10 : "100%",
                          display: "flex",
                          p: 0.75,
                          justifyContent: "end",
                          alignItems: "center",
                          cursor: "pointer",
                          background: closeHover ? "#d32f2f" : "transparent",
                          color: closeHover ? "#fff" : "#000",
                        }}
                        onMouseEnter={handleCloseMouseEnter}
                        onMouseLeave={handleCloseMouseLeave}
                      >
                        {closeHover ? (
                          <Typography variant="body2" sx={{ p: "0 5px" }}>
                            {Labels?.close}
                          </Typography>
                        ) : null}
                        <CommentsDisabledIcon
                          onClick={() => setCloseModal(true)}
                          sx={{
                            width: 25,
                            height: 25,
                            color: closeHover ? "#fff" : "#d32f2f",
                          }}
                        />
                      </Box>
                    ) : null}
                  </Stack>
                ) : null}
              </Grid>
            </Grid>
          </Paper>
          {/* ---------------------------- Other forums ---------------------------- */}
          <Card
            sx={{
              borderRadius: 1,
              padding: 0,
              boxShadow: "none",
            }}
          >
            <Stack sx={{ p: 2 }}>
              <Typography
                variant="subtitle1"
                sx={{ color: "#005F8E", fontWeight: 500 }}
              >
                {Labels?.otherForum}
              </Typography>
            </Stack>
            <Divider />
            <Box
              sx={{ height: { xs: "100vh", md: "51vh" }, overflow: "auto" }}
              className={classes.customColoredScrollbar}
            >
              {loading ? (
                <>
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`100%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                </>
              ) : (
                allForum
                  .filter((item) => item.forumID !== selectedForumID)
                  .map((item, i) => (
                    <Card
                      sx={{
                        borderRadius: 0,
                        boxShadow: "none",
                        borderBottom: "1px solid #005F8E33",
                        cursor: "pointer",
                        p: 1,

                        "&:hover": {
                          backgroundColor: "#ffffffe5",
                        },
                       
                      }}
                      key={item.forumID}
                      onClick={() => viewDetailedForum(item.forumID)}
                    >
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                      >
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Avatar
                            src={`${item.logo}`}
                            alt=" "
                            style={{
                              maxWidth: 30,
                              maxHeight: 30,
                            }}
                          />
                          <Stack>
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={1}
                            >
                              <Typography
                                variant="subtitle1"
                                sx={{ color: "#000", fontWeight: 600 }}
                              >
                                {item.forumName}
                              </Typography>
                              {item.status === "Closed" ? (
                                <Typography
                                  variant="caption"
                                  sx={{
                                    color: "#ED4C5C",
                                    background: "rgb(255, 246, 247)",
                                    p: 0.25,
                                    borderRadius: 1,
                                  }}
                                >
                                  {item.status}
                                </Typography>
                              ) : null}
                            </Stack>
                            <Stack direction="row" alignItems="center">
                              <Typography
                                variant="body2"
                                sx={{ color: "#005F8E" }}
                              >
                                {item.forumTopic}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                        {/* -----------------removed date------------------------------------------------             */}
                        {/* <Stack>
                        <Typography variant="caption">
                          {getTimeDifference(item.createdOn)}
                        </Typography>
                      </Stack> */}
                      </Stack>
                    </Card>
                  ))
              )}
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default ForumDetails;
const ReplyInput = ({
  Labels,
  value,
  employerData,
  onChangeReply,
  addCommentReply,
  parentID,
  companyProfilee,
  btndisable,
}) => {
  return (
    <Box
      sx={{
        mt: 2,
        mb: 0,
        position: "sticky",
        bottom: 0,
        background: "#fff",
        borderTop: "1px solid #0000001A",
      }}
    >
      <Stack direction="row" alignItems="center" sx={{ width: "100%" }}>
        <Avatar
          src={`${companyProfilee?.companyLogo}`}
          sx={{ width: 25, height: 25 }}
        />
        <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
          <OutlinedInput
            placeholder={Labels?.Typehere}
            autoComplete="off"
            value={value}
            onChange={(e) => onChangeReply(e, parentID)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                addCommentReply(e, parentID);
              }
            }}
          />
        </FormControl>
        <Button
          sx={{
            background: "#005F8E",
            "&:hover": {
              background: "#005F8E",
            },
            "&:active": {
              backgroundColor: "#005F8E",
            },
            "&:disabled": { color:"white",
              backgroundColor: "#005F8E",
            },
          }}
          variant="contained"
          endIcon={<SendIcon />}
          disabled={btndisable}
          onClick={(e) => {
            addCommentReply(e, parentID);
          }}
        >
          {Labels?.Send}
        </Button>
      </Stack>
    </Box>
  );
};
