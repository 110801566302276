import {
  Box,
  Chip,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useRef, useState ,useEffect} from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Button, Textarea } from "@mui/joy";
import { errorNotify, succesNotify } from "../../CommonCode/Commonfunc";
import { axiosAPI, axiosupload } from "../../Axios/Axios";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import TextfieldCustom from "../../Components/TextfieldCustom";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

export default function MessageToJobSeekerModal({
  open,
  handleClose,
  jobSeekerId,
  jobSeekerName,
}) {
  const [message, setMessage] = useState("");
  const [isButtonDisable, setButtonDisable] = useState(false);
  const sendMessage = async () => {
    setButtonDisable(true);
    if (!message) {
      setButtonDisable(false);
      errorNotify(Labels.writeMessage);
      return;
    } else if (message.length > 1000) {
      setButtonDisable(false);
      errorNotify(Labels.messageValidation);
      return;
    } else {
      const result = await axiosAPI.post("/message", {
        toUser: jobSeekerId,
        message: message,
      });
      const { success, message: responseMessage } = result.data;
      if (success) {
        setButtonDisable(false);
        succesNotify(responseMessage);
        handleClose(false);
      } else {
        setButtonDisable(false);
        errorNotify(responseMessage);
      }
    }
  };
  useEffect(()=>{
    if(!open){
      setMessage('')
    }
  },[open])
  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <Fragment>
      <Modal
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              mb: 1,
            }}
          >
            <HighlightOffIcon
              sx={{
                color: "#005F8E",
                cursor: "pointer",
              }}
              onClick={() => handleClose(false)}
            />
          </Box>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Typography
              sx={{
                mr: 1,
                fontFamily: "Poppins",
                color: "#005F8E",
                fontWeight: 500,
                fontSize: "14px",
              }}
            >
              {Labels?.To} :
            </Typography>
            <Chip label={jobSeekerName} />
          </div>

          <Box sx={{ mb: 1 }}>
            <Textarea
              minRows={5}
              placeholder={Labels.Typehere}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              pt: 1,
            }}
          >
            {/* <div>
              <IconButton onClick={handleAttachFile}>
                <AttachFileIcon />
              </IconButton>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileInputChange}
              />
              {selectedFile ? (
                <span style={{ marginLeft: "8px" }}>{selectedFile}</span>
              ) : (
                <span style={{ marginLeft: "8px" }}>Attach a file</span>
              )}
            </div> */}
            <Button
              variant="solid"
              sx={{
                background: "#005F8E",
                ml: 0.5,
                width: 90,
                borderRadius: "5px",
                fontFamily: "Poppins",
                fontWeight: 400,
                fontStyle: "normal",
                fontSize: "15px",
                "&:hover": {
                  backgroundColor: "#005F8E",
                },
                "&:active": {
                  backgroundColor: "#005F8E",
                },
                "&:disabled": { color:"white",
                  backgroundColor: "#005F8E",
                },
              }}
              onClick={sendMessage}
              disabled={isButtonDisable}
            >
              {Labels?.Send}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
}
