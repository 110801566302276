// import React, { Fragment, useEffect, useState } from "react";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import { axiosAPI } from "../Axios/Axios";
// import { InputLabel, TextField } from "@mui/material";
// import { useSelector } from "react-redux";

// const Country = ({ style, label, value, setValue, setStateId }) => {
//   const [Countries, setCountries] = useState([]);
//   useEffect(() => {
//     const getCountries = async () => {
//       const result = await axiosAPI.get("/getCountries");
//       const { success, data } = await result.data;
//       if (success === true) {
//         setCountries(data);
//       } else {
//         setCountries([]);
//       }
//     };
//     getCountries();
//   }, []);
//   const handleChange = (event) => {
//     setValue(event.target.value);
//     setStateId("");
//   };
//   const Labels = useSelector((state) => state.allLabels.labels);
//   return (
//     <FormControl sx={{ width: "100%" }}>
//       <Select
//         value={value}
//         onChange={handleChange}
//         placeholder={label}
//         displayEmpty
//         variant="outlined"
//         sx={{ padding: "10px 12px", fontFamily: "Poppins", ...style }}
//       >
//         <MenuItem value="" disabled>
//           <span style={{ color: "#bcbcbc" }}>{Labels?.SelectCountry}</span>
//         </MenuItem>
//         {Countries &&
//           Countries.map((val, index) => (
//             <MenuItem key={index} value={val.id}>
//               {val.name}
//             </MenuItem>
//           ))}
//       </Select>
//     </FormControl>
//   );
// };

// export default Country;

import React, { Fragment, useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import { axiosAPI } from "../Axios/Axios";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton, TextField } from "@mui/material";
import { updateLoadingState } from "../../redux/Loader";

const Country = ({ value, setValue, style, countryId,userchangedcountry,setuserchangedcountry  })=> {
  const dispatch = useDispatch();
  const [Countries, setCountries] = useState([]);
  const usertype = localStorage.getItem("userType");
  useEffect(() => {
    const getCountries = async () => {
      const result = await axiosAPI.get("/getCountries");
      const { success, data } = await result.data;
      if (success === true) {
        setCountries(data);
        if (countryId) {
          const selectedCountry = data.find((country) => country.id == countryId);
          if (selectedCountry) {
            setValue(selectedCountry);
          }
        }
      } else {
        setCountries([]);
      }
    };
    getCountries();
  }, [countryId]);
 
  const Labels = useSelector((state) => state.allLabels.labels);
 
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setuserchangedcountry(true)
  };
  return (
    <FormControl sx={{ width: "100%" }}>
      <Autocomplete
        value={value}
        onChange={handleChange}
        options={Countries}
        sx={{
          ...style,
          "& .MuiOutlinedInput-root": {
            padding: "0px",
            "& fieldset": {
              borderColor: "#106995 !important", // Change border color
            },
            "&:hover fieldset": {
              borderColor: "#000000", // Change border color on hover
            },
            "&.Mui-focused fieldset": {
              borderColor: "#106995 !important", // Change border color when focused
            },
          },
        }}
        getOptionLabel={(option) => option.name}
        readOnly={usertype == "Recruiter" ? true : false}
        renderInput={(params) => (
          <TextField {...params} placeholder={Labels?.SelectCountry} />
        )}
        noOptionsText={Labels?.notAvailable}
      />
    </FormControl>
  );
};

export default Country;
