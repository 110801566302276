import * as React from "react";
import Box from "@mui/material/Box";
import { Button, Dialog, Stack, TextField, Typography,Grid,Divider } from "@mui/material";
import { axiosAPI } from "../../Axios/Axios";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { errorNotify, succesNotify } from "../../CommonCode/Commonfunc";
import Country from "../../CommonCode/Country";
import EditBranchCountry from "../../CommonCode/BranchEditCountry";
import State from "../../CommonCode/State";
import EditBranchState from '../../CommonCode/BranchEditState'
import TextfieldCustom from "../../Components/TextfieldCustom";
import { useSelector } from "react-redux";
import { useState } from "react";
import TransgenderIcon from "@mui/icons-material/Transgender";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import PublicIcon from "@mui/icons-material/Public";
import BungalowIcon from "@mui/icons-material/Bungalow";
import HomeIcon from "@mui/icons-material/Home";
import { Edit, PublicOutlined } from "@mui/icons-material";
import BranchState from "../../CommonCode/BranchEditState";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "60%",
    sm: "50%",
    md: "40%",
    lg: "30%",
  },
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

export default function EditBranches({ open, handleClose, data }) {


  const [isButtonDisable, setButtonDisable] = useState(false);
  const [place, setLocations] =  useState(data || []);
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(data.stateName);
  const [city, setCity] = useState(data.city);
  const[address,setaddress]=useState(data.address);
  const [countryId, setCountryId] = useState(null);
  const [stateId, setStateId] = useState(null);
  const submitLocations = async () => {
    
    

    if (!country || !state || !city) {
      return errorNotify(Labels.allFieldsMandatory);
    } else if (city.length > 200) {
      errorNotify(Labels.cityValidation);
    } else {
      setButtonDisable(true);
      // const newData = {
      //   country: country.id,
      //   state: state.id,
      //   city: city,
      //   address:address,
      // };
      // const newPlace = [...place, newData];

      const postData = {
        branchid:data.branchid,
        country: country.id,
        state: state.id,
        city: city,
        address:address,
        branchid: data.branchid,
        language:localStorage.getItem("language")
      };
      const result = await axiosAPI.post("/editCompanyBranch", postData);
      const { status, message } = result.data;
      if (status === true) {
        succesNotify(message);
        handleClose();
        setButtonDisable(false);
      } else {
        errorNotify(message);
        setButtonDisable(false);
      }
    }
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 1.5 }}
      >
        <Typography variant="h6" sx={{ color: "#136E9B" }}>
          {Labels.CompanyBranches}
        </Typography>
        <HighlightOffIcon
          sx={{
            color: "#136E9B",
            cursor: "pointer",
          }}
          onClick={handleClose}
        />
      </Stack>
      <Box sx={{ p: 1.5 }}>
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
         {Labels?.Country}
        </Typography>
        <EditBranchCountry
          value={data}
          setValue={setCountry}
          setStateId={setState}
          style={{
            width: "100%",
            fontSize: "14px",
            
            borderColor: "#106995",
            "& .MuiOutlinedInput-root": {
              padding: "0px",
             
              
              "& fieldset": {
                borderColor: "#106995", // Change border color
              },
              "&:hover fieldset": {
                borderColor: "#000000", // Change border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "#106995", // Change border color when focused
              },
            },
          }}
        />
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          {Labels?.State}
        </Typography>
        <EditBranchState
          value={data}
          setValue={setState}
          country={country}
          style={{
            width: "100%",
            fontSize: "14px",
            
            borderColor: "#106995",
            "& .MuiOutlinedInput-root": {
              padding: "0px",
             
              
              "& fieldset": {
                borderColor: "#106995", // Change border color
              },
              "&:hover fieldset": {
                borderColor: "#000000", // Change border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "#106995", // Change border color when focused
              },
            },
          }}
        />
        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
        {Labels?.City}
        </Typography>
        <TextfieldCustom
          style={{
            width: "100%",
          }}
          label={Labels?.City}
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />
         <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
        {Labels?.Address}
        </Typography>
        <TextfieldCustom
          style={{
            width: "100%",
          }}
          value={address}
          label={Labels?.Address}
          onChange={(e) => setaddress(e.target.value)}
        />
        <Stack direction="row" justifyContent="center" sx={{ p: 1.5 }}>
          <Button
            variant="solid"
            fullWidth
            sx={{
              background: "#136E9B",
              borderRadius: 3,
              color: "#fff",
              "&:hover": {
                backgroundColor: "#136E9B",
              },
              "&:active": {
                backgroundColor: "#136E9B",
              },
              "&:disabled": { color:"white",
                backgroundColor: "#136E9B",
              },
            }}
            // disabled={isButtonDisable}
            onClick={submitLocations}
          >
            {Labels?.Save}
          </Button>
        </Stack>
      </Box>
       
    </Dialog>
  );
}
