// import React, { Fragment, useEffect, useState } from "react";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
// import { axiosAPI } from "../Axios/Axios";
// export default function LanguageLevel({ value, setValue, label, style,level,setLevel, }) {
//   const [languageLevel, setLanguageLevel] = useState([]);
//   // const [level, setLevel] = useState('');
//   useEffect(() => {
//     const getLanguageLevel = async () => {
//       const result = await axiosAPI.get("/getLanguageLevel");
//       const { success, data } = result.data;
//       if (success === true) {
//         setLanguageLevel(data);
//       } else {
//         setLanguageLevel([]);
//       }
//     };
//     getLanguageLevel();
//   }, []);
//   const handleChange = (e) => {
//     const selectedValue = e.target.value;
  
//     // Assuming languageLevel is an array of objects
//     const selectedLanguage = languageLevel.find(
//       (language) => language.id === selectedValue.id
//     );
  
//     setValue(selectedValue);
  
//     if (selectedLanguage) {
//       // Set the 'name' property of the selected language in the 'setLevel' state
//       setLevel(selectedLanguage.name, "ppp");
//     } else {
//       // Handle the case when the selected language is not found
//       // setLevel("");
//     }
  
//     //// console.log(level, "ppo0");
//   };
    

//   return (
//     <FormControl sx={{ width: "100%" }}>
//       <Select
//         value={value}
//         onChange={handleChange}
//         placeholder={label}
//         displayEmpty
//         variant="outlined"
//         sx={{ padding: "10px 12px", fontFamily: "Poppins", ...style }}
//       >
//         <MenuItem value="" disabled>
//           <span style={{ color: "#bcbcbc" }}>{label}</span>
//         </MenuItem>
//         {languageLevel &&
//           languageLevel.map((val, index) => {
//             return (
//               <MenuItem key={index} value={val}>
//                 {val.name}
//               </MenuItem>
//             );
//           })}
//       </Select>
//     </FormControl>
//   );
// }
import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import {profanityfn}  from "../Profanity/Profanity";
import { useSelector } from "react-redux";



export default function LanguageLevel({ value, setValue, label, style,setLevel }) {
  const [languageLevel, setLanguageLevel] = useState([]);
  
  useEffect(() => {
    const getLanguageLevel = async () => {
      const result = await axiosAPI.get("/getLanguageLevel");
      const { success, data } = result.data;
      if (success === true) {
        setLanguageLevel(data);
   
      } else {
        setLanguageLevel([]);
         
      }
    };
    getLanguageLevel();
  }, []);
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)

  const handleChange = (event) => {
    setValue(profanityfn(ProfanityList,event.target.value));
    setLevel(profanityfn(ProfanityList,event.target.value.name))
  };
  return (
    <FormControl sx={{ width: "100%" }}>
      <Select
        value={value}
        onChange={handleChange}
        placeholder={label}
        displayEmpty
        variant="outlined"
        sx={{ padding: "10px 12px",fontSize:"14px",  fontFamily: "Poppins",borderColor: "#005F8E", // Change border color
        
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: '#005F8E',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#005F8E',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#000000',
          }, ...style }}
      >
        <MenuItem value="" disabled>
          <span style={{ color: "#bcbcbc" }}>{label}</span>
        </MenuItem>
        {languageLevel &&
          languageLevel.map((val, index) => {
            return (
              <MenuItem key={index} value={val}>
                {val.name}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}
