import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { FormControl } from "@mui/material";
import { useSelector } from "react-redux";
import {profanityfn}  from "../Profanity/Profanity";

const Designation = ({ value, setValue, style }) => {
  console.log(value)
  const [Designation, setDesignation] = useState([]);
  useEffect(() => {
    const getlanguage = async () => {
      const result = await axiosAPI.get("/getDesignations");
      const { success, data } = await result.data;
      if (success === true) {
        // // console.log(data);
        setDesignation(data);
      } else {
        setDesignation([]);
      }
    };
    getlanguage();
  }, []);
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)

  const handleChange = (event) => {
    console.log(event)
    setValue(profanityfn(ProfanityList,event.target.value));
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  console.log(typeof value)
  console.log(Designation)
  return (
    <FormControl sx={{ width: "100%" }}>
      <Select
        value={value}
        onChange={(event) => handleChange(event)}
        variant="outlined"
        sx={{ padding: "10px 12px", fontFamily: "Poppins", ...style, borderColor: "#005F8E", // Change border color
        
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: '#005F8E',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#005F8E',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#000000',
          }, }}
        displayEmpty
      >
        <MenuItem value="" disabled>
          <span style={{ color: "#bcbcbc" }}>{Labels?.designation}</span>
        </MenuItem>
        {Designation &&
          Designation.map((val, index) => (
            <MenuItem key={index} name={val.name} value={val.id}>
              {val.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default Designation;
