import { Box, Dialog, Stack, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Button, Textarea } from "@mui/joy";
import { errorNotify, succesNotify } from "../../CommonCode/Commonfunc";
import { axiosAPI } from "../../Axios/Axios";
import { useSelector } from "react-redux";
import {profanityfn}  from "../../Profanity/Profanity";

const CoverLetterModal = ({ open, handleClose, data }) => {
  const [coverLetter, updateCoverLetter] = useState(data);

  const postdata = {
    coverLetter: coverLetter,
    language:localStorage.getItem("language")
  };
  const submitCoverletter = async (e) => {
    e.preventDefault();
    if (!postdata.coverLetter) {
      errorNotify(Labels.writeCoverLetter);
    } else if (postdata.coverLetter.length > 1000) {
      errorNotify(Labels.coverLetterValidation);
    } else {
      const result = await axiosAPI.post("/updateUserProfile", postdata);
      const { success, message } = result.data;
      if (success === true) {
        succesNotify(message);
        handleClose();
      } else {
        errorNotify(message);
        handleClose();
      }
    }
  };
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)

  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 1.5 }}
      >
        <Typography variant="h6" sx={{ color: "#106995" }}>
         {Labels?.CoverLetter}
        </Typography>
        <HighlightOffIcon
          sx={{
            color: "#106995",
          }}
          onClick={handleClose}
        />
      </Stack>
      <Box sx={{ p: 1.5, width: 400 }}>
        <Textarea
          minRows={7}
          maxRows={10}
          placeholder={Labels?.Typehere}
          name="coverletter"
          value={coverLetter}
          sx={{
            padding: "10px",
            border: "0.5px solid #106995",
            borderRadius: 2,
            "& .MuiInputBase-input": {
              padding: "10px",
              border: "none", // Remove the default border
            },
              
              }}
          onChange={(e) => updateCoverLetter(profanityfn(ProfanityList,e.target.value))}
        />
      </Box>
      <Stack
        direction="row"
        justifyContent="center"
        spacing={2}
        sx={{ p: 1.5 }}
      >
        <Button
          variant="solid"
          fullWidth
          sx={{
            background: "#106995",
            borderRadius: 3,
            "&:hover": {
              backgroundColor: "#106995",
            },
            "&:active": {
              backgroundColor: "#106995",
            },
            "&:disabled": { color:"white",
              backgroundColor: "#106995",
            },
          }}
          onClick={submitCoverletter}
          // disabled={isButtonDisable}
        >
          {Labels?.Save}
        </Button>
      </Stack>
    </Dialog>
  );
};

export default CoverLetterModal;
