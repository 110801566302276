import React from "react";
import { Link } from "react-router-dom";
import logo from "../Images/fifyPluslogo.png";
import NaranjoLogo from "../Images/logo1 (1).png"
import { useSelector } from "react-redux";
function ErrorPage() {
  const Labels = useSelector((state) => state.allLabels.labels);

  return (
    <div className="ErrorPage">
      <img src={NaranjoLogo} />
      <h1> {Labels?.Pagenotfound}</h1>
      <Link to="/"> {Labels?.Gohome}</Link>
    </div>
  );
}

export default ErrorPage;
