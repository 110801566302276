import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { useSelector } from "react-redux";
import {profanityfn}  from "../Profanity/Profanity";

const Topics = ({ value, setValue, style, label }) => {
  const [topic, setTopic] = useState([]);
  const Labels = useSelector((state) => state.allLabels.labels);

  useEffect(() => {
    const getTopic = async () => {
      const result = await axiosAPI.get("/getTopicsList");
      const { status, data } = result.data;
      if (status) {
        setTopic(data);
      } else {
        setTopic([]);
      }
    };
    getTopic();
  }, []);
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)

  const handleChange = (event) => {
    setValue(profanityfn(ProfanityList,event.target.value));
  };
  return (
    <Select
      value={value}
      onChange={handleChange}
      placeholder={label}
      displayEmpty
      variant="outlined"
      sx={{ padding: "10px 12px", fontFamily: "Poppins", borderColor: "#005F8E", // Change border color
        
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: '#005F8E',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#005F8E',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#000000',
        }, ...style }}
    >
      <MenuItem value="" disabled>
        {Labels?.Topics}
      </MenuItem>
      {topic &&
        topic.map((val, index) => {
          return (
            <MenuItem key={index} value={val.id}>
              {val.name}
            </MenuItem>
          );
        })}
    </Select>
  );
};

export default Topics;
