import React, { useEffect, useState } from "react";
import { InputLabel } from "@mui/material";
import EmployerNavbar from "../EmployerComponents/EmployerNavbar";
import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Paper,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
  Skeleton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EmployerSideMenu from "../EmployerComponents/EmployerSideMenu";
import TextfieldCustom from "../../Components/TextfieldCustom";
import CustomButton from "../../Components/CustomButton";
import { axiosAPI } from "../../Axios/Axios";
import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import { useNavigate } from "react-router-dom";
import Skills from "../../CommonCode/Skills";
import ExperienceLevel from "../../CommonCode/ExperienceLevel";
import { useSelector } from "react-redux";
import SkillsNew from "../../CommonCode/SkillsNew";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 300, // Adjust the maximum height as needed
      width: 350,
      overflowX:"auto",
        margin:"5px"
    },
  },
};


export default function SearchJobSeekers() {
  const redirect = useNavigate();
  const [jobSeekers, setJobSeekers] = useState([]);
  const [postedJobList, setPostedJobList] = useState([]);
  const [jobId, setJobId] = useState("");
  const [keyword, setKeyWord] = useState("");
  const [skills, setSkills] = useState([]);
  const [userExperienceLevel, setUserExperienceLevel] = useState("");
  const [loading, setloading] = useState(true);

  const searchJobSeekers = async () => {
    setloading(true);
    const skill = JSON.stringify(skills);
    const postData = {
      jobid: jobId,
      experienceLevel: userExperienceLevel,
      skill: skill.slice(1, skill.length - 1),
      search: keyword,
    };
    const result = await axiosAPI.post("/search", postData);
    const { message, data, status } = result.data;
    if (status) {
      setJobSeekers(data);
      setloading(false);
    } else {
      setJobSeekers([]);
      setloading(false);
    }
  };

  const getPostedJobList = async () => {
    const result = await axiosAPI.post("/listjobs");
    const { success, data, message } = result.data;
    if (success) {
      setPostedJobList(data.reverse());
    }
  };
  useEffect(() => {
    getPostedJobList();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    searchJobSeekers();
  }, [jobId, skills, userExperienceLevel]);

  const handleClick = () => {
    setJobId("");
    setUserExperienceLevel("");
    setKeyWord("");
    setSkills([]);
    setJobSeekers({});
  };

  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <>
      <Typography variant="h6" sx={{ color: "#005F8E", fontWeight: 600 }}>
        {Labels?.SearchCandidates}
      </Typography>
      <Stack
        direction={{ xs: "column", md: "row" }}
        alignItems="center"
        spacing={2}
        mb={1}
        width={{ xs: `100%`, md: "100%", lg: `70%`, xl: `80%` }}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          width={{ xs: `100%`, md: "20%" }}
        >
          <FormControl sx={{ width: "100%" }}>
            <Select
              MenuProps={MenuProps}
              value={jobId}
              onChange={(e) => setJobId(e.target.value)}
              variant="outlined"
              displayEmpty
              sx={{
                background: "white",
                borderColor: "#005F8E", // Change border color
        
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: '#005F8E',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#005F8E',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#000000',
          }, 
              }}
              inputProps={{ poppins: "Select a job" }}
            >
              <MenuItem value="" disabled>
                <span style={{ color: "#808080" }}> {Labels?.SelectaJob}</span>{" "}
                {/* Placeholder */}
              </MenuItem>
              {postedJobList &&
                postedJobList.map((val, index) => {
                  return (
                    <MenuItem key={index} value={val.eja_id}>
                      {val.jobName}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Stack>
        <Stack width={{ xs: `100%`, md: "20%" }} >
          <SkillsNew
            style={{ background: "white" }}
            value={skills}
            setValue={setSkills}
          />
        </Stack>
        <Stack width={{ xs: `100%`, md: "20%" }}>
          <ExperienceLevel
            value={userExperienceLevel}
            setValue={setUserExperienceLevel}
            style={{
              background: "white",
            }}
          />
        </Stack>
        <Stack width={{ xs: `100%`, md: "40%" }}>
          <TextfieldCustom
            value={keyword}
            label={Labels?.searchJobSeeker}
            style={{
              borderRadius: "10px",
            }}
            onChange={(e) => setKeyWord(e.target.value)}
          />
        </Stack>

        {/* <Stack direction="row" alignItems="center" spacing={2} sx={{border:"2px solid red"}}>
         
        </Stack> */}

        <CustomButton
          label={Labels?.search}
          style={{ borderRadius: 1 }}
          onClick={searchJobSeekers}
        />
        <CustomButton
          label={Labels?.Reset}
          style={{ borderRadius: "10px", background: "white",
            color: "#005F8E",
            border: "2px solid #005F8E",
            "&:hover": {
              backgroundColor: "white",
              color: "#005F8E",
              border: "2px solid #005F8E",
            },
            "&:active": {
              backgroundColor: "white",
              color: "#005F8E",
              border: "2px solid #005F8E",
            },
            "&:disabled": { color: "white", backgroundColor: "#005F8E" } }}
          onClick={() => {
            handleClick();
          }}
        />
      </Stack>
      <Grid container spacing={2}>
        {loading ? (
          <>
            <Grid container spacing={2} sx={{ margin: "10px auto 0px" }}>
              <Grid item xs={12} sm={6} lg={4} xl={4}>
                <Item>
                  <Skeleton
                    variant="rounded"
                    width={`95%`}
                    height={70}
                    style={{ margin: "0px auto" }}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={4}>
                <Item>
                  <Skeleton
                    variant="rounded"
                    width={`95%`}
                    height={70}
                    style={{ margin: "0px auto" }}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={4}>
                <Item>
                  <Skeleton
                    variant="rounded"
                    width={`95%`}
                    height={70}
                    style={{ margin: "0px auto" }}
                  />
                </Item>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ margin: "10px auto 0px" }}>
              <Grid item xs={12} sm={6} lg={4} xl={4}>
                <Item>
                  <Skeleton
                    variant="rounded"
                    width={`95%`}
                    height={70}
                    style={{ margin: "0px auto" }}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={4}>
                <Item>
                  <Skeleton
                    variant="rounded"
                    width={`95%`}
                    height={70}
                    style={{ margin: "0px auto" }}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={6} lg={4} xl={4}>
                <Item>
                  <Skeleton
                    variant="rounded"
                    width={`95%`}
                    height={70}
                    style={{ margin: "0px auto" }}
                  />
                </Item>
              </Grid>
            </Grid>
          </>
        ) : jobSeekers.length === 0 ? (
          <Stack direction="row" justifyContent="center">
            <Typography
              sx={{ p: "15px", color: "#6a6a6a", mt: 1 }}
              variant="h6"
            >
              {Labels?.Jobseekersnotfound}
            </Typography>
          </Stack>
        ) : (
          Object.values(jobSeekers).map((jobseeker) => (
            <Grid item xs={12} sm={6} lg={4} xl={4}>
              <Card
                key={jobseeker.jsp_id}
                sx={{
                  p: 1,
                  background: "#FFFFFF",
                  border: "1px solid rgba(0, 0, 0, 0.1)",
                  borderRadius: 2,
                  cursor: "pointer",
                  boxShadow: "none",
                }}
                onClick={() =>
                  redirect(`/jobSeekerProfileView/${jobseeker.userid}`)
                }
              >
                <Stack direction="row" spacing={1}>
                  {jobseeker?.profilePicPath?.endsWith("/") ? null : (
                    <Avatar
                      src={`${jobseeker?.profilePicPath}`}
                      sx={{
                        width: 50,
                        height: 50,
                        borderRadius: "100%",
                      }}
                    />
                  )}

                  <Box>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      {jobseeker?.displayName}
                    </Typography>
                    <Typography variant="body2">{jobseeker?.city}</Typography>
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{ my: 1 }}
                >
                  <Typography variant="body2">
                    {jobseeker.experience ? "Seniority Level" : null} 
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#686868cc" }}>
                    : 
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#686868cc" }}>
                    {jobseeker.experience ? jobseeker.experience : null}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="flex-end">
                  <Button
                    variant="outlined"
                    sx={{
                      textTransform: "capitalize",
                      color: "#005F8E",
                      border: "1px solid #005F8E",
                    }}
                    // onClick={() => getJobSeekerData(jobseeker.jobseekerid)}
                  >
                    {Labels?.View}
                  </Button>
                </Stack>
              </Card>
            </Grid>
          ))
        )}
      </Grid>
    </>
  );
}
