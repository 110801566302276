import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { useSelector } from "react-redux";
import { InputLabel } from "@mui/material";
import {profanityfn}  from "../Profanity/Profanity";

const SelectJobType = ({ value, setValue, style, placeholder   }) => {
  const [Types, setTypes] = useState([]);
  useEffect(() => {
    const getJobType = async () => {
      const result = await axiosAPI.get("/getTypes");
      const { success, data } = await result.data;
      if (success === true) {
        setTypes(data);
        //// console.log(data);
      } else {
        setTypes([]);
      }
    };
    getJobType();
  }, []);
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)
  const handleChange = (event) => {
    //// console.log(event.target.value);
    setValue(profanityfn(ProfanityList,event.target.value));
    
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <FormControl sx={{ width: "100%" }}>
      
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={value}
      onChange={handleChange}
      // placeholder={label}
      fullWidth
      variant="outlined"
      sx={{
        padding: "10px 12px",
        fontSize: "14px", // Apply fontSize style here
        fontFamily: "Poppins",
        borderColor: "#005F8E", // Change border color
        
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: '#005F8E',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#005F8E',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#000000',
        },
        ...style
      }}
      // renderValue={(selected) => {
      //   const selectedType = Types.find((item) => item.id === selected);
      //   return selectedType ? selectedType.name : "contract";
      // }}
      displayEmpty
    > <MenuItem value="" disabled>
           <span style={{ color: "#bcbcbc" }}> {placeholder || Labels.ContractType}</span>
        </MenuItem>
  
      {Types &&
        Types.map((val, index) => (
          <MenuItem key={index} value={val.id}>
            {val.name}
          </MenuItem>
        ))}
    </Select>
  </FormControl>
  );
};

export default SelectJobType;
