import {
  Avatar,
  Button,
  Grid,
  Paper,
  Stack,
  Typography,
  Skeleton,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Components/CustomButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { axiosAPI } from "../../Axios/Axios";
import { differenceInDays, parseISO } from "date-fns";
import JobDeleteAlert from "../EmployerComponents/JobDeleteAlert";
import InfoIcon from "@mui/icons-material/Info";
import { useSelector } from "react-redux";
import {
  errorNotify,
  infoNotify,
  warningNotify,
} from "../../CommonCode/Commonfunc";

export default function EmployerCreatedJobList() {
  const redirect = useNavigate();
  const [isApiCall, setIsApiCall] = useState(0);
  const [postedJobList, setPostedJobList] = useState([]);
  const [jobId, setJobId] = useState("");
  const [openJobDeleteAlert, setJobDeleteAlert] = useState(false);
  const resettedByAdmin = localStorage.getItem("resettedByAdmin");

  const [loading, setloading] = useState(true);

  const getPostedJobList = async () => {
    const result = await axiosAPI.post("/listjobs");
    const { success, data, message } = result?.data;
    if (success) {
      setPostedJobList(data);
      setloading(false);
    } else {
      setloading(false);
    }
  };
  useEffect(() => {
    getPostedJobList();
    window.scrollTo(0, 0);
  }, [isApiCall]);

  const deleteJob = (e, jobId) => {
    e.stopPropagation();
    setJobId(jobId);
    setJobDeleteAlert(true);
  };

  const goToCreatePage = (event) => {
    event.stopPropagation();
    redirect("/Createjob");
  };
  const redirectToJobDetailPage = (event, jobId) => {
    event.stopPropagation();
    localStorage.setItem("jobId", jobId);
    redirect(`/jobInfo/${jobId}`);
  };
  const redirectToJobEditPage = (event, jobId) => {
    event.stopPropagation();
    localStorage.setItem("jobId", jobId);
    redirect(`/editJob/${jobId}`);
  };
  const redirectToMatchingJobSeekers = (event, jobId) => {
    event.stopPropagation();
    localStorage.setItem("jobId", jobId);
    redirect(`/matchingJobSeekers/${jobId}`);
  };
  const redirectToMarkedJobSeekers = (event, jobId) => {
    event.stopPropagation();
    localStorage.setItem("jobId", jobId);
    redirect(`/markedJobSeekers/${jobId}`);
  };
  const redirectToAppliedJobSeekers = (event, jobId) => {
    event.stopPropagation();
    localStorage.setItem("jobId", jobId);
    redirect(`/appliedJobSeekers/${jobId}`);
  };
  const redirectToShortListedJobSeekers = (event, jobId) => {
    event.stopPropagation();
    localStorage.setItem("jobId", jobId);
    redirect(`/shortListedJobSeekers/${jobId}`);
  };

  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <>
      {/* -------------------- Dialog -------------------- */}
      <JobDeleteAlert
        open={openJobDeleteAlert}
        handleClose={setJobDeleteAlert}
        jobId={jobId}
        setIsApiCall={setIsApiCall}
      />
      {/* -------------------- Create Job List -------------------- */}
      <Paper elevation={0} sx={{ my: 1 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 1.5 }}
        >
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 600, color: "#005F8E" }}
          >
            {Labels?.CreatedJobsList}&nbsp;({postedJobList.length})
          </Typography>
          <CustomButton label={Labels?.CreateJob} onClick={goToCreatePage} />
        </Stack>
      </Paper>
      <>
        {loading ? (
          <>
            <Skeleton
              variant="rectangular"
              width={`100%`}
              height={100}
              style={{ margin: "10px auto" }}
            />
            <Skeleton
              variant="rectangular"
              width={`100%`}
              height={100}
              style={{ margin: "10px auto" }}
            />
            <Skeleton
              variant="rectangular"
              width={`100%`}
              height={100}
              style={{ margin: "10px auto" }}
            />
            <Skeleton
              variant="rectangular"
              width={`100%`}
              height={100}
              style={{ margin: "10px auto" }}
            />
            <Skeleton
              variant="rectangular"
              width={`100%`}
              height={100}
              style={{ margin: "10px auto" }}
            />
            <Skeleton
              variant="rectangular"
              width={`100%`}
              height={100}
              style={{ margin: "10px auto" }}
            />
          </>
        ) : postedJobList.length > 0 ? (
          postedJobList.map((data) => (
            <Paper elevation={0} sx={{ my: 1, p: 1.5, cursor: "pointer" }}>
              <Grid
                container
                spacing={1}
                key={data.eja_id}
                sx={{ alignItems: "center" }}
              >
                <Grid
                  // style={{ border: "2px solid red" }}
                  item
                  xs={12}
                  md={6}
                  lg={3}
                  order={{ xs: 1, md: 1, lg: 1 }}
                  onClick={(event) =>
                    redirectToJobDetailPage(event, data?.eja_id)
                  }
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 600,
                      textAlign: { xs: "center", md: "left" },
                    }}
                  >
                    {data?.jobName}
                  </Typography>
                  {data?.companyName && (
                    <Typography
                      variant="subtitle1"
                      sx={{
                        fontWeight: 400,
                        textAlign: { xs: "center", md: "left" },
                        color:"#005F8E"
                      }}
                    >
                      {data?.companyName}
                    </Typography>
                  )}
                  <Typography
                    variant="body2"
                    sx={{
                      textAlign: { xs: "center", md: "left" },
                    }}
                  >
                    {data?.address}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontSize: 13,
                      lineHeight: 2,
                      textAlign: { xs: "center", md: "left" },
                    }}
                  >
                    {/* {`${Labels?.Created} ${
                        differenceInDays(
                          new Date(),
                          parseISO(data?.createdOn)
                        ) === 0
                          ? `${Labels?.Today}`
                          : `${differenceInDays(
                              new Date(),
                              parseISO(data?.createdOn)
                            )} ${Labels?.daysago}`
                      }`} */}
                    {data?.createdOn}
                  </Typography>
                </Grid>

                <Grid
                  // style={{ border: "2px solid red" }}
                  item
                  xs={12}
                  lg={8}
                  order={{ xs: 2, md: 3, lg: 2 }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={6} md={3}>
                      <Button
                        varient="text"
                        sx={{ textTransform: " capitalize", color: "#008080 " }}
                        onClick={(event) =>
                          redirectToAppliedJobSeekers(event, data?.eja_id)
                        }
                      >
                        {Labels?.Applied}({data?.applied})
                      </Button>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Button
                        varient="text"
                        sx={{ textTransform: " capitalize", color: "#FFA500 " }}
                        onClick={(event) =>
                          redirectToShortListedJobSeekers(event, data?.eja_id)
                        }
                      >
                        {Labels?.Shortlisted}({data?.shortlist})
                      </Button>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Button
                        varient="text"
                        sx={{ textTransform: " capitalize", color: "#FF6347 " }}
                        onClick={(event) =>
                          redirectToMarkedJobSeekers(event, data?.eja_id)
                        }
                      >
                        {Labels?.Marked}({data?.marked})
                      </Button>
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Button
                        varient="text"
                        sx={{ textTransform: " capitalize", color: "#32CD32 " }}
                        onClick={(event) =>
                          redirectToMatchingJobSeekers(event, data?.eja_id)
                        }
                      >
                        {Labels?.Recommended}({data?.matching})
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  // style={{ border: "2px solid red" }}
                  item
                  xs={12}
                  md={6}
                  lg={1}
                  order={{ xs: 3, md: 2, lg: 3 }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent={{ xs: "center", lg: "flex-end" }}
                    spacing={1}
                  >
                    <Box
                      sx={{
                        border: "1px solid #00000040",
                        borderRadius: "100%",
                        display: "flex",
                        p: 0.5,
                        justifyContent: "end",
                        alignItems: "center",
                        cursor: "pointer",
                        background: "transparent",
                      }}
                      onClick={(event) =>
                        redirectToJobDetailPage(event, data?.eja_id)
                      }
                    >
                      <InfoIcon
                        sx={{
                          width: 28,
                          height: 28,
                          color: "#0288d1",
                        }}
                      />
                    </Box>

                    <Box
                      sx={{
                        border: "1px solid #00000040",
                        borderRadius: "100%",
                        display: "flex",
                        p: 0.5,
                        justifyContent: "end",
                        alignItems: "center",
                        cursor: "pointer",
                        background: "transparent",
                      }}
                    >
                      <EditIcon
                        onClick={(event) =>
                          redirectToJobEditPage(event, data?.eja_id)
                        }
                        sx={{
                          width: 25,
                          height: 25,
                          color: "#2e7d32",
                        }}
                      />
                    </Box>

                    {data?.applied == 0 && data?.shortlist == 0 ? (
                      <Box
                        sx={{
                          border: "1px solid #00000040",
                          borderRadius: "100%",
                          display: "flex",
                          p: 0.5,
                          justifyContent: "end",
                          alignItems: "center",
                          cursor: "pointer",
                          background: "transparent",
                        }}
                      >
                        <DeleteIcon
                          onClick={(e) => deleteJob(e, data?.eja_id)}
                          sx={{
                            width: 25,
                            height: 25,
                            color: "#d32f2f",
                          }}
                        />
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          border: "1px solid #00000040",
                          borderRadius: "100%",
                          display: "flex",
                          p: 0.5,
                          justifyContent: "end",
                          alignItems: "center",
                          cursor: "pointer",
                          background: "transparent",
                        }}
                      >
                        <DeleteForeverIcon
                          onClick={(e) =>
                            warningNotify(Labels.Cannotdeletethisjob)
                          }
                          sx={{
                            width: 28,
                            height: 28,
                            color: "#d32f2f",
                          }}
                        />
                      </Box>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Paper>
          ))
        ) : (
          <Paper elevation={0} sx={{ my: 1, p: 1.5 }}>
            <Typography variant="body2">{Labels?.NoJobsPostedYet}</Typography>
          </Paper>
        )}
      </>
    </>
  );
}
