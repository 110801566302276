import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import JobSeekerRoutes from "./Routes/JobSeekerRoutes";
import EmployerRoutes from "./Routes/EmployerRoutes";
import CommonRoutes from "./Routes/CommonRoutes";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "./authContext/index";
import { useDispatch, useSelector } from "react-redux";
import { getProfanityWord } from "./redux/Profanity";
import { axiosAPI } from "./views/Axios/Axios";
import RouteValidator from "./RouteValidator";
import { getAllLabels } from "./redux/AllLabels";
function App() {
  const dispatch = useDispatch();
  const language = localStorage.getItem("language")!=null?localStorage.getItem("language"):'en';
  const getLabels = (language) => {
    dispatch(getAllLabels(language));
    dispatch(getProfanityWord())
  };
  useEffect(() => {
    getLabels(language);
  }, []);

  return (
    <Router>
      <RouteValidator />
      <AuthProvider>
        <CommonRoutes />
        <JobSeekerRoutes />
        <EmployerRoutes />
        <ToastContainer />
      </AuthProvider>
    </Router>
  );
}

export default App;
