import React from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import Home from "../views/Home";
import Landingpage from "../views/Landinpage1/Landingpage_1";
import JobSerachPreloginGrid from "../views/JobSearch/JobSerachPreloginGrid";
import JobDetailedview from "../views/JobSearch/JobDetailedview";
import AboutUs from "../views/Landinpage1/AboutUs";
import Contact from "../views/Landinpage1/Contact";
import AllCompanies from "../views/Landinpage1/AllCompanies";
import AllCategory from "../views/Landinpage1/AllCategory";
import Header from "../views/Landinpage1/Header";
import Login from "../views/Login/Login";
import Signup from "../views/Login/Signup";
import Company from "../views/Company/Company";
import EmployerPasswordChange from "../views/Login/EmployerPwdChange";
import ErrorPage from "./ErrorPage";

function ProtectedRoute() {
  const isJobseeker = localStorage.getItem("token");
  const isEmployer = localStorage.getItem("employerToken");
  const resettedByAdmin = localStorage.getItem("resettedByAdmin");
  if (isJobseeker) {
    return !isJobseeker ? (
      <>
        <Header /> <Outlet />
      </>
    ) : (
      <Navigate to="/login" />
    );
  } else if (isEmployer) {
    return !isEmployer ? (
      <>
        <Header /> <Outlet />
      </>
    ) : (
      <Navigate to="/login" />
    );
  } else {
    return (
      <>
        <Header /> <Outlet />
      </>
    );
  }
}

const IsLogin = ({ resettedByAdmin }) => {
  const jobSeekerToken = localStorage.getItem("token");
  const employerToken = localStorage.getItem("employerToken");

  if (jobSeekerToken) {
    if (resettedByAdmin === "1") {
      // Return the Login component if resettedByAdmin is "1"
      return <Login />;
    } else {
      // Navigate to employer dashboard
      return <Navigate to="/jobseekerDashboard" replace />;
    }
  } else if (employerToken) {
    if (resettedByAdmin === "1") {
      // Return the Login component if resettedByAdmin is "1"
      return <Login />;
    } else {
      // Navigate to employer dashboard
      return <Navigate to="/employerDashboard" replace />;
    }
  } else {
    return <Login />;
  }
};

export default function CommonRoutes() {
  const resettedByAdmin = localStorage.getItem("resettedByAdmin");
  return (
    <Routes>
    <Route path="/login" element={<IsLogin resettedByAdmin={resettedByAdmin} />}/>
    <Route path="/emppwdchange" element={<EmployerPasswordChange />} />
    <Route path="/signup" element={<Signup />} />
    <Route path="/ErrorPage" element={<ErrorPage/>} />
    <Route element={<ProtectedRoute />}>
      <Route exact path="/" element={<Landingpage />} />
      <Route path="/landingpage" element={<Landingpage />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/jobSearchPrelogin" element={<JobSerachPreloginGrid />}/>
      <Route path="/jobSearchPrelogin/:id" element={<JobSerachPreloginGrid />}/>

      <Route path="/contact" element={<Contact />} />
      {/* <Route path="/jobSearchPrelogin/:id" element={<JobDetailedview />} /> */}
      <Route path="/allCompanies" element={<AllCompanies />} />
      <Route path="/allCategories" element={<AllCategory />} />
      <Route path="/company/:id" element={<Company />} /> 
    </Route>

  </Routes>
  );
}
