import {
  Box,
  Dialog,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { Fragment, useState } from "react";
import CustomButton from "../../Components/CustomButton";
import TextfieldCustom from "../../Components/TextfieldCustom";
import { axiosAPI } from "../../Axios/Axios";
import { errorNotify, succesNotify } from "../../CommonCode/Commonfunc";
import { useDispatch, useSelector } from "react-redux";
import { updateLoginDetails } from "../../../redux/CreateLoginDetailSlice";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Textarea } from "@mui/joy";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

export default function UserActivatonModal({ open, handleClose }) {
  const dispatch = useDispatch();
  const Labels = useSelector((state) => state.allLabels.labels);
  const [activationCode, setActivationCode] = useState("");
  const [resendButton, setResendButton] = useState(false);
  const activateAccount = async (e) => {
    e.preventDefault();
    const result = await axiosAPI.post("/activateUser", {
      code: activationCode,
      language:localStorage.getItem("language")
    });
    const { data, message, success } = result.data;
    if (success) {
      succesNotify(message);
      dispatch(updateLoginDetails(data));
      handleClose(false);
    } else {
      setResendButton(true);
      errorNotify(data.error);
    }
  };

  const resendActivationCode = async (e) => {
    e.preventDefault();
    const result = await axiosAPI.post("/resendCode");
    const { data, message, status } = result.data;
    if (status) {
      succesNotify(Labels.activationMail);
      setResendButton(false);
    } else {
      errorNotify(message);
    }
  };
  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
        <Typography variant="h6" sx={{ color: "#005F8E" }}>
          {Labels.PleaseActivateYourAccount}
        </Typography>
        <HighlightOffIcon
          sx={{
            cursor: "pointer",
            color: "#005F8E",
          }}
          onClick={() => handleClose(false)}
        />
      </Stack>
      <Box sx={{ p: 1.5 }}>
        <TextField
          onChange={(e) => setActivationCode(e.target.value)}
          variant="outlined"
          fullWidth
          placeholder="Enter Activation Code"
        />
      </Box>
      <Stack
        direction="row"
        justifyContent="center"
        spacing={1}
        sx={{ p: 1.5 }}
      >
        {resendButton ? (
          <Button
            variant="contained"
            onClick={resendActivationCode}
            sx={{
              border: "1px solid #005F8E",
              backgroundColor: "#005F8E",
              textTransform: "capitalize",
              fontWeight: "400 !important",

              "&:hover": {
                backgroundColor: "#005F8E",
              },
              "&:active": {
                backgroundColor: "#005F8E",
              },
              "&:disabled": { color:"white",
                backgroundColor: "#005F8E",
              },
            }}
          >
           {Labels.ResendCode}
          </Button>
        ) : null}
        <Button
          variant="contained"
          onClick={activateAccount}
          sx={{
            textTransform: "capitalize",
            border: "1px solid #005F8E",
            backgroundColor: "#005F8E",
            fontWeight: "400 !important",
            "&:hover": {
              backgroundColor: "#005F8E",
            },
            "&:active": {
              backgroundColor: "#005F8E",
            },
            "&:disabled": { color:"white",
              backgroundColor: "#005F8E",
            },
          }}
        >
         {Labels.Activate}
        </Button>
      </Stack>
    </Dialog>
  );
}
