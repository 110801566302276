import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { useSelector } from "react-redux";
import { Autocomplete, Skeleton, TextField } from "@mui/material";
import CreateCompany from "../Employer/Companies/createCompany";
import InputLabel from "@mui/material/InputLabel";
import { tr } from "date-fns/locale";
import {profanityfn} from "../Profanity/Profanity";

export default function AddedCompanies({ style, value, setValue,setView }) {
  const [createCompany, setcreateCompany] = useState(false);
  const [allCompanies, setAllCompanies] = useState([]);

  // const [value, setValue] = useState([]);

  const [companyName, setChildValue] = useState(null);

  const [childData, setChildData] = useState(0);

  const [childData1, setChildData1] = useState(null);

  const [iscreateCompany, IssetcreateCompany] = useState(false);

  const handleChildData1 = (data) => {
    setChildData1(data);
  };
 
  //FOR CALL ALL COMPANIES API AFTER 2 SEC
  const handleChildData = (data) => {
    setTimeout(() => {
      setChildData(childData + 1);
    }, 2000);
  };

  // useEffect(() => {
  //   const getAllCompanies = async () => {
  //     const result = await axiosAPI.get("/getCompanyNames");
  //     const { status, data } = await result.data;
  //   
  //     if (status === true) {
  //       setAllCompanies(data);
  //       if (value === "others") {
  //         setValue(data.at(-1).ccp_id)
  //       } else {
  //         setValue('')
  //       }
  //     } else {
  //       setAllCompanies([]);
  //     }
  //   };
  //   getAllCompanies();
  // }, [childData]);

  // useEffect(() => {

  const getAllCompanies1 = async () => {
    const result = await axiosAPI.get("/getCompanyNames");

    const { status, data } = await result.data;
    if (status === true) {
      setAllCompanies(data);
      if (value === "others") {
        setValue(data.at(-1).ccp_id);
      } else {
        setValue("");
      }
    } else {
      setAllCompanies([]);
    }
  };
  // }, []);

  useEffect(() => {
    getAllCompanies1();
  }, []);
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)
  const handleChange = (event) => {
    setValue(profanityfn(ProfanityList,event.target.value));
  };

  const handleOthersClick = () => {
    // Set a specific value for "Others" (you can customize this value)
    IssetcreateCompany(true);
    setView(false)
    // Additional logic if needed
  };

  const handleCloseModal = () => {
    IssetcreateCompany(false);
    setView(true)
  };

  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <Fragment>
      <FormControl sx={{ width: "100%" }}>
        {/* <InputLabel  style={{ color: "rgb(188, 188, 188)" }} >Select Companies</InputLabel> */}
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          value={value}
          onChange={handleChange}
          fullWidth
          displayEmpty
          variant="outlined"
          sx={{ padding: "10px 12px", fontFamily: "Poppins",borderColor: "#005F8E", // Change border color
        
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: '#005F8E',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#005F8E',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#000000',
            }, ...style }}
        >
          <MenuItem disabled value="">
            <span style={{ color: "rgb(188, 188, 188)", fontSize: "14px" }}>
              {Labels?.companyName} 
            </span>
          </MenuItem>
          {allCompanies.map((val, index) => (
            <MenuItem key={index} value={val.ccp_id}>
              {val.companyName}
            </MenuItem>
          ))}

          {allCompanies.length === 0 && (
            <MenuItem value="others" onClick={handleOthersClick}>
              {Labels?.others}
            </MenuItem>
          )}
        </Select>
        
        <CreateCompany
          // open={value === "others"}
          open={iscreateCompany}
          handleClose={handleCloseModal}
          // handleClose={() => setcreateCompany(false)}
          getAllCompanies={getAllCompanies1}
        />
      </FormControl>
    </Fragment>
  );
}
