import { Box, Button, Grid, Dialog, Stack, Typography } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import React from "react";
import { useState } from "react";
import LanguageLevel from "../../CommonCode/LanguageLevel";
import AllLanguage from "../../CommonCode/AllLanguage";
import { axiosAPI } from "../../Axios/Axios";
import { errorNotify, succesNotify } from "../../CommonCode/Commonfunc";
import { Label } from "@mui/icons-material";
import { useSelector } from "react-redux";
import AllLanguageJobSeeker from "../../CommonCode/AllLanguagesjobseeker";
import LanguageLevelJobSeeker from "../../CommonCode/LanguageLevelJobseeker";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "60%",
    sm: "50%",
    md: "40%",
    lg: "30%",
  },
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

export default function LanguageAddModal({ open, handleClose }) {
  const [language, setLanguage] = useState("");
  const [languageReadLevel, setLanguageReadLevel] = useState("");
  const [languageWriteLevel, setLanguageWriteLevel] = useState("");
  const [FilterLanguage,setFilterLanguage] = useState('');
  const jobseekerId = localStorage.getItem("uid");
  const Labels = useSelector((state) => state.allLabels.labels);
  const saveLanguage = async () => {
    if (!language) {
      return errorNotify(Labels.Languagefieldisrequired);
    } else if (!languageReadLevel) {
      return errorNotify(Labels.Spokenlevelfieldisrequired);
    } else if (!languageWriteLevel) {
      return errorNotify(Labels.Writtenlevelfieldisrequired);
    }
    const postData = {
      jobseekerid: jobseekerId,
      language: language,
      written: languageWriteLevel,
      spoken: languageReadLevel,
    };
    const result = await axiosAPI.post("/addLanguageLevel", postData);
    const { status, message } = result.data;
    if (status) {
      succesNotify(message);
      handleClose();
      setLanguage("");
      setLanguageReadLevel("");
      setLanguageWriteLevel("");
    } else {
      errorNotify(message);
    }
  };

  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <Box sx={{ p: 2.5 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ my: 2 }}
        >
          <Typography variant="h6" sx={{ color: "#005F8E" }}>
            {Labels?.language}
          </Typography>
          <HighlightOffIcon
            sx={{
              color: "#005F8E",
            }}
            onClick={() => handleClose(true)}
          />
        </Stack>
        <Box sx={{ my: 1 }}>
          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            <Box sx={{ width: 200}}>
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                {Labels?.Spoken}
              </Typography>
              <AllLanguageJobSeeker
                value={language}
                setValue={setLanguage}
                label="Languages"
              />
            </Box>
            <Box sx={{ width: 200 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                {Labels.Read}
              </Typography>
              <LanguageLevelJobSeeker
                value={languageReadLevel}
                setValue={setLanguageReadLevel}
                label={Labels.Spoken}
              />
            </Box>
            <Box sx={{ width: 200 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                {Labels?.Written}
              </Typography>
              <LanguageLevelJobSeeker
                value={languageWriteLevel}
                setValue={setLanguageWriteLevel}
                label={Labels?.Written}
              />
            </Box>
          </Stack>
        </Box>
        <Stack direction="row" justifyContent="center" sx={{ p: 1.5 }}>
          <Button
            variant="solid"
            sx={{
              background: "#005F8E",
              borderRadius: 3,
              color: "#fff",
              "&:hover": {
                backgroundColor: "#005F8E",
              },
              "&:active": {
                backgroundColor: "#005F8E",
              },
              "&:disabled": { color:"white",
                backgroundColor: "#005F8E",
              },
            }}
            onClick={saveLanguage}
            // disabled={isButtonDisable}
          >
           {Labels?.Save}
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}
