import {
  Box,
  Modal,
  Dialog,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Designation from "../../CommonCode/Designation";
import { Button } from "@mui/joy";
import { axiosAPI } from "../../Axios/Axios";
import {
  errorNotify,
  succesNotify,
  useStyles,
} from "../../CommonCode/Commonfunc";
import JobType from "../../CommonCode/JobType";
import TextfieldCustom from "../../Components/TextfieldCustom";
import { useSelector } from "react-redux";
import {profanityfn}  from "../../Profanity/Profanity";
import DesignationProfile from "../../CommonCode/DesignationProfile";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "60%",
    sm: "50%",
    md: "40%",
    lg: "30%",
  },
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

const EploymentModal = ({ open, handleClose }) => {
  const classes = useStyles();
  const [isButtonDisable, setButtonDisable] = useState(false);
  const [designation, setDesignation] = useState("");
  const [Jobtype, setJobtype] = useState("");
  const [empstart, setempstart] = useState("");
  const [empend, setend] = useState("");
  const [company, setCompany] = useState("");
  const [description, setDescription] = useState("");
  const today = new Date().toISOString().split("T")[0];

  const submitEmploymentHistory = async (e) => {
    e.preventDefault();
    setButtonDisable(true);
    if (!designation || !Jobtype || !company || !empstart || !empend) {
      setButtonDisable(false);
      return errorNotify(Labels.allFieldsMandatory);
    } else if (company.length > 200) {
      setButtonDisable(false);
      errorNotify(Labels.companyNameValidation);
    } else {
      setButtonDisable(false);

      const startDate = new Date(empstart);
      const endDate = new Date(empend);

      const startYear = startDate.getFullYear().toString();
      const endYear = endDate.getFullYear().toString();

      const startMonth = new Intl.DateTimeFormat("en-US", {
        month: "long",
      }).format(startDate);

      const endMonth = new Intl.DateTimeFormat("en-US", {
        month: "long",
      }).format(endDate);

      if (startYear > endYear) {
        errorNotify(Labels.startyearshouldnot);
      } else if (startYear === endYear) {
        const startMonthtest = startDate.getMonth();
        const endMonthtest = endDate.getMonth();

        if (startMonthtest > endMonthtest) {
          errorNotify(Labels.startmonthshouldnot);
        } else {
          const totalYear = startYear - endYear;
          const postData = {
            designationid: designation,
            jobtype: Jobtype,
            nYears: totalYear,
            company: company,
            startyear: startYear,
            endyear: endYear,
            startMonth: startMonth,
            endMonth: endMonth,
            responsibilities: description,
          };

          const result = await axiosAPI.post("/addEmployment", postData);
          const { success, message } = result.data;
          if (success === true) {
            succesNotify(Labels.employmentSuccess);
            setButtonDisable(false);
            handleClose();
          } else {
            setButtonDisable(false);
            errorNotify(message);
          }
        }
      } else {
        const totalYear = startYear - endYear;
        const postData = {
          designationid: designation,
          jobtype: Jobtype,
          nYears: totalYear,
          company: company,
          startyear: startYear,
          endyear: endYear,
          startMonth: startMonth,
          endMonth: endMonth,
          responsibilities: description,
        };

        const result = await axiosAPI.post("/addEmployment", postData);
        const { success, message } = result.data;
        if (success === true) {
          succesNotify(Labels.employmentSuccess);
          setButtonDisable(false);
          handleClose();
        } else {
          setButtonDisable(false);
          errorNotify(message);
        }
      }
    }
  };
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)

  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <Dialog open={open} onClose={() => handleClose(false)}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 2 }}
      >
        <Typography variant="h6" sx={{ color: "#106995" }}>
          {Labels?.Employment}
        </Typography>
        <HighlightOffIcon
          sx={{
            color: "#106995",
            cursor: "pointer",
          }}
          onClick={handleClose}
        />
      </Stack>

      <Box
        className={classes.customColoredScrollbar}
        sx={{ p: 2, height: "60vh", overflow: "auto" }}
      >
        <div style={{ margin: "10px 5px" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            {Labels?.designation}
          </Typography>
          <DesignationProfile
            value={designation}
            setValue={setDesignation}
            style={{
              width: "100%",
            }}
          />
        </div>
        <div style={{ margin: "10px 5px" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            {Labels?.JobType}
          </Typography>
          <JobType
            value={Jobtype}
            setValue={setJobtype}
            style={{
              width: "100%",
            }}
          />
        </div>
        <div style={{ margin: "10px 5px" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            {Labels?.company}
          </Typography>
          <TextfieldCustom
            style={{
              width: "100%",
            }}
            name="company"
            value={company}
            onChange={(e) => setCompany(profanityfn(ProfanityList,e.target.value))}
            label={Labels?.company}
          />
        </div>
        <div style={{ margin: "10px 5px" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
            {Labels?.description}
          </Typography>
          <TextField
            placeholder="Description"
            multiline
            rows={4}
            variant="outlined"
            sx={{
              width: "100%",
              "& .MuiInputBase-input": {
                padding: "10px",
                border: "0.5px solid #106995",
                borderColor: "#106995",
                borderRadius:1,
              },
              "& .MuiInputLabel-root": {
                padding: "10px",
              },
              "&:focus": {
      border: "0.5px solid #106995",
      boxShadow: "#d6edf7",
      borderColor: "#106995",
    },
            }}
            onChange={(e) => setDescription(profanityfn(ProfanityList,e.target.value))}
            value={description}
          />
        </div>
        <Stack
          direction={{ xs: "column", md: "row" }}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mx: 1,
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
              {Labels?.From}
            </Typography>
            <TextField
              type="date"
              name="date"
              value={empstart}
              onChange={(event) => setempstart(profanityfn(ProfanityList,event.target.value))}
              inputProps={{
                max: today,
              }}
              sx={{
                "& .MuiInputBase-input": {
                  borderRadius:1,
                border: "0.5px solid #106995",
                borderColor: "#106995",
             
              },
              
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mx: 1,
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
              {Labels?.To}
            </Typography>
            <TextField
              type="date"
              name="date"
              value={empend}
              onChange={(event) => setend(profanityfn(ProfanityList,event.target.value))}
              inputProps={{
                min: empstart,
                max: today,
              }}
              sx={{
                "& .MuiInputBase-input": {
                 borderRadius:1,
                border: "01px solid #106995",
                borderColor: "#106995",
             
              },
              
              }}
            />
          </Box>
        </Stack>
        <Stack direction="row" justifyContent="center" sx={{ p: 1.5 }}>
          <Button
            variant="solid"
            fullWidth
            sx={{
              background: "#106995",
              borderRadius: 3,
              "&:hover": {
                backgroundColor: "#106995",
              },
              "&:active": {
                backgroundColor: "#106995",
              },
              "&:disabled": { color:"white",
                backgroundColor: "#106995",
              },
            }}
            onClick={submitEmploymentHistory}
            disabled={isButtonDisable}
          >
            {Labels?.Save}
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default EploymentModal;
