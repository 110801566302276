import {
  Box,
  Dialog,
  Stack,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import JobType from "../../CommonCode/JobType";
import Designation from "../../CommonCode/Designation";
import { axiosAPI } from "../../Axios/Axios";
import { errorNotify, succesNotify } from "../../CommonCode/Commonfunc";
import TextfieldCustom from "../../Components/TextfieldCustom";
import { useSelector } from "react-redux";
import {profanityfn}  from "../../Profanity/Profanity";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

export default function EmployementUpdateModal({
  openEmploymentUpdateModal,
  employemtUpdateModalClose,
  data,
}) {
  const [designationId, setDesignationId] = useState(data.designationid);
  const [JobtypeId, setJobtypeId] = useState(data.jobtype);
  const [companyName, setCompanyName] = useState(data.company);
  const [empstart, setempstart] = useState("");
  const [empend, setend] = useState("");
  const today = new Date().toISOString().split("T")[0];
  const Labels = useSelector((state) => state.allLabels.labels);
  const [description, setDescription] = useState(data.responsibilities);
console.log(data,"data");
console.log(designationId,"designationIddataaaaaaaa");

  useEffect(() => {
    const startYear = data.startyear;
    const startMonth = data.startMonth;
    const endYear = data.endyear;
    const endMonth = data.endMonth;
    const startedDate = `${startYear}-${(
      "0" +
      (new Date(startMonth + " 1, " + startYear).getMonth() + 1)
    ).slice(-2)}-01`;
    const endedDate = `${endYear}-${(
      "0" +
      (new Date(endMonth + " 1, " + endYear).getMonth() + 1)
    ).slice(-2)}-01`;

    setempstart(startedDate);
    setend(endedDate);
  }, []);

  const updateEmployment = async (e) => {
    e.preventDefault();
    if (!designationId || !JobtypeId || !companyName || !empstart || !empend) {
      return errorNotify(Labels.allFieldsMandatory);
    } else if (companyName.length > 200) {
      errorNotify(Labels.companyNameValidation);
    } else {
      const startDate = new Date(empstart);
      const endDate = new Date(empend);
      const startMonth = new Intl.DateTimeFormat("en-US", {
        month: "long",
      }).format(startDate);
      const startYear = startDate.getFullYear().toString();
      const endMonth = new Intl.DateTimeFormat("en-US", {
        month: "long",
      }).format(endDate);
      const endYear = endDate.getFullYear().toString();
      const totalYear = startYear - endYear;
      const postData = {
        employmentid: data.jseh_id,
        designationid: designationId,
        jobtype: JobtypeId,
        nYears: totalYear,
        company: companyName,
        startyear: startYear,
        endyear: endYear,
        startMonth: startMonth,
        endMonth: endMonth,
        responsibilities: description,
        language:localStorage.getItem("language")
      };
      const result = await axiosAPI.post("/updateEmployment", postData);
      const { success, message } = result.data;
      if (success === true) {
        succesNotify(message);
        employemtUpdateModalClose();
      } else {
        errorNotify(message);
      }
    }
  };
  console.log(designationId,"designationId");
  
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)

  return (
    <Dialog
      open={openEmploymentUpdateModal}
      onClose={employemtUpdateModalClose}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ p: 2 }}
      >
        <Typography variant="h6" sx={{ color: "#106995" }}>
        {Labels?.Employment}
        </Typography>
        <HighlightOffIcon
          sx={{
            color: "#106995",
            cursor: "pointer",
          }}
          onClick={employemtUpdateModalClose}
        />
      </Stack>
      <Box sx={{ p: 2 }}>
        <div style={{ margin: "10px 5px" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          {Labels?.designation}
          </Typography>
          <Designation
            value={designationId}
            setValue={setDesignationId}
            style={{
              width: "100%",
            }}
          />
        </div>
        <div style={{ margin: "10px 5px" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          {Labels?.JobType}
          </Typography>
          <JobType
            value={JobtypeId}
            setValue={setJobtypeId}
            style={{
              width: "100%",
            }}
          />
        </div>
        <div style={{ margin: "10px 5px" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          {Labels?.company}
          </Typography>
          <TextField
            sx={{
              width: "100%",
              "& .MuiInputBase-input": {
                padding: "10px",
                border: "0.5px solid #106995",
                borderColor: "#106995",
                borderRadius:1
              },
              
            }}
            name="company"
            value={companyName}
            onChange={(e) => setCompanyName(profanityfn(ProfanityList,e.target.value))}
          />
        </div>
        <div style={{ margin: "10px 5px" }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
          {Labels?.description}
          </Typography>
          <TextField
            placeholder="Description"
            multiline
            rows={4}
            variant="outlined"
            sx={{
              width: "100%",
              "& .MuiInputBase-input": {
                padding: "10px",
                border: "0.5px solid #106995",
                borderColor: "#106995",
                borderRadius:1
              },
              "& .MuiInputLabel-root": {
                padding: "10px",
              },
            }}
            onChange={(e) => setDescription(profanityfn(ProfanityList,e.target.value))}
            value={description}
          />
        </div>
        <Stack
          direction={{ xs: "column", md: "row" }}
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mx: 1,
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
              {Labels?.From}
            </Typography>
            <TextField
              type="date"
              name="date"
              value={empstart}
              onChange={(event) => setempstart(profanityfn(ProfanityList,event.target.value))}
              inputProps={{
                max: new Date().toISOString().split("T")[0],
              }}
              sx={{
                width: "100%",
                "& .MuiInputBase-input": {
                  padding: "10px",
                  border: "0.5px solid #106995",
                  borderColor: "#106995",
                  borderRadius:1
                },
                "& .MuiInputLabel-root": {
                  padding: "10px",
                },
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              mx: 1,
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
              {Labels?.To}
            </Typography>
            <TextField
              type="date"
              name="date"
              value={empend}
              onChange={(event) => setend(profanityfn(ProfanityList,event.target.value))}
              inputProps={{
                max: today,
                min: empstart,
              }}
              sx={{
                width: "100%",
                "& .MuiInputBase-input": {
                  padding: "10px",
                  border: "0.5px solid #106995",
                  borderColor: "#106995",
                  borderRadius:1
                },
                "& .MuiInputLabel-root": {
                  padding: "10px",
                },
              }}
            />
            <Stack direction="row" justifyContent="center" sx={{ p: 2 }}>
              <Button
                variant="solid"
                fullWidth
                sx={{
                  background: "#106995",
                  color: "white",
                  borderRadius: 3,
                  "&:hover": {
                    backgroundColor: "#106995",
                  },
                  "&:active": {
                    backgroundColor: "#106995",
                  },
                  "&:disabled": { color:"white",
                    backgroundColor: "#106995",
                  },
                }}
                onClick={(e) => updateEmployment(e)}
              >
                  {Labels?.Save}
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Dialog>
  );
}
