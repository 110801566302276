import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Stack,
  TextField,
  Typography,
  Chip,
  Switch,
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  createRef,
} from "react";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import CustomButton from "../../Components/CustomButton";
import PhoneIcon from "@mui/icons-material/Phone";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import TourOutlinedIcon from "@mui/icons-material/TourOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useSelector } from "react-redux";
import { axiosupload, axiosAPI } from "../../Axios/Axios";
import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import Designation from "../../CommonCode/Designation";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import {
  errorNotify,
  succesNotify,
  validatePhoneNumber,
  warningNotify,
} from "../../CommonCode/Commonfunc";
import Country from "../../CommonCode/Country";
import RecruiterCountry from "../../CommonCode/RecruiterCountry";
import State from "../../CommonCode/State";
import { useStyles } from "../../CommonCode/Commonfunc";
import EmployerPasswordChangeModal from "./EmployerPasswordChangeModal";
import IconLabelTextField from "../../Components/iconLabelText";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import SimpleBackdrop from "../../CommonCode/SimpleBackdrop";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Close, Label } from "@mui/icons-material";
import CompanyType from "../../CommonCode/CompanyType";
import BusinessCategory from "../../CommonCode/BusinessCategory";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import BallotOutlinedIcon from "@mui/icons-material/BallotOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import FlagIcon from "@mui/icons-material/Flag";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { getCompanyProfile } from "../../../redux/CompanyDetailSlice";
import { useDispatch } from "react-redux";
import YesNo from "../../CommonCode/YesNo";
import JobPostingDuration from "../../CommonCode/JobDuration";
import InputWithLabel from "../../Components/InputWithLabel";
import { useDropzone } from "react-dropzone";
import { Select } from "@material-ui/core";
import CompanyBranches from "./CompanyBranches";
import JobInfo from "../../CommonCode/JobInformation";
import EditBranches from "./EditCompanyBranch";
import CompanySize from "../../CommonCode/CompanySize";
import BranchState from "../../CommonCode/BranchEditState";
import { profanityfn, profanitySend } from "../../Profanity/Profanity";
export default function EmployerProfile({
  routeChange,
  isDisable,
  placeHolder,
  value,
  setvalue,
}) {
  const classes = useStyles();

  const [employerData, setEmployerData] = useState({});

  const [isButtonDisabled1, setButtonDisabled1] = useState(false);
  const [isButtonDisabled2, setButtonDisabled2] = useState(false);
  const [isButtonDisabled3, setButtonDisabled3] = useState(false);
  const [isButtonDisabled4, setButtonDisabled4] = useState(false);
  const [isButtonDisabled5, setButtonDisabled5] = useState(false);
  const [receiveCoverLetter, setExpectCoverLetter] = useState(0);
  const [showProfile, setShowProfile] = useState(0);
  const [contactBymail, setShowEmail] = useState(0);
  const [JobPostDuration, setJobPostDuration] = useState(0);
  const [backdropOpen, setBackdrop] = useState(false);
  const [openPasswordResetModal, setPasswordResetModal] = useState(false);
  const [designation, setDesignation] = useState("");
  const [businessCategories, setBusinessCategories] = useState([]);
  const [companyTypes, setCompanyTypes] = useState("");
  const [countryId, setCountryId] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [headQuarters, setHeadQuarters] = useState(null);
  const [headQuarterStates, setHeadQuarterStates] = useState(null);
  const Labels = useSelector((state) => state.allLabels.labels);
  const usertype = localStorage.getItem("userType");
  // const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [slogan, setSlogan] = useState("");
  const [images, setImages] = useState("");
  const [open, setOpen] = useState(false);
  const [isApiCalled, setIsApiCalled] = useState(0);
  const resettedByAdmin = localStorage.getItem("resettedByAdmin");
  const [CompanyBranch, setCompanyBranch] = useState([]);
  const [openBranchesAddModel, setopenBranchesModel] = useState(false);
  const [updatecompany, setupdatecompany] = useState({});
  const [openEditBranchmodal, setopenEditBranchmodal] = useState(false);
  const [companySize, setCompanySize] = useState("");
  const [comlogo, secomlogo] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [userchangedcountry, setuserchangedcountry] = useState(null);
  // -------------Set country code ------------------
  const [countryCode, setCountryCode] = useState("");
  const [companyTypeKey, setcompanyTypeKey] = useState("");

  useEffect(() => {
    getEmployerData();
  }, []);

  const handleClose = () => {
    setOpen(false);
    setIsApiCalled(Math.random());
  };

  const handleOnChange = (value) => {
    setCountryCode(value);
  };

  const tokens = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("employerToken"),
    },
  };

  useEffect(() => {
    setEmployerData({
      ...employerData,
      language: localStorage.getItem("language"),
    });
  }, [employerData?.companyName]);

  const getEmployerData = async () => {
    setBackdrop(true);
    const result = await axiosAPI.post("/getCompanyProfile");
    const { status, data, message } = result.data;

    if (status) {
      setEmployerData(data);
      setcompanyTypeKey(data?.companyTypeKey);
      setDesignation(
        data.contactPersonDesignation ? data.contactPersonDesignation : 0
      );
      setIsChecked(data.twoFactorPreferred == 1 ? true : false);
      setShowProfile(data.showProfile ? data.showProfile : 0);
      setShowEmail(data.contactBymail ? data.contactBymail : 0);
      setExpectCoverLetter(
        data.receiveCoverLetter ? data.receiveCoverLetter : 0
      );
      setJobPostDuration(data.JobPostDuration ? data.JobPostDuration : 0);

      setCountryId(
        data.countryId ? { id: data.countryId, name: data.countryName } : null
      );
      setStateId(
        data.headQuarterState
          ? { id: data.headQuarterState, name: data.headOfStateName }
          : null
      );

      setHeadQuarters(
        data?.headQuarterCountry
          ? { id: data?.headQuarterCountry, name: data.headOfCountryName }
          : null
      );

      setCompanySize(data.companySize ? data.companySize : "");
      setCompanyBranch(data?.locations);
      setBackdrop(false);
      setCountryCode(data.countryCode ? data.countryCode : "1");
      setCompanyTypes(data.companyType ? data.companyType : "");
      setBusinessCategories(data.businessCategory ? data.businessCategory : "");
    } else {
      setBackdrop(false);
      //// console.log(message);
    }
  };
  const ProfanityList = useSelector((state) => state.Profanity.wordlist);

  const handleChange = (e) => {
    setEmployerData({
      ...employerData,
      [e.target.name]: profanityfn(ProfanityList, e.target.value),
    });
  };

  const profanityCheckerProfile = () => {
    let profanityDetected;
    for (const key in employerData) {
      if (profanitySend(ProfanityList, employerData[key])) {
        profanityDetected = true;
        break;
      } else {
        profanityDetected = false;
      }
    }

    if (profanityDetected == false) {
      updateEmployerProfile();
    } else {
      warningNotify(Labels.ProfanityLabel);
    }
  };
  // --------------------Update Profile----------------------
  const updateEmployerProfile = async () => {
    setButtonDisabled1(true);
    employerData.userType = localStorage.getItem("userType");

    if (designation) {
      employerData.contactPersonDesignation = designation;
    }
    if (countryCode) {
      employerData.countryCode = countryCode;
    }
    if (companyTypes) {
      employerData.companyType = companyTypes;
    }
    if (companySize) {
      employerData.companySize = companySize;
    }
    if (businessCategories.length !== 0) {
      employerData.businessCategory = businessCategories
        .toString()
        .replace(/(^,|,$|,,+)/g, "");
    } else {
      employerData.businessCategory = null;
    }

    if (true) {
      employerData.headQuarterCountry = headQuarters?.id;
    }

    if (true) {
      employerData.headQuarterState = stateId?.id;
    }

    if (!employerData?.companyName) {
      errorNotify(Labels.companyNameRequired);
      setButtonDisabled1(false);
    } else if (employerData?.companyName?.length > 200) {
      errorNotify(Labels.companyNameValidation);
      setButtonDisabled1(false);
    } else {
      const result = await axiosAPI.post("/updateEmpProfile", employerData);
      const { success, message } = result.data;
      if (success) {
        setButtonDisabled1(false);
        succesNotify(message);
        getEmployerData();
        routeChange(Math.random());
      } else {
        setButtonDisabled1(false);
        errorNotify(message);
      }
    }
  };
  // --------------------Update Location----------------------
  const updateLocation = async (e) => {
    setButtonDisabled2(true);
    employerData.countryid = countryId?.id;
    employerData.stateid = stateId?.id;
    if (businessCategories.length !== 0) {
      employerData.businessCategory = businessCategories
        .toString()
        .replace(/(^,|,$|,,+)/g, "");
    } else {
      employerData.businessCategory = null;
    }
    if (!countryId?.id) {
      errorNotify(Labels.countryRequired);
      setButtonDisabled2(false);
    } else if (!stateId?.id) {
      errorNotify(Labels.stateRequired);
      setButtonDisabled2(false);
    } else {
      const result = await axiosAPI.post("/updateEmpProfile", employerData);
      const { success, message } = result.data;
      if (success) {
        succesNotify(message);
        getEmployerData();
        setButtonDisabled2(false);
      } else {
        //// console.log(message);
        setButtonDisabled2(false);
      }
    }
  };
  // --------------------Update Additional Information----------------------
  const updateCompanyAdditionalInformations = async () => {
    setButtonDisabled4(true);
    employerData.headQuarterCountry = headQuarters?.id;
    if (businessCategories.length !== 0) {
      employerData.businessCategory = businessCategories
        .toString()
        .replace(/(^,|,$|,,+)/g, "");
    } else {
      employerData.businessCategory = null;
    }
    if (!headQuarters) {
      errorNotify(Labels.Headquartersisrequired);
      setButtonDisabled4(false);
    } else {
      const result = await axiosAPI.post("/updateEmpProfile", employerData);
      const { success, message } = result.data;
      if (success) {
        succesNotify(message);
        getEmployerData();
        setButtonDisabled4(false);
      } else {
        //// console.log(message);
        setButtonDisabled4(false);
      }
    }
  };

  const profanityCheckerCompanyDesc = () => {
    let profanityDetected;
    for (const key in employerData) {
      if (profanitySend(ProfanityList, employerData[key])) {
        profanityDetected = true;
        break;
      } else {
        profanityDetected = false;
      }
    }

    if (profanityDetected == false) {
      updateCompanyDescription();
    } else {
      warningNotify(Labels.ProfanityLabel);
    }
  };
  // --------------------Update Description-------------------
  const updateCompanyDescription = async (e) => {
    setButtonDisabled3(true);
    if (businessCategories.length !== 0) {
      employerData.businessCategory = businessCategories
        .toString()
        .replace(/(^,|,$|,,+)/g, "");
    } else {
      employerData.businessCategory = null;
    }

    if (!employerData?.companyDescription) {
      setButtonDisabled3(false);
      return errorNotify(Labels.writeCompanyDescription);
    } else if (employerData?.companyDescription.length > 5000) {
      setButtonDisabled3(false);
      errorNotify(Labels.companyDescriptionValidation);
    } else {
      setEmployerData({
        ...employerData,
        language: localStorage.getItem("language"),
      });

      const result = await axiosAPI.post("/updateEmpProfile", employerData);
      const { success, message } = result.data;
      if (success) {
        succesNotify(message);
        getEmployerData();
        setButtonDisabled3(false);
      } else {
        setButtonDisabled3(false);
        errorNotify(message);
      }
    }
  };

  // --------------------Update Slogan-------------------
  const allowedTypes = ["image/jpeg", "image/png", "image/JPG"];
  const maxFileSize = 1 * 1024 * 1024;

  const [selectSlogan, setSelectSolgan] = useState(false);
  const [sloganname, setSloganname] = useState(null);

  const [openSlogan, setOpenSlogan] = useState(false);
  const onChange1 = (e) => {
    e.preventDefault();
    setOpenSlogan(false);
    setSelectSolgan(true);
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files[0];
    }
    setSloganname(files);
    const reader = new FileReader();
    reader.onload = () => {
      setSlogan(reader.result);
    };
    reader.readAsDataURL(files);
  };
  const handleUploadSlogan = async () => {
    // if (typeof cropperRef.current?.cropper !== "undefined") {
    const croppedDataURL = cropperRef.current.cropper
      .getCroppedCanvas()
      .toDataURL();
    // setCropData(croppedDataURL);
    // setSelectImage(false);
    // uploadProfilePicture(croppedDataURL);
    //// console.log("User Type:", usertype);
    updateSlogan(croppedDataURL, sloganname, usertype);
    // }
  };

  const updateSlogan = async (manipulatedDataURL, sloganname, usertype) => {
    const formData1 = new FormData();
    const blob1 = await fetch(manipulatedDataURL).then((r) => r.blob());
    formData1.append("slogan", blob1);
    formData1.append("usertype", usertype);

    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    // const maxFileSize = 1 * 1024 * 1024;

    if (!allowedTypes.includes(blob1.type)) {
      // throw new Error("Invalid file type selected");
      return errorNotify("Invalid file type selected");
    }
    // if (blob1.size > maxFileSize) {
    //   // throw new Error("Please select an image smaller than 1MB.");
    //   return errorNotify("Please select an image smaller than 1MB.");
    // }
    const blob3 = formData1.get("slogan"); // Replace 'yourBlobField' with the actual field name

    // Create a proper File from the Blob
    const fileName = sloganname.name; // Replace with your desired file name
    const mimeType = sloganname.type; // Replace with your desired MIME type

    const file = new File([blob3], fileName, { type: mimeType });

    const formData3 = new FormData();
    formData3.append("slogan", file);
    formData3.append("userType", usertype);
    formData3.append("language", localStorage.getItem("language"));
    try {
      const response = await axiosupload.post(
        "/updateEmpProfile",
        // employerData,
        formData3,
        tokens
      );
      const { success, message } = response.data;
      if (success === true) {
        succesNotify(message);
        setSelectSolgan(false);
        setOpenSlogan(false);
        getEmployerData();
        // setApiCall(Math.random());
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
    //// console.log("Form Data:", formData);
  };
  const [Ischecked, setIsChecked] = useState(false);

  const handleToggle = () => {
    setIsChecked((prevChecked) => {
      sendValueToAPI(!prevChecked);
      return !prevChecked;
    });
  };

  const sendValueToAPI = (value) => {
    axiosAPI
      .post("/enableDisableTFA", { twoFactorPreferred: value ? 1 : 0 }, tokens)
      .then((res) => {
        //// console.log(res);
      })
      .catch((err) => {
        //// console.log(err);
      });
  };
  const dropzoneStyles = {
    border: "2px dashed #cccccc",
    borderRadius: "4px",
    padding: "20px",
    textAlign: "center",
    cursor: "pointer",
  };
  // const companyprofile = useSelector(
  //   (state) => state.companyDetails.companyprofile
  // );
  // const getCompanyProfile=()=>{
  //   dispatch(getCompanyProfile());
  // };
  //  useEffect(() => {
  //    getCompanyProfile();
  //  }, [Labels]);

  const profanityCheckerMiscellaneous = () => {
    let profanityDetected;
    for (const key in employerData) {
      if (profanitySend(ProfanityList, employerData[key])) {
        profanityDetected = true;
        break;
      } else {
        profanityDetected = false;
      }
    }

    if (profanityDetected == false) {
      updateMiscellaneous();
    } else {
      warningNotify(Labels.ProfanityLabel);
    }
  };

  const updateMiscellaneous = async () => {
    setButtonDisabled5(true);
    try {
      if (businessCategories.length !== 0) {
        employerData.businessCategory = businessCategories
          .toString()
          .replace(/(^,|,$|,,+)/g, "");
      } else {
        employerData.businessCategory = null;
      }

      if (showProfile) {
        employerData.showProfile = showProfile;
      }

      if (contactBymail) {
        employerData.contactBymail = contactBymail;
      }
      if (receiveCoverLetter) {
        employerData.receiveCoverLetter = receiveCoverLetter;
      }
      if (JobPostDuration) {
        employerData.JobPostDuration = JobPostDuration;
      }
      setEmployerData({
        ...employerData,
        language: localStorage.getItem("language"),
      });
      const result = await axiosAPI.post("/updateEmpProfile", employerData);
      const { success, message } = result.data;
      if (success) {
        succesNotify(message);
        getEmployerData();
        setButtonDisabled5(false);
      } else {
        setButtonDisabled5(false);
        errorNotify(message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  //  =-------------------------------------delete Company Branch-------------------------------
  const deleteCompanyBranch = async (branchid) => {
    try {
      // Call your editCompanyBranch API here to delete the branch
      const result = await axiosAPI.post("/deleteCompanyBranch", { branchid });
      const { status, message } = result.data;

      if (status) {
        // Update employerData after successful deletion
        const updatedBranches = employerData.companyBranches.filter(
          (branch) => branch.branchid !== branchid
        );

        setEmployerData((prevData) => ({
          ...prevData,
          companyBranches: updatedBranches,
        }));
      } else {
        console.error("Error deleting branch:", message);
        // Handle error as needed
      }
    } catch (error) {
      console.error("Error deleting branch:", error);
      // Handle error as needed
    }
  };

  const closeBranchesModal = () => {
    setopenBranchesModel(false);
    getEmployerData();
    setIsApiCalled(Math.random());
  };
  //// console.log(CompanyBranch, "Branche");

  const closeEditcompanyBranchmodal = () => {
    setopenEditBranchmodal(false);
    getEmployerData();
    setIsApiCalled(Math.random());
  };
  const EditcomapnyBranch = (data) => {
    setupdatecompany(data);
    setopenEditBranchmodal(usertype === "Recruiter" ? false : true);
  };
  const [image, setImage] = useState("");
  const [cropData, setCropData] = useState("");
  const [selectImage, setSelectImage] = useState(false);
  const [imagename, setImagename] = useState(null);
  const cropperRef = createRef();
  const [openImage, setOpenImage] = useState(false);
  const onChange = (e) => {
    e.preventDefault();
    setOpenImage(false);
    setSelectImage(true);
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files[0];
    }
    setImagename(files);
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files);
  };
  const handleUpload = async () => {
    // if (typeof cropperRef.current?.cropper !== "undefined") {
    const croppedDataURL = cropperRef.current.cropper
      .getCroppedCanvas()
      .toDataURL();
    // setCropData(croppedDataURL);
    // setSelectImage(false);
    // uploadProfilePicture(croppedDataURL);
    //// console.log("User Type:", usertype);
    uploadProfilePicture(croppedDataURL, imagename, usertype);
    // }
  };

  const uploadProfilePicture = async (
    manipulatedDataURL,
    imagename,
    usertype
  ) => {
    const formData = new FormData();
    const blob = await fetch(manipulatedDataURL).then((r) => r.blob());
    formData.append("images", blob);
    formData.append("usertype", usertype);

    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    const maxFileSize = 1 * 1024 * 1024;

    if (!allowedTypes.includes(blob.type)) {
      // throw new Error("Invalid file type selected");
      return errorNotify("Invalid file type selected");
    }
    if (blob.size > maxFileSize) {
      // throw new Error("Please select an image smaller than 1MB.");
      return errorNotify("Please select an image smaller than 1MB.");
    }
    const blob2 = formData.get("images"); // Replace 'yourBlobField' with the actual field name

    // Create a proper File from the Blob
    const fileName = imagename.name; // Replace with your desired file name
    const mimeType = imagename.type; // Replace with your desired MIME type

    const file = new File([blob2], fileName, { type: mimeType });

    const formData2 = new FormData();
    formData2.append("images", file);
    formData2.append("userType", usertype);
    formData2.append("language", localStorage.getItem("language"));
    try {
      const response = await axiosupload.post(
        "/updateEmpProfile",
        // employerData,
        formData2,
        tokens
      );
      const { success, message } = response.data;
      if (success === true) {
        succesNotify(message);
        setSelectImage(false);
        setOpenImage(false);
        getEmployerData();
        // setApiCall(Math.random());
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
    //// console.log("Form Data:", formData);
  };
  return (
    <>
      {/* ------------------------Modals---------------------- */}
      <>
        {openBranchesAddModel === true ? (
          <CompanyBranches
            open={openBranchesAddModel}
            handleClose={closeBranchesModal}
            data={CompanyBranch}
          />
        ) : null}
        {openEditBranchmodal === true ? (
          <EditBranches
            open={openEditBranchmodal}
            handleClose={closeEditcompanyBranchmodal}
            data={updatecompany}
          />
        ) : null}
      </>
      <Grid container spacing={2}>
        <SimpleBackdrop open={backdropOpen} />

        <Grid item xs={12} lg={7}>
          {/* --------------------Company Information-------------------- */}
          <Paper elevation={0}>
            <Typography
              variant="subtitle1"
              sx={{ color: "#106995", p: 2, fontWeight: 600 }}
            >
              {Labels?.companyInformation}
            </Typography>
            <Divider />
            <Box sx={{ p: 2 }} className="jobseekerProfile">
              <Grid item xs={12} lg={2.5}>
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 400, color: "#106995" }}
                >
                  {Labels.CompanyLogo}
                </Typography>

                {/* <Box sx={{ p: 1.5 }} className="jobseekerProfile"> */}
                {/* <div
                    {...getRootProps({
                      className: "dropzone",
                      style: { height: "80px" },
                    })}
                  >
                    <input
                      {
                        ...getInputProps({
                        // onChange: (event) => setcom,logoi(event.target.files),
                        style: { height: "80px" },
                      })}
                    />
                    <p>{"company Logo"}</p>
                  </div>
                  <aside>
                    <ul */}
                {/* // style={{ display: "flex", alignItems: "center" }} */}
                {/* >
                      
                    </ul>
                  </aside> */}

                {/* </Box> */}
                <Box
                  sx={{ position: "relative", display: "inline-block", mt: 2 }}
                >
                  <Avatar
                    sx={{
                      width: 130,
                      height: 120,
                      borderRadius: 1,
                      cursor: usertype !== "Recruiter" ? "pointer" : "cursor",
                      marginRight: "20px", // Adjust margin as needed
                    }}
                    src={`${employerData?.logoPath}`}
                    onClick={() => setOpenImage(true)}
                  />
                  {usertype !== "Recruiter" && (
                    <IconButton
                      component="label"
                      sx={{
                        position: "absolute",
                        top: "90%",
                        right: "10px", // Adjust this value as needed
                        transform: "translateY(-50%)",
                        padding: 0.5,
                        background: "white",
                        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                        "&:hover": {
                          background: "white",
                        },
                      }}
                    >
                      <CameraAltIcon
                        sx={{
                          color: "black",
                        }}
                      />
                      <InputBase
                        type="file"
                        style={{ display: "none" }}
                        accept="image/jpeg, image/png, image/gif"
                        onChange={onChange}
                      />
                    </IconButton>
                  )}
                </Box>
                <Dialog
                  open={selectImage}
                  onClose={() => setSelectImage(false)}
                >
                  <DialogTitle>
                    <Stack direction="row" justifyContent="flex-end">
                      <Close
                        sx={{ cursor: "pointer" }}
                        onClick={() => setSelectImage(false)}
                      />
                    </Stack>
                  </DialogTitle>
                  <DialogContent>
                    <Box
                      sx={{
                        width: { xs: "60%", sm: "50%", md: "60%", lg: "80%" },
                        height: { xs: "60%", sm: "50%", md: "60%", lg: "80%" },
                      }}
                    >
                      <Cropper
                        ref={cropperRef}
                        style={{ height: "100%", width: "100%" }}
                        zoomTo={0.5}
                        initialAspectRatio={1}
                        preview=".img-preview"
                        src={image}
                        viewMode={1}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}
                        guides={true}
                      />
                    </Box>
                  </DialogContent>
                  <DialogActions sx={{ padding: "25px" }}>
                    <CustomButton
                      onClick={handleUpload}
                      label={Labels.Upload}
                      style={{ padding: "5px 18px" }}
                    />
                  </DialogActions>
                </Dialog>
              </Grid>
              {/* ---------------Company Name------------------ */}
              <IconLabelTextField
                icon={
                  <BusinessOutlinedIcon
                    sx={{
                      color: "#000",
                    }}
                  />
                }
                InputLabel={Labels?.companyName}
                type="text"
                label={Labels?.companyName}
                name="companyName"
                value={employerData?.companyName}
                onChange={(e) => handleChange(e)}
                readOnly={usertype === "Recruiter" ? true : false}
                style={{ width: "100%" }}
              />
              {/* ---------------Company Type------------------ */}

              {/* <IconLabelTextField
                icon={
                  <BallotOutlinedIcon
                    sx={{ color: "#65AC98", mr: 1, width: 25, fontSize: 25 }}

                  />
                }
                InputLabel={Labels?.CompanyType}
                type="text"
                label={Labels?.CompanyType}
                name="companyTypeName"
                value={employerData?.companyTypeName}
                onChange={(e) => handleChange(e)}
                style={{ width: "100%" }}
                readOnly
              /> */}
              {/* <label style={{ color: "#413E3E", width: 230 }}>
                Company Type
              </label>
              <CompanyType
                value={companyTypes}
                setValue={setCompanyTypes}
                style={{ width: "100%", fontSize: "14px" }}
              /> */}

              {/* ---------------business category------------------ */}
              {employerData?.userType === "Company" ? (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <CategoryOutlinedIcon
                    sx={{ color: "#000", mr: 1, width: 25, fontSize: 25 }}
                  />
                  <label style={{ color: "#106995", width: 230 }}>
                    {Labels?.BusinessIndustry}
                  </label>
                  <BusinessCategory
                    value={businessCategories}
                    setValue={setBusinessCategories}
                    displayEmpty
                    style={{ width: "100%", fontSize: "14px" }}
                  />
                </Stack>
              ) : (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ my: 1.5 }}
                >
                  <CategoryOutlinedIcon
                    sx={{ color: "#000", mr: 1, width: 25, fontSize: 25 }}
                  />
                  <label style={{ color: "#413E3E", width: 230 }}>
                    {Labels?.BusinessIndustry}
                  </label>
                  <BusinessCategory
                    value={businessCategories}
                    setValue={setBusinessCategories}
                    style={{ width: "100%", fontSize: "14px" }}
                  />
                </Stack>
              )}
              {/* ---------------company size------------------ */}

              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ my: 1.5 }}
              >
                <AssessmentIcon
                  sx={{ color: "#000", mr: 1, width: 25, fontSize: 25 }}
                />
                <label style={{ color: "#106995", width: 230 }}>
                  {Labels?.CompanySize}
                </label>
                <CompanySize
                  value={companySize}
                  setValue={setCompanySize}
                  readOnly={usertype === "Recruiter" ? true : false}
                  style={{ width: "100%", fontSize: "14px" }}
                />
              </Stack>
              {/* ------------Headquarters--------------- */}
              {employerData?.userType === "Recruiter" ? (
                <>
                  <Grid
                    container
                    item
                    xs={12}
                    md={12}
                    justifyContent="space-between"
                    alignItems="center"
                    direction="row"
                    sx={{
                      display: {
                        xs: "none",
                        md: "flex",
                        lg: "none",
                        xl: "flex",
                      }, // Hide in xs, show in md
                    }}
                    mt={2}
                  >
                    {/* Headquarters Section */}
                    <Grid
                      item
                      xs={12}
                      md={4}
                      display="flex"
                      alignItems="center"
                    >
                      <PublicOutlinedIcon
                        sx={{
                          color: "#000",
                          marginRight: 1,
                          height: 25,
                          width: 25,
                        }}
                      />
                      <label style={{ color: "#106995", width: 150 }}>
                        {Labels?.Headquarters}
                      </label>
                      <Country
                        value={headQuarters}
                        setValue={setHeadQuarters}
                        readOnly={usertype === "Recruiter" ? true : false}
                        userchangedcountry={userchangedcountry}
                        setuserchangedcountry={setuserchangedcountry}
                        style={{
                          width: "90%",
                          fontSize: "14px",
                          marginLeft: 10,
                          borderColor: "#106995 !important",
                          "& .MuiOutlinedInput-root": {
                            padding: "0px",

                            "& fieldset": {
                              borderColor: "#106995 !important", // Change border color
                            },
                            "&:hover fieldset": {
                              borderColor: "#000000", // Change border color on hover
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#106995 !important", // Change border color when focused
                            },
                          },

                          "@media (max-width:600px)": {
                            marginLeft: 0, // No margin for xs screens
                          },
                        }}
                      />
                    </Grid>

                    {/* State Section */}
                    <Grid
                      item
                      xs={12}
                      md={3}
                      display="flex"
                      alignItems="center"
                    >
                      <TourOutlinedIcon sx={{ color: "#000", ml: 2 }} />
                      <label style={{ color: "#106995", width: 150 }}>
                        {Labels?.State}
                      </label>
                      <State
                        value={stateId}
                        setValue={setStateId}
                        country={headQuarters}
                        userchangedcountry={userchangedcountry}
                        setuserchangedcountry={setuserchangedcountry}
                        style={{
                          width: "100%",
                          fontSize: "14px",

                          borderColor: "#106995",
                          "& .MuiOutlinedInput-root": {
                            padding: "0px",

                            "& fieldset": {
                              borderColor: "#106995", // Change border color
                            },
                            "&:hover fieldset": {
                              borderColor: "#000000", // Change border color on hover
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#106995", // Change border color when focused
                            },
                          },
                        }}
                      />
                    </Grid>

                    {/* City Section */}
                    <Grid
                      item
                      xs={12}
                      md={3}
                      display="flex"
                      alignItems="center"
                    >
                      <ApartmentIcon sx={{ color: "#000" }} />
                      <label style={{ color: "#106995", width: 150 }}>
                        {Labels?.City}
                      </label>
                      <InputWithLabel
                        InputLabel={""}
                        label={Labels?.City}
                        name="headQuarterCity"
                        value={employerData?.headQuarterCity || ""}
                        onChange={(e) => handleChange(e)}
                        readOnly={usertype == "Recruiter" ? true : false}
                        disabled={usertype == "Recruiter" ? true : false}
                        style={{
                          width: "100%",
                          marginTop: -0.5,
                          borderColor: "#106995",
                          "& .MuiOutlinedInput-root": {
                            padding: "0px",

                            "& fieldset": {
                              borderColor: "#106995", // Change border color
                            },
                            "&:hover fieldset": {
                              borderColor: "#000000", // Change border color on hover
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#106995", // Change border color when focused
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      display: {
                        xs: "flex",
                        md: "none",
                        lg: "Flex",
                        xl: "none",
                      }, // Show in xs, hide in md
                    }}
                  >
                    <PublicOutlinedIcon
                      sx={{
                        color: "#000",
                        mr: 1,
                        width: 25,
                        fontSize: 25,
                      }}
                    />
                    <label style={{ color: "#106995", width: 230 }}>
                      {Labels?.Headquarters}
                    </label>
                    <Country
                      value={headQuarters}
                      userchangedcountry={userchangedcountry}
                      setuserchangedcountry={setuserchangedcountry}
                      setValue={setHeadQuarters}
                      readOnly={usertype === "Recruiter" ? true : false}
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        borderColor: "#106995 !important",
                        "& .MuiOutlinedInput-root": {
                          padding: "0px",

                          "& fieldset": {
                            borderColor: "#106995 !important", // Change border color
                          },
                          "&:hover fieldset": {
                            borderColor: "#000000", // Change border color on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#106995 !important", // Change border color when focused
                          },
                        },
                      }}
                    />
                  </Stack>

                  {/* State Section */}
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={2}
                    sx={{
                      display: {
                        xs: "flex",
                        md: "none",
                        lg: "Flex",
                        xl: "none",
                      }, // Show in xs, hide in md
                    }}
                  >
                    <TourOutlinedIcon
                      sx={{ color: "#000", mr: 1, width: 25, fontSize: 25 }}
                    />
                    <label style={{ color: "#106995", width: 230 }}>
                      {Labels?.State}
                    </label>
                    <State
                      value={stateId}
                      setValue={setStateId}
                      country={headQuarters}
                      userchangedcountry={userchangedcountry}
                      setuserchangedcountry={setuserchangedcountry}
                      style={{
                        width: "100%",
                        fontSize: "14px",

                        borderColor: "#106995",
                        "& .MuiOutlinedInput-root": {
                          padding: "0px",

                          "& fieldset": {
                            borderColor: "#106995", // Change border color
                          },
                          "&:hover fieldset": {
                            borderColor: "#000000", // Change border color on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#106995", // Change border color when focused
                          },
                        },
                      }}
                    />
                  </Stack>

                  {/* City Section */}
                  <Stack
                    mt={1}
                    sx={{
                      display: {
                        xs: "flex",
                        md: "none",
                        lg: "Flex",
                        xl: "none",
                      }, // Show in xs, hide in md
                    }}
                  >
                    <IconLabelTextField
                      icon={
                        <ApartmentIcon
                          sx={{
                            color: "#000",
                          }}
                        />
                      }
                      InputLabel={Labels?.City}
                      label={Labels?.City}
                      name="headQuarterCity"
                      value={employerData?.headQuarterCity || ""}
                      onChange={(e) => handleChange(e)}
                      readOnly={usertype == "Recruiter" ? true : false}
                      style={{
                        width: "100%",
                        marginTop: -0.5,
                        borderColor: "#106995",
                        "& .MuiOutlinedInput-root": {
                          padding: "0px",

                          "& fieldset": {
                            borderColor: "#106995", // Change border color
                          },
                          "&:hover fieldset": {
                            borderColor: "#000000", // Change border color on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#106995", // Change border color when focused
                          },
                        },
                      }}
                    />
                  </Stack>
                </>
              ) : (
                <>
                  {/* Headquarters Section */}
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      display: {
                        xs: "flex",
                      }, // Show in xs, hide in md
                    }}
                  >
                    <PublicOutlinedIcon
                      sx={{
                        color: "#000",
                        mr: 1,
                        width: 25,
                        fontSize: 25,
                      }}
                    />
                    <label style={{ color: "#106995", width: 230 }}>
                      {Labels?.Headquarters}
                    </label>
                    <Country
                      value={headQuarters}
                      userchangedcountry={userchangedcountry}
                      setuserchangedcountry={setuserchangedcountry}
                      setValue={setHeadQuarters}
                      readOnly={usertype === "Recruiter" ? true : false}
                      style={{
                        width: "100%",
                        fontSize: "14px",
                        borderColor: "#106995 !important",
                        "& .MuiOutlinedInput-root": {
                          padding: "0px",

                          "& fieldset": {
                            borderColor: "#106995 !important", // Change border color
                          },
                          "&:hover fieldset": {
                            borderColor: "#000000", // Change border color on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#106995 !important", // Change border color when focused
                          },
                        },
                      }}
                    />
                  </Stack>

                  {/* State Section */}
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={2}
                    sx={{
                      display: {
                        xs: "flex",
                      }, // Show in xs, hide in md
                    }}
                  >
                    <TourOutlinedIcon
                      sx={{ color: "#000", mr: 1, width: 25, fontSize: 25 }}
                    />
                    <label style={{ color: "#106995", width: 230 }}>
                      {Labels?.State}
                    </label>
                    <State
                      value={stateId}
                      setValue={setStateId}
                      country={headQuarters}
                      userchangedcountry={userchangedcountry}
                      setuserchangedcountry={setuserchangedcountry}
                      style={{
                        width: "100%",
                        fontSize: "14px",

                        borderColor: "#106995",
                        "& .MuiOutlinedInput-root": {
                          padding: "0px",

                          "& fieldset": {
                            borderColor: "#106995", // Change border color
                          },
                          "&:hover fieldset": {
                            borderColor: "#000000", // Change border color on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#106995", // Change border color when focused
                          },
                        },
                      }}
                    />
                  </Stack>

                  {/* City Section */}
                  <Stack
                    mt={1}
                    sx={{
                      display: {
                        xs: "flex",
                      },
                    }}
                  >
                    <IconLabelTextField
                      icon={
                        <ApartmentIcon
                          sx={{
                            color: "#000",
                          }}
                        />
                      }
                      InputLabel={Labels?.City}
                      label={Labels?.City}
                      name="headQuarterCity"
                      value={employerData?.headQuarterCity || ""}
                      onChange={(e) => handleChange(e)}
                      readOnly={usertype == "Recruiter" ? true : false}
                      style={{
                        width: "100%",
                        marginTop: -0.5,
                        borderColor: "#106995",
                        "& .MuiOutlinedInput-root": {
                          padding: "0px",

                          "& fieldset": {
                            borderColor: "#106995", // Change border color
                          },
                          "&:hover fieldset": {
                            borderColor: "#000000", // Change border color on hover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#106995", // Change border color when focused
                          },
                        },
                      }}
                    />
                  </Stack>
                </>
              )}
              {/* ------------Website--------------- */}
              <IconLabelTextField
                icon={
                  <LanguageIcon
                    sx={{
                      color: "#000000",
                    }}
                  />
                }
                InputLabel={Labels?.Website}
                label={Labels?.Website}
                name="website"
                value={employerData?.website}
                onChange={(e) => handleChange(e)}
                readOnly={usertype === "Recruiter" ? true : false}
                style={{ width: "100%" }}
              />

              {/* ---------------Contact Person Name------------------ */}
              {/* {employerData?.userType === "Recruiter" ? (
                <IconLabelTextField
                  icon={
                    <PersonOutlineOutlinedIcon
                      sx={{
                        color: "#65AC98",
                      }}
                    />
                  }
                  InputLabel={Labels?.ContactName}
                  type="text"
                  label={Labels?.ContactName}
                  name="recruiterName"
                  value={employerData?.recruiterName}
                  onChange={(e) => handleChange(e)}
                  style={{ width: "100%" }}
                />
              ) : (
                <IconLabelTextField
                  icon={
                    <PersonOutlineOutlinedIcon
                      sx={{
                        color: "#65AC98",
                      }}
                    />
                  }
                  InputLabel={Labels?.ContactName}
                  type="text"
                  label={Labels?.ContactName}
                  name="contactName"
                  value={employerData?.contactName}
                  onChange={(e) => handleChange(e)}
                  style={{ width: "100%" }}
                />
              )} */}

              {/* ---------------Contact Person Designation------------------ */}
              {/* <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ my: 1.5 }}
              >
                <BadgeOutlinedIcon
                  sx={{ color: "#65AC98", mr: 1, width: 25, fontSize: 25 }}
                />
                <label style={{ color: "#413E3E", width: 230 }}>
                  {Labels?.designation}
                </label>
                <Designation
                  value={designation}
                  setValue={setDesignation}
                  style={{ width: "100%", fontSize: "14px" }}
                />
              </Stack> */}
              {/* ---------------Contact Person Number------------------ */}
              {/* {employerData?.userType === "Recruiter" ? (
                <IconLabelTextField
                  icon={
                    <ContactPhoneOutlinedIcon
                      sx={{
                        color: "#65AC98",
                      }}
                    />
                  }
                  InputLabel={Labels?.ContactNumber}
                  label={Labels?.ContactNumber}
                  name="recruiterNumber"
                  value={employerData?.recruiterNumber}
                  onChange={(e) => handleChange(e)}
                  style={{ width: "78%" }}
                  display="true"
                  countryCode={countryCode}
                  handleOnChange={handleOnChange}
                />
              ) : (
                <IconLabelTextField
                  icon={
                    <ContactPhoneOutlinedIcon
                      sx={{
                        color: "#65AC98",
                      }}
                    />
                  }
                  InputLabel={Labels?.ContactNumber}
                  label={Labels?.ContactNumber}
                  name="contactNumber"
                  value={employerData?.contactNumber}
                  onChange={(e) => handleChange(e)}
                  style={{ width: "78%" }}
                  display="true"
                  countryCode={countryCode}
                  handleOnChange={handleOnChange}
                />
              )} */}

              {/* ---------------Contact Person EmailID------------------ */}
              {/* <IconLabelTextField
                icon={<EmailOutlinedIcon sx={{ color: "#65AC98" }} />}
                InputLabel={Labels?.emailAddress}
                type="email"
                label={Labels?.emailAddress}
                name="emailid"
                value={employerData.emailid || ""}
                readOnly
                style={{ width: "100%" }}
              /> */}
              {/* ---------------Save button------------------ */}

              {usertype !== "Recruiter" && (
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <CustomButton
                    onClick={profanityCheckerProfile}
                    label={Labels?.Save}
                    style={{ padding: "5px 25px" }}
                    disabled={isButtonDisabled1}
                  />
                </div>
              )}
            </Box>
          </Paper>
          {/* --------------------Slogan-------------------- */}
          <Paper elevation={0} sx={{ my: 2, width: "100%" }}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 600, color: "#106995", p: 1.5 }}
            >
              {Labels?.slogan	}
            </Typography>
            <Divider />
            <Grid container justifyContent="start">
              <Grid item xs={12} md={8} lg={6}>
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      position: "relative",
                      display: "inline-block",
                      mt: 2,
                    }}
                  >
                    <Avatar
                      sx={{
                        width: "100%",
                        height: 120,
                        borderRadius: 0,
                        cursor: usertype !== "Recruiter" ? "pointer" : "cursor",
                        margin: "20px", // Adjust margin as needed
                      }}
                      src={`${employerData?.slogan}`}
                      onClick={() => setOpenSlogan(true)}
                    />
                    {usertype !== "Recruiter" && (
                      <IconButton
                        component="label"
                        sx={{
                          position: "absolute",
                          top: "90%",
                          right: "10px", // Adjust this value as needed
                          transform: "translateY(-50%)",
                          padding: 0.5,
                          background: "white",
                          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                          "&:hover": {
                            background: "white",
                          },
                          "&:active": {
                            backgroundColor: "#005F8E",
                          },
                          "&:disabled": {
                            color: "white",
                            backgroundColor: "#005F8E",
                          },
                        }}
                      >
                        <CameraAltIcon
                          sx={{
                            color: "black",
                          }}
                        />

                        <InputBase
                          type="file"
                          style={{ display: "none" }}
                          accept="image/jpeg, image/png, image/gif"
                          onChange={onChange1}
                        />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Dialog open={selectSlogan} onClose={() => setSelectSolgan(false)}>
              <DialogTitle>
                <Stack direction="row" justifyContent="flex-end">
                  <Close
                    sx={{ cursor: "pointer" }}
                    onClick={() => setSelectSolgan(false)}
                  />
                </Stack>
              </DialogTitle>
              <DialogContent>
                <Box
                  sx={{
                    width: { xs: "60%", sm: "50%", md: "60%", lg: "80%" },
                    height: { xs: "60%", sm: "50%", md: "60%", lg: "80%" },
                  }}
                >
                  <Cropper
                    ref={cropperRef}
                    style={{ height: "100%", width: "100%" }}
                    zoomTo={0.5}
                    initialAspectRatio={1}
                    preview=".img-preview"
                    src={slogan}
                    viewMode={3}
                    minCropBoxHeight={10}
                    minCropBoxWidth={20}
                    background={false}
                    responsive={true}
                    autoCropArea={3}
                    checkOrientation={false}
                    guides={true}
                  />
                </Box>
              </DialogContent>
              <DialogActions sx={{ padding: "25px" }}>
                <CustomButton
                  onClick={handleUploadSlogan}
                  label={Labels.Upload}
                  style={{ padding: "5px 18px" }}
                />
              </DialogActions>
            </Dialog>
          </Paper>
          {/* --------------------About Company-------------------- */}
          <Paper elevation={0} sx={{ my: 2 }}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 600, color: "#106995", p: 1.5 }}
            >
              {Labels?.AboutCompany}
            </Typography>
            <Divider />

            <Box sx={{ p: 1.5 }} className="jobseekerProfile">
              <TextField
                multiline
                rows={4}
                fullWidth
                // label="Enter Company Description Here"
                placeholder={Labels?.DescriptionHere}
                value={
                  employerData?.companyDescription
                    ? employerData?.companyDescription
                    : ""
                }
                variant="outlined"
                name="companyDescription"
                InputProps={{
                  readOnly: usertype === "Recruiter" ? true : false,
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#106995", // Change border color
                    },
                    "&:hover fieldset": {
                      borderColor: "#000000", // Change border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#106995", // Change border color when focused
                    },
                  },
                }}
                onChange={(e) => handleChange(e)}
              />
              {usertype !== "Recruiter" && (
                <div
                  style={{
                    display: "flex",
                    margin: "10px auto",
                    justifyContent: "end",
                  }}
                >
                  <CustomButton
                    onClick={profanityCheckerCompanyDesc}
                    label={Labels?.Save}
                    style={{ padding: "5px 25px" }}
                    disabled={isButtonDisabled3}
                  />
                </div>
              )}
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={5}>
          <Stack
            sx={{
              // height: "120vh",
              overflowY: "auto",
            }}
            className={classes.customColoredScrollbar}
          >
            <Paper elevation={0} sx={{ mb: 2, width: "100%" }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ p: 1.5 }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 600, color: "#106995" }}
                >
                  {Labels?.EnableTwoFactor}
                </Typography>
                <Switch checked={Ischecked} onChange={handleToggle} />
              </Stack>
            </Paper>
            {/* --------------Company branch--------------------- */}
            {employerData.companyTypeKey === "Consultancy" ? null : (
              <Paper elevation={0}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ p: 1.5 }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 600, color: "#106995" }}
                  >
                    {Labels?.CompanyLocation}
                  </Typography>
                  {usertype !== "Recruiter" && (
                    <CustomButton
                      label={Labels?.Add}
                      sx={{
                        color: "rgba(113, 109, 109, 0.9)",
                        cursor: "pointer",
                      }}
                      onClick={() => setopenBranchesModel(true)}
                    />
                  )}
                </Stack>
                <Divider />
                <Box
                  sx={{ p: 1.5, overflow: "auto" }}
                  className="jobseekerProfile"
                >
                  {/* {isLoading ? (
                <Skeleton
                  variant="rectangular"
                  height={38}
                  animation="wave"
                  sx={{ width: "100%", my: 1.5 }}
                />
              ) : ( */}
                  {/* <Stack direction="row" alignItems="center">
                  {Array.isArray(employerData) &&
                    employerData.map((val, index) => (
                    
                      <Chip
                        key={index}
                        label={companyBranches.countryName}
                        sx={{ mr: 1 }}
                        // onDelete={() => deletePreferredLocations(val.jspl_id)}
                      />
                   ))}
                </Stack> */}
                  {/* )}  */}
                  <Box sx={{ p: 1 }} className="jobseekerProfile">
                    <Grid container spacing={2}>
                      {Array.isArray(employerData.companyBranches) &&
                        employerData.companyBranches.map((branch, index) => (
                          <Grid item xs={12} md={6} lg={6}>
                            <Box
                              key={index}
                              sx={{
                                p: 2,
                                border: "1px solid #106995",
                                mb: 2,
                                cursor:
                                  usertype !== "Recruiter"
                                    ? "pointer"
                                    : "default",
                              }}
                              onClick={() => EditcomapnyBranch(branch)}
                            >
                              <Stack
                                direction="row"
                                alignItems="end"
                                justifyContent="flex-end"
                              >
                                {/* <DriveFileRenameOutlineOutlinedIcon
                                  sx={{
                                    cursor: "pointer",
                                    fontsize: "1.2rem",
                                    color: "rgba(0, 0, 0, 0.59)",
                                    mx: 1,
                                  }}np
                                  onClick={() => updateLanguage(val)}
                                /> */}
                                <HighlightOffTwoToneIcon
                                  sx={{
                                    cursor:
                                      usertype !== "Recruiter"
                                        ? "pointer"
                                        : "default",
                                    fontsize: "1.2rem",
                                    color: "#106995",
                                    display:
                                      usertype !== "Recruiter"
                                        ? `block`
                                        : "none",
                                  }}
                                  onClick={(event) => {
                                    event.stopPropagation(); // Prevent event propagationsearch-groupListsearch-groupList
                                    deleteCompanyBranch(branch.branchid);
                                  }}
                                />
                              </Stack>

                              <Stack>
                                <Stack direction="row" spacing={1}>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      color: "rgba(0, 0, 0, 0.59)",
                                    }}
                                  >
                                    {Labels?.Country}:
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color: "rgba(0, 0, 0, 0.59)",
                                      mx: 2,
                                      width: "200px",
                                    }}
                                  >
                                    {branch.countryName}
                                  </Typography>
                                </Stack>
                                <Stack direction="row" spacing={3.5}>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      color: "rgba(0, 0, 0, 0.59)",
                                    }}
                                  >
                                    {Labels?.State}:
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color: "rgba(0, 0, 0, 0.59)",
                                      mx: 2,
                                      width: "200px",
                                    }}
                                  >
                                    {branch.stateName}
                                  </Typography>
                                </Stack>
                                <Stack direction="row" spacing={5}>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      color: "rgba(0, 0, 0, 0.59)",
                                    }}
                                  >
                                    {Labels?.City}:
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color: "rgba(0, 0, 0, 0.59)",
                                      mx: 2,
                                      width: "200px",
                                    }}
                                  >
                                    {branch.city}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </Box>
                          </Grid>
                        ))}
                    </Grid>
                  </Box>
                </Box>
              </Paper>
            )}

            {/* -------------------Miscellaneous----------------------------------------- */}
            <Paper elevation={0} sx={{ mb: 2, my: 2 }}>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 600, color: "#106995", p: 1.5 }}
              >
                {Labels?.Miscellaneous}
              </Typography>
              <Divider />
              <Box sx={{ p: 1.5 }} className="jobseekerProfile">
                <label>{Labels?.Canpeoplecontactyoubymail}</label>
                <YesNo
                  placeHolder={Labels.yesOrNo}
                  value={contactBymail}
                  setValue={setShowEmail}
                />
              </Box>
              <Box sx={{ p: 1.5 }} className="jobseekerProfile">
                <label>{Labels?.Wouldyouliketoshowyourprofile}</label>
                <YesNo
                  placeHolder={Labels.yesOrNo}
                  value={showProfile}
                  setValue={setShowProfile}
                />
              </Box>
              <Box sx={{ p: 1.5 }} className="jobseekerProfile">
                <label>{Labels?.DoyouexpecttoreceiveaCoverletter}</label>
                <YesNo
                  placeHolder={Labels.yesOrNo}
                  value={receiveCoverLetter}
                  setValue={setExpectCoverLetter}
                />
              </Box>
              <Box sx={{ p: 1.5, pb: 3.2 }} className="jobseekerProfile">
                <label>{Labels.JobPostDuration}</label>
                <JobPostingDuration
                  placeholder={Labels.JobPostDuration}
                  value={JobPostDuration}
                  setValue={setJobPostDuration}
                />
                {/* {usertype !== "Recruiter" && ( */}
                <div style={{ display: "flex", justifyContent: "end" }}>
                  <CustomButton
                    onClick={profanityCheckerMiscellaneous}
                    label={Labels?.Save}
                    style={{ padding: "5px 25px", mt: 2 }}
                    disabled={isButtonDisabled5}
                  />
                </div>
                {/* )} */}
              </Box>
            </Paper>

            {/* --------------Subscription --------------------- */}

            <Paper elevation={0}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ p: 1.5 }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ fontWeight: 600, color: "#005F8E" }}
                >
                  {Labels?.Subscriptions}
                </Typography>
              </Stack>
              <Divider />
              <Box sx={{ p: 1.5, opacity: 0.5, pointerEvents: "none" }}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Box
                    sx={{
                      p: 6,
                      border: "1px solid rgba(28, 97, 78, 0.4)",
                      borderRadius: 2,
                    }}
                  />
                  <Box
                    sx={{
                      p: 6,
                      border: "1px solid rgba(28, 97, 78, 0.4)",
                      borderRadius: 2,
                    }}
                  />
                  <Box
                    sx={{
                      p: 6,
                      border: "1px solid rgba(28, 97, 78, 0.4)",
                      borderRadius: 2,
                    }}
                  />
                </Stack>
              </Box>
            </Paper>
            {/* <Paper elevation={0}> */}
            {/* <Grid
                container
                spacing={2}
                justifyContent="space-between"
                flexDirection="row"
                p={2}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ color: "#2B4947", p: 2, fontWeight: 600 }}
                >
                  {Labels?.CompanyLocation}
                </Typography>
              </Grid> */}

            {/* <Divider />
              <Box sx={{ p: 1.5 }} className="jobseekerProfile"> */}
            {/* ------------Country--------------- */}

            {/* <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ my: 1.5 }}
                >
                  <PublicOutlinedIcon
                    sx={{ color: "#65AC98", mr: 1, height: 25, width: 25 }}
                  />
                  <label style={{ color: "#413E3E", width: 230 }}>
                    {Labels?.Country}
                  </label>
                  <RecruiterCountry
                    value={countryId}
                    setValue={setCountryId}
                    setStateId={setStateId}
                    style={{
                      width: "100%",
                      fontSize: "14px",
                    }}
                  />
                </Stack> */}

            {/* ------------State--------------- */}
            {/* <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ my: 1.5 }}
                >
                  <TourOutlinedIcon
                    sx={{ color: "#65AC98", mr: 1, height: 25, width: 25 }}
                  />
                  <label style={{ color: "#413E3E", width: 230 }}>
                    {Labels?.State}
                  </label>
                  <State
                    value={stateId}
                    setValue={setStateId}
                    country={countryId}
                    style={{
                      width: "100%",
                      fontSize: "14px",
                    }}
                  />
                </Stack> */}
            {/* ------------City--------------- */}
            {/* <IconLabelTextField
                  icon={
                    <LocationCityIcon
                      sx={{
                        color: "#65AC98",
                      }}
                    />
                  }
                  InputLabel={Labels?.City}
                  label={Labels?.City}
                  name="city"
                  value={employerData?.city || ""}
                  style={{ width: "100%" }}
                /> */}
            {/* ------------Address--------------- */}
            {/* <IconLabelTextField
                  icon={
                    <HomeOutlinedIcon
                      sx={{
                        color: "#65AC98",
                      }}
                    />
                  }
                  InputLabel={Labels?.Address}
                  label={Labels?.Address}
                  name="address1"
                  value={employerData?.address1 || ""}
                  style={{ width: "100%" }}
                  multiline
                  rows={3}
                /> */}
            {/* ------------Save Button--------------- */}
            {/* {employerData?.userType === "Recruiter" ? null : (
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <CustomButton
                      onClick={updateLocation}
                      label={Labels?.Save}
                      style={{ padding: "5px 25px" }}
                      disabled={isButtonDisabled2}
                    />
                  </div>
                )} */}
            {/* </Box>
            </Paper> */}

            {/* --------------Company additional information --------------------- */}

            {/* <Paper elevation={0} sx={{ my: 2 }}> */}
            {/* <Typography
                variant="subtitle1"
                sx={{ color: "#2B4947", p: 2, fontWeight: 600 }}
              >
                {Labels?.additionalInformation}
              </Typography>
              <Divider /> */}
            {/* <Box sx={{ p: 1.5 }} className="jobseekerProfile"> */}
            {/* ------------Headquarters--------------- */}
            {/* {employerData?.userType === "Recruiter" ? (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ my: 1.5 }}
                  >
                    <PublicOutlinedIcon
                      sx={{ color: "#65AC98", mr: 1, height: 25, width: 25 }}
                    />
                    <label style={{ color: "#413E3E", width: 230 }}>
                      {Labels?.Headquarters}
                    </label>
                    <RecruiterCountry
                      value={headQuarters}
                      setValue={setHeadQuarters}
                      setStateId={setHeadQuarterState}
                      readOnly={usertype === "Recruiter" ? true : false}
                      style={{
                        width: "100%",
                        fontSize: "14px",
                      }}
                    />
                  </Stack>
                ) : (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ my: 1.5 }}
                  >
                    <PublicOutlinedIcon
                      sx={{ color: "#65AC98", mr: 1, height: 25, width: 25 }}
                    />
                    <label style={{ color: "#413E3E", width: 230 }}>
                      {Labels?.Headquarters}
                    </label>
                    <Country
                      value={headQuarters}
                      setValue={setHeadQuarters}
                      setStateId={setHeadQuarterState}
                      readOnly={usertype === "Recruiter" ? true : false}
                      style={{
                        width: "100%",
                        fontSize: "14px",
                      }}
                    />
                  </Stack>
                )} */}
            {/* ------------Headquarters City--------------- */}
            {/* <IconLabelTextField
                  icon={
                    <ApartmentIcon
                      sx={{
                        color: "#65AC98",
                      }}
                    />
                  }
                  InputLabel={Labels?.CompanyHeadquarters}
                  label={Labels?.CompanyHeadquarters}
                  name="headQuarterCity"
                  value={employerData?.headQuarterCity || ""}
                  onChange={(e) => handleChange(e)}
                  readOnly={usertype === "Recruiter" ? true : false}
                  style={{ width: "100%" }}
                /> */}
            {/* ------------Website--------------- */}
            {/* <IconLabelTextField
                  icon={
                    <LanguageIcon
                      sx={{
                        color: "#65AC98",
                      }}
                    />
                  }
                  InputLabel={Labels?.Website}
                  label={Labels?.Website}
                  name="website"
                  value={employerData?.website || ""}
                  onChange={(e) => handleChange(e)}
                  readOnly={usertype === "Recruiter" ? true : false}
                  style={{ width: "100%" }}
                /> */}
            {/* ------------Founded--------------- */}
            {/* <IconLabelTextField
                  icon={
                    <FlagIcon
                      sx={{
                        color: "#65AC98",
                      }}
                    />
                  }
                  InputLabel={Labels?.Founded}
                  label={Labels?.Founded}
                  name="founded"
                  value={employerData?.founded || ""}
                  onChange={(e) => handleChange(e)}
                  readOnly={usertype === "Recruiter" ? true : false}
                  style={{ width: "100%" }}
                /> */}
            {/* ------------companySize--------------- */}
            {/* <IconLabelTextField
                  icon={
                    <AssessmentIcon
                      sx={{
                        color: "#65AC98",
                      }}
                    />
                  }
                  InputLabel={Labels?.CompanySize}
                  label={Labels?.CompanySize}
                  name="companySize"
                  value={employerData?.companySize || ""}
                  onChange={(e) => handleChange(e)}
                  readOnly={usertype === "Recruiter" ? true : false}
                  style={{ width: "100%" }}
                /> */}
            {/* ------------Save Button--------------- */}
            {/* {employerData?.userType === "Recruiter" ? null : (
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <CustomButton
                      onClick={updateCompanyAdditionalInformations}
                      label={Labels?.Save}
                      style={{ padding: "5px 25px" }}
                      disabled={isButtonDisabled4}
                    />
                  </div>
                )} */}
            {/* </Box>
            </Paper> */}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
