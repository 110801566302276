import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import "../Landinpage1/Style.css";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "white" : "white",
    border: "0.5px solid #005F8E",
    borderColor: "#005F8E",
    padding: "10px 12px",
    fontSize: " 14px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['"Poppins"'].join(","),
    "&:focus": {
      border: "0.5px solid #005F8E",
      boxShadow: "#005F8E",
      borderColor: "#005F8E",
    },
  },
}));
const textStyle = {};

function InputWithLabel({
  labelStyle,
  InputLabel,
  label,
  style,
  type,
  name,
  value,
  onChange,
  disabled,
  inputProps,
  onKeyDown,
  required,
  errorMsg,
  multiline,
  rows,
  display,
  ref
}) {
  return (
    <div
      style={
        display ? { paddingLeft: "5px", width: "100%" } : { margin: "10px 5px" }
      }
    >
      {display ? null : <label style={labelStyle}>{InputLabel}</label>}
      {required ? <span style={{ color: "red" }}>* </span> :  null}
      {/* <br /> */}
      <BootstrapInput
        type={type}
        sx={{ ...textStyle, ...style }}
        placeholder={label}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        inputProps={inputProps}
        onKeyDown={onKeyDown}
        multiline={multiline}
        rows={rows}
        ref={ref}
      />
      <br />
      {errorMsg
        ? errorMsg && (
            <span style={{ color: "#ff0000", fontSize: "13px" }}>
              {errorMsg}
            </span>
          )
        : null}
    </div>
  );
}

export default InputWithLabel;
