import React, { useState, useEffect } from "react";
import { axiosAPI } from "../../Axios/Axios";
import { useNavigate } from "react-router-dom";
import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import CustomButton from "../../Components/CustomButton";
import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
  parseISO,
} from "date-fns";
import {
  errorNotify,
  infoNotify,
  useStyles,
} from "../../CommonCode/Commonfunc";
import { useSelector } from "react-redux";
import TextfieldCustom from "../../Components/TextfieldCustom";

function MyForumList() {
  const Labels = useSelector((state) => state.allLabels.labels);
  const classes = useStyles();
  const redirect = useNavigate();
  // -------------------- Employer Data --------------------
  const employerLoginData = useSelector(
    (state) => state.createLoginDetails.loginDetails
  );
  const resettedByAdmin = localStorage.getItem("resettedByAdmin");

  const [employerData, setEmployerData] = useState({});
  const getEmployerData = async () => {
    const postData = {
      userid: employerLoginData.uld_id,
    };
    const result = await axiosAPI.post("/employerProfile", postData);
    const { success, data, message } = result.data;
    if (success) {
      setEmployerData(data);
    } else {
      //// console.log(message);
    }
  };

  // --------------- Popular Forums--------------------
  const [popularForum, setPopularForum] = useState([]);
  const [view2, setview2] = useState(false);

  const getPopularForum = async () => {
    try {
      const result = await axiosAPI.post("/getPopularList", { type: "forum" });
      const { data, status } = result.data;
      if (status) {
        setPopularForum(data);
        setview2(true);
      } else {
        setview2(true);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };

  // --------------- My Forums--------------------
  const [myForum, setMyForum] = useState([]);
  const [view, setview] = useState(false);
  const getMyForum = async () => {
    try {
      const result = await axiosAPI.post("/getMyForumList");
      const { data, status } = result.data;
      if (status) {
        setMyForum(data);
        setview(true);
      } else {
        setview(true);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };
  // ---------------Search Forums--------------------
  const [searchKeyword, setSearchKeyword] = useState("");
  const searchForums = async () => {
    if (!searchKeyword) {
      getMyForum();
    } else {
      try {
        const result = await axiosAPI.post("searchForums", {
          search: searchKeyword,
          type: "myForum",
        });
        const { data, status, message } = result.data;
        if (status) {
          setMyForum(data);
        } else {
          infoNotify(message);
        }
      } catch (error) {
        //// console.log(error.meesage);
      }
    }
  };
  // -------------------- Time Difference --------------------
  const getTimeDifference = (createdOn) => {
    const dateNow = new Date();
    const createdDate = parseISO(createdOn);

    const diffInSeconds = differenceInSeconds(dateNow, createdDate);
    if (diffInSeconds < 60) {
      return `${diffInSeconds} ${Labels?.secAgo}`;
    }

    const diffInMinutes = differenceInMinutes(dateNow, createdDate);
    if (diffInMinutes < 60) {
      return `${diffInMinutes} ${Labels?.minAgo}`;
    }

    const diffInHours = differenceInHours(dateNow, createdDate);
    if (diffInHours < 24) {
      return `${diffInHours} ${Labels?.hourAgo}`;
    }

    const diffInDays = differenceInDays(dateNow, createdDate);
    if (diffInDays < 30) {
      return `${diffInDays}  ${Labels?.daysago}`;
    }

    const diffInMonths = differenceInMonths(dateNow, createdDate);
    return `${diffInMonths} ${Labels?.monthAgo}`;
  };

  useEffect(() => {
    getMyForum();
    getEmployerData();
    window.scrollTo(0, 0);
    getPopularForum();
  }, []);

  const viewDetailedForum = (id) => {
    redirect(`/myForum-detail/${id}`);
  };
  const viewDetailedPopularForum = (id) => {
    redirect(`/forum-detail/${id}`);
  };
  return (
    <>
      {/* changed grid posioyn----------------------------------- */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} md={7} lg={8} xl={9}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" sx={{ color: "#005F8E" }}>
              {Labels?.MyForums}
            </Typography>
            <CustomButton
              onClick={() => redirect("/create-forum")}
              label={Labels?.CreateForum}
              style={{ borderRadius: "4px" }}
            />
          </Stack>

          <Stack
            direction={{ xs: "column", sm: "row" }}
            sx={{ alignItems: "center" }}
          >
            <TextfieldCustom
              label={Labels?.searchForumName}
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
            />
            <CustomButton
              label={Labels?.search}
              style={{ borderRadius: "10px", margin: "10px" }}
              onClick={searchForums}
            />
            <CustomButton
              label={Labels?.Reset}
              style={{ borderRadius: "10px", margin: "10px",  background: "white",
                color: "#005F8E",
                border: "2px solid #005F8E",
                "&:hover": {
                  backgroundColor: "white",
                  color: "#005F8E",
                  border: "2px solid #005F8E",
                },
                "&:active": {
                  backgroundColor: "white",
                  color: "#005F8E",
                  border: "2px solid #005F8E",
                },
                "&:disabled": { color: "white", backgroundColor: "#005F8E" } }}
              onClick={() => {
                getMyForum();
                setSearchKeyword("");
              }}
            />
          </Stack>
          {/* changed grid posioyn----------------------------------- */}
          {/* <Grid container spacing={2}>
        <Grid item xs={12} lg={8}> */}
          {view ? (
            myForum.length > 0 ? (
              myForum.map((item, i) => (
                <Paper
                  elevation={0}
                  sx={{ p: 2, my: 1 }}
                  onClick={() => viewDetailedForum(item.forumID)}
                  key={item.forumID}
                >
                  <Stack direction="row" spacing={2}>
                    <Avatar
                      src={`${item.logo}`}
                      sx={{
                        maxWidth: 35,
                        maxHeight: 35,
                      }}
                    />
                    <Typography
                      variant="subtitle1"
                      sx={{ color: "#005F8E", fontWeight: 600 }}
                    >
                      {item.forumName}
                    </Typography>
                    {item.statusKey == "Expired" ||
                    item.statusKey == "In-Active" ||
                    item.statusKey == "Closed" ? (
                      <Typography
                        variant="caption"
                        sx={{
                          color: "#ED4C5C",
                          background: "rgb(255, 246, 247)",
                          p: 0.75,
                          borderRadius: 1,
                        }}
                      >
                        {item.status}
                      </Typography>
                    ) : null}
                  </Stack>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      fontWeight: 300,
                      color: "#716D6DD9",
                      my: 0.5,
                      textAlign: "justify",
                    }}
                  >
                    {item.description.length > 75
                      ? item.description.slice(0, 75) + "..."
                      : item.description}
                  </Typography>

                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                  >
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Avatar
                        src={`${item.userLogo}`}
                        sx={{
                          maxWidth: 24,
                          maxHeight: 24,
                        }}
                      />
                      <Typography variant="caption">
                        {Labels?.postedBy} {Labels?.you}
                      </Typography>
                      <Typography variant="caption">
                        {item.createdOn}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          color: "#1976D2",
                          background: "#F3FBFF",
                          p: 0.75,
                          borderRadius: 1,
                        }}
                      >
                        {item?.forumTopic
                          ? item?.forumTopic
                          : item?.categoryName}
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
              ))
            ) : (
              <Typography variant="body1" sx={{ p: 1 }}>
                {Labels?.noForumFound}
              </Typography>
            )
          ) : (
            <>
              <Skeleton variant="rectangular" width={`100%`} height={100} />
              <br />
              <Skeleton variant="rectangular" width={`100%`} height={100} />
              <br />
              <Skeleton variant="rectangular" width={`100%`} height={100} />
              <br />
              <Skeleton variant="rectangular" width={`100%`} height={100} />
              <br />
              <Skeleton variant="rectangular" width={`100%`} height={100} />
              <br />
              <Skeleton variant="rectangular" width={`100%`} height={100} />
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={4} md={5} lg={4} xl={3}>
          <Card
            sx={{
              borderRadius: 1,
              padding: 0,
              boxShadow: "none",
            }}
          >
            <Stack sx={{ p: 2 }}>
              <Typography
                variant="subtitle1"
                sx={{ color: "#005F8E", fontWeight: 500 }}
              >
                {Labels?.popularForum}
              </Typography>
            </Stack>
            <Divider />
            {view2 ? (
              <Box
                sx={{ height: { xs: "50vh", md: "70vh" }, overflow: "auto" }}
                className={classes.customColoredScrollbar}
              >
                {popularForum.length ? (
                  popularForum.map((item, i) => (
                    <Card
                      sx={{
                        borderRadius: 1,
                        boxShadow: "none",
                        borderBottom: "1px solid #005F8E33",
                        cursor: "pointer",
                        p: 1,

                        "&:hover": {
                          backgroundColor: "#ffffffe5",
                        },
                      }}
                      key={item.id}
                      onClick={() =>
                        employerData.userid !== item.ownerid
                          ? viewDetailedPopularForum(item.id)
                          : viewDetailedForum(item.id)
                      }
                    >
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                      >
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Avatar
                            src={`${item.frmLogoPath}`}
                            alt=" "
                            style={{
                              maxWidth: 30,
                              maxHeight: 30,
                            }}
                          />
                          <Stack>
                            <Typography
                              variant="subtitle1"
                              sx={{ color: "#000", fontWeight: 600 }}
                            >
                              {item.forumName}
                            </Typography>
                            <Stack direction="row" alignItems="center">
                              <Typography
                                variant="body2"
                                sx={{ color: "#005F8E" }}
                              >
                                {item?.forumTopic
                                  ? item?.forumTopic
                                  : item?.categoryName}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Stack>
                        {/* -------------------removed time--------------------------e                */}
                        {/* <Stack>
                      <Typography variant="caption">
                        {getTimeDifference(item.createdOn)}
                      </Typography>
                    </Stack> */}
                      </Stack>
                    </Card>
                  ))
                ) : (
                  <Typography
                    variant="subtitle1"
                    sx={{ color: "#2B4947", fontWeight: 600, margin: "20px" }}
                  >
                    {Labels.noForumFound}
                  </Typography>
                )}
              </Box>
            ) : (
              <>
                <Skeleton
                  variant="rounded"
                  width={`95%`}
                  height={70}
                  style={{ margin: "5px auto" }}
                />
                <Skeleton
                  variant="rounded"
                  width={`95%`}
                  height={70}
                  style={{ margin: "5px auto" }}
                />
                <Skeleton
                  variant="rounded"
                  width={`95%`}
                  height={70}
                  style={{ margin: "5px auto" }}
                />
                <Skeleton
                  variant="rounded"
                  width={`95%`}
                  height={70}
                  style={{ margin: "5px auto" }}
                />
                <Skeleton
                  variant="rounded"
                  width={`95%`}
                  height={70}
                  style={{ margin: "5px auto" }}
                />
                <Skeleton
                  variant="rounded"
                  width={`95%`}
                  height={70}
                  style={{ margin: "5px auto" }}
                />
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default MyForumList;
