import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoginImage from "../../Images/LoginImage.png";
import HomeIcon from "@mui/icons-material/Home";
import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import ForgotPasswordModel from "../Jobseeker/Login/ForgotPasswordModel";
import "../Landinpage1/Style.css";
import NaranjoLogo from "../../Images/logo1 (1).png"
import { styled } from "@mui/system";
import Group1524 from "../../Images/Group 1524.png";
import { useSelector } from "react-redux";
import { Tabs } from "@mui/base/Tabs";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import { TabPanel as BaseTabPanel } from "@mui/base/TabPanel";
import { buttonClasses } from "@mui/base/Button";
import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";
import JobseekerLogin from "./JobseekerLogin";
import EmployerLogin from "./EmployerLogin";
import logo from "../../Images/fifyPluslogo.png";
import './Social.css'

const Login = () => {
  const redirect = useNavigate();
  const location = useLocation();
  const Labels = useSelector((state) => state.allLabels.labels);
  const [initialTab, setInitialTab] = useState(
    location?.state ? location?.state : 1
  );
  return (
    <Grid container direction="row" height="100vh">
      {/* Left side: Login Form */}
      <Grid item xs={12} md={6} sx={{ backgroundColor: "#D2F0FF", display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
        <Stack width="100%">
        <Stack direction="row" justifyContent="flex-end" sx={{display: { xs: 'flex', md: 'none' },}}> 
          <IconButton
            sx={{
              color: "#136E9B",
              height: "100%",
              background: "rgba(0, 0, 0, 0.04)",
            }}
            aria-label="delete"
            onClick={() => redirect("/landingpage")}
          >
            <HomeIcon />
          </IconButton>
        </Stack>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            height="90%"
            spacing={5}
            sx={{ marginBottom: 20 }}          >
            <img
              src={NaranjoLogo}
              alt="logo"
              width="85px"
              style={{
                cursor: "pointer",
              }}
              onClick={() => redirect("/landingpage")}
            />
            <Box sx={{ minWidth: "45%", p: 2 }}>
              <Tabs defaultValue={initialTab}>
                <TabsList>
                  <Tab value={1}>{Labels?.JobSeeker}</Tab>
                  <Tab value={2}>{Labels?.Employer}</Tab>
                </TabsList>
                <TabPanel value={1}>
                  <JobseekerLogin />
                </TabPanel>
                <TabPanel value={2}>
                  <EmployerLogin />
                </TabPanel>
              </Tabs>
            </Box>
          </Stack>
        </Stack>
      </Grid>

      {/* Right side: Image */}
      <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: 'column', justifyContent: 'space-between' }}>
        <Stack direction="row" justifyContent="flex-end" sx={{ p: 2 }}>
          <IconButton
            sx={{
              color: "#136E9B",
              background: "rgba(0, 0, 0, 0.04)",
            }}
            aria-label="home"
            onClick={() => redirect("/landingpage")}
          >
            <HomeIcon />
          </IconButton>
        </Stack>
        <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ flex: 1,mt:-8}}>
          <Grid item>
            <img
              src={Group1524}
              className="loginImg"
              alt="loginImage"
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
              }}
            />
          </Grid>
          <Grid item>
  <Typography variant="h5" align="center" sx={{ mt: 2, color: "#136E9B", fontWeight: 600, fontSize: 20 }}>
    <Box component="span">{Labels?.FindYourDreamJobiNOnePlace	}</Box>
    {/* <Box component="span" display="block">One Place</Box> */}
  </Typography>
</Grid>

        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;
const grey = {
  50: "#F3F6F9",
  100: "#136E9B",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#136E9B",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#136E9B",
  800: "#303740",
  900: " #136E9B",
};

const Tab = styled(BaseTab)`
  font-family: "IBM Plex Sans", sans-serif;
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 10px 12px;
  margin: 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  &:focus {
    color: #fff;
  }

  &.${tabClasses.selected} {
    background-color: #fff;
   color: #136E9B;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(BaseTabPanel)(
  ({ theme }) => `
  width: auto;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  padding: 20px 12px;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid #136E9B ;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  `
);

const TabsList = styled(BaseTabsList)(
  ({ theme }) => `
  width: 100%;
  background-color: rgba(0, 0, 0, 0.54);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  box-shadow: 0px 4px 30px ${theme.palette.mode === "dark" ? grey[900] : grey[200]
    };
  `
);
