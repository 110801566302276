import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  Chip,
  Paper,
  Stack,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Skeleton,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import React from "react";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import Grid from "@mui/material/Grid";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import ShareIcon from "@mui/icons-material/Share";
import { useEffect, useRef } from "react";
import { axiosAPI } from "../../Axios/Axios";
import { useState } from "react";
import {
  errorNotify,
  infoNotify,
  succesNotify,
} from "../../CommonCode/Commonfunc";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { PUBLIC_IMAGE_FOLDER } from "../../Axios/Constant";
import { useStyles } from "../../CommonCode/Commonfunc";
import { useSelector } from "react-redux";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import UndoIcon from "@mui/icons-material/Undo";
import PlaceIcon from "@mui/icons-material/Place";
import TextfieldCustom from "../../Components/TextfieldCustom";
import CustomButton from "../../Components/CustomButton";
import JobDetailedViewPostLogin from "./JobDetailedViewPostLogin";
import { makeStyles } from "@mui/styles";
import { profanityfn } from "../../Profanity/Profanity";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const NewJobSearchPostLogin = () => {
  const location = useLocation();
  const postData = location.state;
  const [jobList, setJobList] = useState([]);
  const [jobId, setjobId] = useState("");
  const [view, setView] = useState("module");
  const [jobDetail, setJobDetail] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [searchResult, setSearchResult] = useState("");
  const [refreshState, setRefreshState] = useState(false);
  const cardRefs = useRef([]);

  useEffect(() => {
    let jobString = "";
    if (postData?.jobsearch?.length) jobString += ` ${postData.jobsearch}`;
    if (postData?.city?.length) jobString += `, ${postData.city}`;
    if (postData?.radius?.length)
      jobString += `, ${postData.radius}km ${Labels.Radius}`;

    setSearchResult(jobString);
  }, [postData]);

  const handleCardClick = (val, index) => {
    setSelectedJobId(val.eja_id);
    if (cardRefs.current[index]) {
      cardRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    setExpanded(false);
  };

  const savefn = async (data) => {
    const postdata1 = {
      language: "en",
      jobid: data.eja_id,
      type: data.save_status ? "unsave" : "save",
      language: localStorage.getItem("language"),
    };

    try {
      const result = await axiosAPI.post("/saveJob", postdata1);
      const { status, message } = result.data; // Destructure status and message
      if (status === true) {
        succesNotify(message); // Notify success
        getJobList(); // Refresh job list
        setRefreshState(!refreshState);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };

  const handleLinkClick = (jobid) => {
    const email = ""; // Replace with the recipient's email address
    const subject = "Job Application";
    const body = `Job Link : https://50plus.zone/detailedJobView/${jobid}`;

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const handleChange = (event, nextView) => {
    if (nextView !== null) {
      setView(nextView);
    }

    if (jobDetail == true) {
      setJobDetail(false);
      setjobId("");
    }
  };

  const [loading, setloading] = useState(true);
  const Labels = useSelector((state) => state.allLabels.labels);

  const getJobList = async () => {
    if (postData?.jobsearch === undefined) {
      try {
        const result = await axiosAPI.post("/searchjobs", { jobsearch: "" });
        const { data, success } = result.data;
        if (success === true) {
          setJobList(data);
          setloading(false);
        } else {
          setJobList([]);
          setloading(false);
        }
      } catch (error) {
        //// console.log(error.message);
      }
    } else {
      try {
        const result = await axiosAPI.post("/searchjobs", postData);
        const { data, success } = result.data;
        if (success === true) {
          setJobList(data);
          setloading(false);
        } else {
          setloading(false);
        }
      } catch (error) {
        //// console.log(error.message);
      }
    }
  };

  useEffect(() => {
    getJobList();
  }, [postData, Labels]);

  const viewDetailedJob = (id) => {
    setJobDetail(true);
    setjobId(id);
  };

  const containerRef = useRef(null);
  const selectedCardRef = useRef(null);

  useEffect(() => {
    if (selectedCardRef.current && containerRef.current) {
      const containerTop = containerRef.current.getBoundingClientRect().top;
      const cardTop = selectedCardRef.current.getBoundingClientRect().top;
      containerRef.current.scrollTo({
        top: selectedCardRef.current.offsetTop - containerTop,
        behavior: "smooth",
      });
    }
  }, [selectedJobId]);

  return (
    <>
      {/* Top job length display + toggle view button stack */}
      <Stack
        direction="row"
        spacing={3}
        justifyContent="space-between"
        sx={{ my: 1 }}
      >
        <Stack direction="row" spacing={4} justifyContent="space-between">
          <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography variant="body1" sx={{ color: "#005F8E" }}>
              {Labels?.Showing}
            </Typography>
            <Typography variant="body1" sx={{ color: "#000" }}>
              {jobList.length}
            </Typography>
            <Typography variant="body1" sx={{ color: "#005F8E" }}>
              {jobList.length == 1 ? Labels?.Job : Labels?.Jobs}
            </Typography>
            <Typography variant="body1" sx={{ color: "#005F8E" }}>
              {searchResult}
            </Typography>
          </Stack>
        </Stack>
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={handleChange}
          sx={{ display: { xs: "none", md: "block" } }}
        >
          {jobDetail ? null : (
            <ToggleButton value="module" aria-label="module">
              <ViewModuleIcon />
            </ToggleButton>
          )}

          {jobDetail ? null : (
            <ToggleButton value="list" aria-label="list">
              <ViewListIcon />
            </ToggleButton>
          )}

          {jobDetail ? (
            <ToggleButton value="module" aria-label="list">
              <UndoIcon />
            </ToggleButton>
          ) : null}
        </ToggleButtonGroup>
      </Stack>
      {/* Top job length display + toggle view button stack */}

      {/* ________________________________________________________________________________________________________________________________________ */}

      <Grid container spacing={2} sx={{ padding: "12px" }}>
        <Grid
          item
          xs={12}
          sx={{
            display: {
              xs: "block",
              sm: "block",
              md: "none",
              lg: "none",
              xl: "none",
            },
          }}
        >
          <Accordion expanded={expanded}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              {Labels.viewAll} {Labels.JobsList}
            </AccordionSummary>
            <AccordionDetails sx={{ background: "rgb(234,239,241)" }}>
              <Grid
                item
                xs={12}
                sm={jobDetail ? 12 : 12}
                md={jobDetail ? 6 : 12}
                lg={jobDetail ? 6 : 12}
                xl={jobDetail ? 4 : 12}
              >
                <div style={{ position: "relative" }}>
                  <Grid
                    container
                    spacing={2}
                    className="scrollable-container-Jobsearchpostlogin"
                    ref={containerRef}
                    style={{ height: jobDetail ? "85vh" : `100%` }}
                  >
                    {loading ? (
                      <>
                        <Grid
                          item
                          xs={12}
                          sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                          md={jobDetail ? 12 : view == "module" ? 6 : 12}
                          lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={`100%`}
                            style={{
                              minHeight: view == "module" ? "350px" : "100px",
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                          md={jobDetail ? 12 : view == "module" ? 6 : 12}
                          lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={`100%`}
                            style={{
                              minHeight: view == "module" ? "350px" : "100px",
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                          md={jobDetail ? 12 : view == "module" ? 6 : 12}
                          lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={`100%`}
                            style={{
                              minHeight: view == "module" ? "350px" : "100px",
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                          md={jobDetail ? 12 : view == "module" ? 6 : 12}
                          lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={`100%`}
                            style={{
                              minHeight: view == "module" ? "350px" : "100px",
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                          md={jobDetail ? 12 : view == "module" ? 6 : 12}
                          lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={`100%`}
                            style={{
                              minHeight: view == "module" ? "350px" : "100px",
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                          md={jobDetail ? 12 : view == "module" ? 6 : 12}
                          lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={`100%`}
                            style={{
                              minHeight: view == "module" ? "350px" : "100px",
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                          md={jobDetail ? 12 : view == "module" ? 6 : 12}
                          lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={`100%`}
                            style={{
                              minHeight: view == "module" ? "350px" : "100px",
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                          md={jobDetail ? 12 : view == "module" ? 6 : 12}
                          lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={`100%`}
                            style={{
                              minHeight: view == "module" ? "350px" : "100px",
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                          md={jobDetail ? 12 : view == "module" ? 6 : 12}
                          lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={`100%`}
                            style={{
                              minHeight: view == "module" ? "350px" : "100px",
                            }}
                          />
                        </Grid>
                      </>
                    ) : (
                      jobList &&
                      jobList.map((val, i) => (
                        <Grid
                          item
                          xs={12}
                          sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                          md={jobDetail ? 12 : view == "module" ? 6 : 12}
                          lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                          key={i}
                          ref={
                            selectedJobId === val.eja_id
                              ? selectedCardRef
                              : null
                          }
                        >
                          <Card
                            sx={{
                              backgroundColor:
                                selectedJobId === val.eja_id ? "#C0EAFF" : "",
                              borderRadius: "17px",
                              boxShadow: "none",
                              cursor: "pointer",
                              display: "flex",
                              // justifyContent: "space-between",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "10px 5px",
                              position: "relative",
                              border: "1px solid grey",
                              minHeight: view == "module" ? "200px" : "200px",
                            }}
                            // onClick={() => {
                            //   handleCardClick(val);
                            // }}
                          >
                            {(val.status == "Applied" || val.status == 4) && (
                              <span class={`stamp-${view} is-applied`}>
                                {Labels.Applied}
                              </span>
                            )}
                            {val.status == "Shortlisted" && (
                              <span class={`stamp-${view} is-shortlisted`}>
                                {Labels.Shortlisted}
                              </span>
                            )}
                            {/* {val.status == "Open"  && 
                                  <Button
                                  variant="text"
                                  onClick={() => redirect(`/detailedJobView/${val.eja_id}`)}
                                  sx={{
                                    textTransform: "capitalize",
                                    color: "black",
                                    padding: "5px 10px",
                                    borderRadius: "10px",
                                    border:"2px solid grey",
                                    position:"absolute",
                                    right:"20px",
                                    top:"50px",
                                    zIndex:"99999"
                                  }}
                                >
                                    {Labels?.View}
                                  </Button>
                                  } */}

                            <Grid container spacing={2} direction="row">
                              <Grid
                                sx={{
                                  width: "90%",
                                  margin: "auto",
                                  position: "absolute",
                                  top: "10px",
                                  left: "25px",
                                }}
                                container
                                direction="row"
                                justifyContent="space-between"
                              >
                                <Grid item direction="row">
                                  <ShareIcon
                                    onClick={() => {
                                      handleLinkClick(val.eja_id);
                                      handleCardClick(val);
                                    }}
                                    sx={{
                                      color: "#005F8E",
                                      cursor: "pointer",
                                    }}
                                  />
                                </Grid>
                                <Grid item direction="row">
                                  {val.save_status === 0 ? (
                                    <BookmarkBorderOutlinedIcon
                                      onClick={() => {
                                        savefn(val);
                                      }}
                                      sx={{
                                        color: "#005F8E",
                                        cursor: "pointer",
                                      }}
                                    />
                                  ) : null}
                                  {val.save_status === 1 ? (
                                    <BookmarkAddedIcon
                                      onClick={() => {
                                        savefn(val);
                                      }}
                                      sx={{
                                        color: "#005F8E",
                                        cursor: "pointer",
                                      }}
                                    />
                                  ) : null}
                                </Grid>
                              </Grid>

                              {/* Avatar */}
                              <Grid
                                item
                                xs={3}
                                onClick={() => {
                                  viewDetailedJob(`${val.eja_id}`);
                                  handleCardClick(val);
                                }}
                                sm={jobDetail ? 3 : view == "module" ? 3 : 2}
                                md={jobDetail ? 3 : view == "module" ? 3 : 2}
                                lg={jobDetail ? 3 : view == "module" ? 3 : 2}
                                sx={{ position: "relative", top: "30px" }}
                                direction="row"
                              >
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  height="100%"
                                >
                                  <Avatar
                                    src={
                                      val.companyLogo
                                        ? `${val.companyLogo}`
                                        : null
                                    }
                                    alt=" "
                                    style={{
                                      minWidth: 60,
                                      minHeight: 60,
                                      margin: "25px",
                                      borderRadius: 5,
                                      border: "1px solid #EAEAEA",
                                    }}
                                  >
                                    {val.companyLogo ? null : (
                                      <>
                                        {val?.jobName
                                          ? val.jobName
                                              .split(" ")
                                              .map((word) =>
                                                word[0].toUpperCase()
                                              )
                                              .join("")
                                          : null}
                                      </>
                                    )}
                                  </Avatar>
                                </Box>
                              </Grid>

                              {/* Job details */}
                              <Grid
                                item
                                xs={9}
                                onClick={() => {
                                  viewDetailedJob(`${val.eja_id}`);
                                  handleCardClick(val);
                                }}
                                sm={jobDetail ? 9 : view == "module" ? 9 : 4}
                                md={jobDetail ? 9 : view == "module" ? 9 : 4}
                                lg={jobDetail ? 9 : view == "module" ? 9 : 4}
                                sx={{ position: "relative", top: "30px" }}
                                //   onClick={() =>
                                //     redirect(`/detailedJobView/${val.eja_id}`)
                                //   }
                              >
                                <Box
                                  sx={{
                                    direction: "row",
                                    Width: 100,
                                  }}
                                >
                                  <Typography
                                    variant="h6"
                                    sx={{ color: "#000000", fontWeight: 600 }}
                                  >
                                    {val.jobName}
                                  </Typography>
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ color: "#000000" }}
                                  >
                                    {val.employer}
                                  </Typography>
                                  <Typography variant="body2">
                                    {val.city}
                                  </Typography>

                                  <Typography variant="body2">
                                    {val.days}
                                  </Typography>
                                  <Typography variant="body2">
                                    {val.jobseekers}{" "}
                                    {val.jobseekers?.length == 1
                                      ? Labels?.Applicant
                                      : Labels?.Applicants}
                                  </Typography>
                                </Box>
                              </Grid>

                              {/* Job Box */}
                              <Grid
                                item
                                xs={12}
                                onClick={() => {
                                  viewDetailedJob(`${val.eja_id}`);
                                  handleCardClick(val);
                                }}
                                sm={jobDetail ? 12 : view == "module" ? 12 : 6}
                                md={jobDetail ? 12 : view == "module" ? 12 : 5}
                                lg={jobDetail ? 12 : view == "module" ? 12 : 5}
                                mt={2}
                                //   onClick={() =>redirect(`/detailedJobView/${val.eja_id}`)}
                              >
                                <Box
                                  sx={{
                                    position: "relative", // Ensure the parent container is a positioning context
                                    display: "flex",
                                    paddingRight: 4,
                                    minHeight: "0px", // Set a minimum height to create space for other content
                                  }}
                                >
                                  <Grid item xs={4} direction="column">
                                    {val.jobTypes ? (
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={1}
                                        mx={1}
                                        my={2}
                                      >
                                        {val.jobTypes
                                          .split(",")
                                          .map((jobType, i) => (
                                            <Chip
                                              key={jobType}
                                              label={jobType}
                                              size="small"
                                              sx={{
                                                fontWeight:
                                                  selectedJobId === val.eja_id
                                                    ? 600
                                                    : 400,
                                                backgroundColor:
                                                  selectedJobId === val.eja_id
                                                    ? "#C0EAFF"
                                                    : "#F3FBFF",
                                                // backgroundColor:
                                                // "#F3FBFF",
                                                color: "#005F8E",
                                                // background: "#FFFFFF",
                                                fontSize: "12px",
                                                borderRadius: 1,
                                                border: "1px solid #005F8E",
                                                width: "100px", // Set your desired fixed width
                                                whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                                wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                              }}
                                            />
                                          ))
                                          .slice(0, 2)}
                                      </Stack>
                                    ) : null}

                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={1}
                                      mx={1}
                                      my={2}
                                    >
                                      <Chip
                                        key={""}
                                        label={`${val?.typePercentage}0%`}
                                        size="small"
                                        sx={{
                                          fontWeight:
                                            selectedJobId === val.eja_id
                                              ? 600
                                              : 400,
                                          backgroundColor:
                                            selectedJobId === val.eja_id
                                              ? "#C0EAFF"
                                              : "#F3FBFF",
                                          // backgroundColor:
                                          // "#F3FBFF",
                                          color: "#005F8E",
                                          // background: "#FFFFFF",
                                          fontSize: "12px",
                                          borderRadius: 1,
                                          border: "1px solid #005F8E",
                                          width: "100px", // Set your desired fixed width
                                          whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                          wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                        }}
                                      />
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={4} direction="column">
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={1}
                                      mx={1}
                                      my={2}
                                      // style={{ border: '1px solid red' }}
                                    >
                                      <Chip
                                        // key={index}
                                        label={val?.officePresence}
                                        size="small"
                                        sx={{
                                          fontWeight:
                                            selectedJobId === val.eja_id
                                              ? 600
                                              : 400,
                                          backgroundColor:
                                            selectedJobId === val.eja_id
                                              ? "#C0EAFF"
                                              : "#F3FBFF",
                                          // backgroundColor:
                                          // "#F3FBFF",
                                          color: "#005F8E",
                                          // background: "#FFFFFF",
                                          fontSize: "12px",
                                          borderRadius: 1,
                                          border: "1px solid #005F8E",
                                          width: "100px", // Set your desired fixed width
                                          whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                          wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                        }}
                                      />
                                    </Stack>

                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={1}
                                      mx={1}
                                      my={2}
                                    >
                                      <Chip
                                        key={""}
                                        label={
                                          val.officePercentage == 100
                                            ? `${val.officePercentage}%`
                                            : val.officePercentage == 0
                                            ? `${val.remotePercentage}%`
                                            : val.officePercentage > 0 &&
                                              val.officePercentage < 100
                                            ? `${val.officePercentage}% (${Labels.Office})`
                                            : null
                                        }
                                        size="small"
                                        sx={{
                                          fontWeight:
                                            selectedJobId === val.eja_id
                                              ? 600
                                              : 400,
                                          backgroundColor:
                                            selectedJobId === val.eja_id
                                              ? "#C0EAFF"
                                              : "#F3FBFF",
                                          // backgroundColor:
                                          // "#F3FBFF",
                                          color: "#005F8E",
                                          // background: "#FFFFFF",
                                          fontSize: "12px",
                                          borderRadius: 1,
                                          border: "1px solid #005F8E",
                                          width: "100px", // Set your desired fixed width
                                          whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                          wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                        }}
                                      />
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={4} direction="column">
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={1}
                                      mx={1}
                                      my={2}
                                    >
                                      <Chip
                                        key={""}
                                        label={`${val?.workingHours} hrs/${val?.hourPeriod}`}
                                        size="small"
                                        sx={{
                                          fontWeight:
                                            selectedJobId === val.eja_id
                                              ? 600
                                              : 400,
                                          backgroundColor:
                                            selectedJobId === val.eja_id
                                              ? "#C0EAFF"
                                              : "#F3FBFF",
                                          // backgroundColor:
                                          // "#F3FBFF",
                                          color: "#005F8E",
                                          // background: "#FFFFFF",
                                          fontSize: "12px",
                                          borderRadius: 1,
                                          border: "1px solid #005F8E",
                                          width: "100px", // Set your desired fixed width
                                          whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                          wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                        }}
                                      />
                                    </Stack>

                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={1}
                                      mx={1}
                                      my={2}
                                    >
                                      <Chip
                                        key={""}
                                        label={`${val?.salary} ${val?.currency}/${val?.salaryPeriod} `}
                                        size="small"
                                        sx={{
                                          fontWeight:
                                            selectedJobId === val.eja_id
                                              ? 600
                                              : 400,
                                          backgroundColor:
                                            selectedJobId === val.eja_id
                                              ? "#C0EAFF"
                                              : "#F3FBFF",
                                          // backgroundColor:
                                          // "#F3FBFF",
                                          color: "#005F8E",
                                          // background: "#FFFFFF",
                                          fontSize: "12px",
                                          borderRadius: 1,
                                          border: "1px solid #005F8E",
                                          width: "100px", // Set your desired fixed width
                                          whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                          wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                        }}
                                      />
                                    </Stack>
                                  </Grid>
                                </Box>
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>
                      ))
                    )}
                  </Grid>
                </div>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container spacing={2}>
            <Grid
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                  xl: "block",
                },
              }}
              item
              xs={12}
              sm={jobDetail ? 12 : 12}
              md={jobDetail ? 6 : 12}
              lg={jobDetail ? 6 : 12}
              xl={jobDetail ? 4 : 12}
            >
              <div style={{ position: "relative" }}>
                <Grid
                  container
                  spacing={2}
                  className="scrollable-container-Jobsearchpostlogin"
                  ref={containerRef}
                  style={{ height: jobDetail ? "85vh" : `100%` }}
                >
                  {loading ? (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                        md={jobDetail ? 12 : view == "module" ? 6 : 12}
                        lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                      >
                        <Skeleton
                          variant="rectangular"
                          width={`100%`}
                          style={{
                            minHeight: view == "module" ? "350px" : "100px",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                        md={jobDetail ? 12 : view == "module" ? 6 : 12}
                        lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                      >
                        <Skeleton
                          variant="rectangular"
                          width={`100%`}
                          style={{
                            minHeight: view == "module" ? "350px" : "100px",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                        md={jobDetail ? 12 : view == "module" ? 6 : 12}
                        lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                      >
                        <Skeleton
                          variant="rectangular"
                          width={`100%`}
                          style={{
                            minHeight: view == "module" ? "350px" : "100px",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                        md={jobDetail ? 12 : view == "module" ? 6 : 12}
                        lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                      >
                        <Skeleton
                          variant="rectangular"
                          width={`100%`}
                          style={{
                            minHeight: view == "module" ? "350px" : "100px",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                        md={jobDetail ? 12 : view == "module" ? 6 : 12}
                        lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                      >
                        <Skeleton
                          variant="rectangular"
                          width={`100%`}
                          style={{
                            minHeight: view == "module" ? "350px" : "100px",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                        md={jobDetail ? 12 : view == "module" ? 6 : 12}
                        lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                      >
                        <Skeleton
                          variant="rectangular"
                          width={`100%`}
                          style={{
                            minHeight: view == "module" ? "350px" : "100px",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                        md={jobDetail ? 12 : view == "module" ? 6 : 12}
                        lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                      >
                        <Skeleton
                          variant="rectangular"
                          width={`100%`}
                          style={{
                            minHeight: view == "module" ? "350px" : "100px",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                        md={jobDetail ? 12 : view == "module" ? 6 : 12}
                        lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                      >
                        <Skeleton
                          variant="rectangular"
                          width={`100%`}
                          style={{
                            minHeight: view == "module" ? "350px" : "100px",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                        md={jobDetail ? 12 : view == "module" ? 6 : 12}
                        lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                      >
                        <Skeleton
                          variant="rectangular"
                          width={`100%`}
                          style={{
                            minHeight: view == "module" ? "350px" : "100px",
                          }}
                        />
                      </Grid>
                    </>
                  ) : (
                    jobList &&
                    jobList.map((val, i) => (
                      <Grid
                        item
                        xs={12}
                        sm={jobDetail ? 12 : view == "module" ? 6 : 12}
                        md={jobDetail ? 12 : view == "module" ? 6 : 12}
                        lg={jobDetail ? 12 : view == "module" ? 4 : 12}
                        key={i}
                        ref={
                          selectedJobId === val.eja_id ? selectedCardRef : null
                        }
                      >
                        <Card
                          sx={{
                            backgroundColor:
                              selectedJobId === val.eja_id ? "#C0EAFF" : "",
                            borderRadius: "17px",
                            boxShadow: "none",
                            border: "1px solid #EAEAEA",
                            // cursor: "pointer",
                            display: "flex",
                            // justifyContent: "space-between",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "5px",
                            position: "relative",
                            bottom:
                              i == 1 && jobList.length == 2 && jobDetail
                                ? "100px"
                                : null,
                            minHeight: view == "module" ? "270px" : "150px",
                            overflow: "none",
                          }}
                         
                        >
                          {(val.status == "Applied" || val.status == 4) && (
                            <span class={`stamp-${view} is-applied`}>
                              {Labels.Applied}
                            </span>
                          )}
                          {val.status == "Shortlisted" && (
                            <span class={`stamp-${view} is-shortlisted`}>
                              {Labels.Shortlisted}
                            </span>
                          )}

                          <Grid container spacing={2} direction="row">
                            <Grid
                              sx={{
                                width: "90%",
                                margin: "auto",
                                position: "absolute",
                                top: "20px",
                                left: "25px",
                                zIndex: "100",
                              }}
                              container
                              direction="row"
                              justifyContent="flex-end"
                            >
                              <Grid item direction="row">
                                <ShareIcon
                                  onClick={() => {
                                    handleLinkClick(val.eja_id);
                                  }}
                                  sx={{
                                    color: "#005F8E",
                                    cursor: "pointer",
                                  }}
                                />
                              </Grid>
                              <Grid item direction="row">
                                {val.save_status === 0 ? (
                                  <BookmarkBorderOutlinedIcon
                                    onClick={() => {
                                      savefn(val);
                                    }}
                                    sx={{
                                      color: "#005F8E",
                                      cursor: "pointer",
                                    }}
                                  />
                                ) : null}
                                {val.save_status === 1 ? (
                                  <BookmarkAddedIcon
                                    onClick={() => {
                                      savefn(val);
                                    }}
                                    sx={{
                                      color: "#005F8E",
                                      cursor: "pointer",
                                    }}
                                  />
                                ) : null}
                              </Grid>
                            </Grid>

                            {/* Avatar */}
                            <Grid
                              item
                              xs={3}
                              onClick={() => {
                                viewDetailedJob(`${val.eja_id}`);
                                handleCardClick(val);
                              }}
                              sm={jobDetail ? 3 : view == "module" ? 3 : 2}
                              md={jobDetail ? 3 : view == "module" ? 3 : 2}
                              lg={jobDetail ? 3 : view == "module" ? 3 : 2}
                              sx={{
                                position: "relative",
                                zIndex: "1",
                                cursor: "pointer",
                                top:
                                  view == "module"
                                    ? "30px"
                                    : jobDetail
                                    ? "20px"
                                    : "0px",
                              }}
                              direction="row"
                            >
                              <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                height="100%"
                              >
                                <Avatar
                                  src={
                                    val.companyLogo
                                      ? `${val.companyLogo}`
                                      : null
                                  }
                                  alt=" "
                                  style={{
                                    minWidth: 60,
                                    minHeight: 60,
                                    margin: "25px",
                                    borderRadius: 5,
                                    border: "1px solid #EAEAEA",
                                  }}
                                >
                                  {val.companyLogo ? null : (
                                    <>
                                      {val?.jobName
                                        ? val.jobName
                                            .split(" ")
                                            .map((word) =>
                                              word[0].toUpperCase()
                                            )
                                            .join("")
                                        : null}
                                    </>
                                  )}
                                </Avatar>
                              </Box>
                            </Grid>

                            {/* Job details */}
                            <Grid
                              item
                              xs={9}
                              onClick={() => {
                                viewDetailedJob(`${val.eja_id}`);
                                handleCardClick(val);
                              }}
                              sm={jobDetail ? 9 : view == "module" ? 9 : 4}
                              md={jobDetail ? 9 : view == "module" ? 9 : 4}
                              lg={jobDetail ? 9 : view == "module" ? 9 : 4}
                              sx={{
                                cursor: "pointer",
                                position: "relative",
                                zIndex: "1",
                                top:
                                  view == "module"
                                    ? "30px"
                                    : jobDetail
                                    ? "20px"
                                    : "0px",
                              }}
                              //   onClick={() =>
                              //     redirect(`/detailedJobView/${val.eja_id}`)
                              //   }
                            >
                              <Box
                                sx={{
                                  direction: "row",
                                  Width: 100,
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  sx={{ color: "#000", fontWeight: 600 }}
                                >
                                  {val.jobName}
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  sx={{ color: "#000" }}
                                >
                                  {val.employer}
                                </Typography>
                                <Typography variant="body2">
                                  {val.city}
                                </Typography>

                                <Typography variant="body2">
                                  {val.days}
                                </Typography>
                                <Typography variant="body2">
                                  {val.jobseekers}{" "}
                                  {val.jobseekers == 1
                                    ? Labels?.Applicant
                                    : Labels?.Applicants}
                                </Typography>
                              </Box>
                            </Grid>

                            {/* Job Box */}
                            <Grid
                              item
                              xs={12}
                              onClick={() => {
                                viewDetailedJob(`${val.eja_id}`);
                                handleCardClick(val);
                              }}
                              sm={jobDetail ? 12 : view == "module" ? 12 : 6}
                              md={jobDetail ? 12 : view == "module" ? 12 : 5}
                              lg={jobDetail ? 12 : view == "module" ? 12 : 5}
                              sx={{ zIndex: "1", cursor: "pointer" }}
                              mt={2}
                              //   onClick={() =>redirect(`/detailedJobView/${val.eja_id}`)}
                            >
                              <Box
                                sx={{
                                  position: "relative", // Ensure the parent container is a positioning context
                                  display: "flex",
                                  paddingRight: 4,
                                  minHeight: "0px", // Set a minimum height to create space for other content
                                }}
                              >
                                <Grid item xs={4} direction="column">
                                  {val.jobTypes ? (
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={1}
                                      mx={1}
                                      my={2}
                                    >
                                      {val.jobTypes
                                        .split(",")
                                        .map((jobType, i) => (
                                          <Chip
                                            key={jobType}
                                            label={jobType}
                                            size="small"
                                            sx={{
                                              fontWeight:
                                                selectedJobId === val.eja_id
                                                  ? 600
                                                  : 400,
                                              backgroundColor:
                                                selectedJobId === val.eja_id
                                                  ? "#C0EAFF"
                                                  : "#F3FBFF",
                                              // backgroundColor:
                                              // "#F3FBFF",
                                              color: "#005F8E",
                                              // background: "#FFFFFF",
                                              fontSize: "12px",
                                              borderRadius: 1,
                                              border: "1px solid #005F8E",
                                              width: "100px", // Set your desired fixed width
                                              whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                              wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                            }}
                                          />
                                        ))
                                        .slice(0, 2)}
                                    </Stack>
                                  ) : null}

                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                    mx={1}
                                    my={2}
                                  >
                                    <Chip
                                      key={""}
                                      label={`${val?.typePercentage}0%`}
                                      size="small"
                                      sx={{
                                        fontWeight:
                                          selectedJobId === val.eja_id
                                            ? 600
                                            : 400,
                                        backgroundColor:
                                          selectedJobId === val.eja_id
                                            ? "#C0EAFF"
                                            : "#F3FBFF",
                                        // backgroundColor:
                                        // "#F3FBFF",
                                        color: "#005F8E",
                                        // background: "#FFFFFF",
                                        fontSize: "12px",
                                        borderRadius: 1,
                                        border: "1px solid #005F8E",
                                        width: "100px", // Set your desired fixed width
                                        whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                        wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                      }}
                                    />
                                  </Stack>
                                </Grid>
                                <Grid item xs={4} direction="column">
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                    mx={1}
                                    my={2}
                                    // style={{ border: '1px solid red' }}
                                  >
                                    <Chip
                                      // key={index}
                                      label={val?.officePresence}
                                      size="small"
                                      sx={{
                                        fontWeight:
                                          selectedJobId === val.eja_id
                                            ? 600
                                            : 400,
                                        backgroundColor:
                                          selectedJobId === val.eja_id
                                            ? "#C0EAFF"
                                            : "#F3FBFF",
                                        // backgroundColor:
                                        // "#F3FBFF",
                                        color: "#005F8E",
                                        // background: "#FFFFFF",
                                        fontSize: "12px",
                                        borderRadius: 1,
                                        border: "1px solid #005F8E",
                                        width: "100px", // Set your desired fixed width
                                        whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                        wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                      }}
                                    />
                                  </Stack>

                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                    mx={1}
                                    my={2}
                                  >
                                    <Chip
                                      key={""}
                                      // label={`${val.officePercentage}%`}
                                      label={
                                        val.officePercentage == 100
                                          ? `${val.officePercentage}%`
                                          : val.officePercentage == 0
                                          ? `${val.remotePercentage}%`
                                          : val.officePercentage > 0 &&
                                            val.officePercentage < 100
                                          ? `${val.officePercentage}% (${Labels.Office})`
                                          : null
                                      }
                                      size="small"
                                      sx={{
                                        fontWeight:
                                          selectedJobId === val.eja_id
                                            ? 600
                                            : 400,
                                        backgroundColor:
                                          selectedJobId === val.eja_id
                                            ? "#C0EAFF"
                                            : "#F3FBFF",
                                        // backgroundColor:
                                        // "#F3FBFF",
                                        color: "#005F8E",
                                        // background: "#FFFFFF",
                                        fontSize: "12px",
                                        borderRadius: 1,
                                        border: "1px solid #005F8E",
                                        width: "100px", // Set your desired fixed width
                                        whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                        wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                      }}
                                    />
                                  </Stack>
                                </Grid>
                                <Grid item xs={4} direction="column">
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                    mx={1}
                                    my={2}
                                  >
                                    <Chip
                                      key={""}
                                      label={`${val?.workingHours} hrs/${val?.hourPeriod}`}
                                      size="small"
                                      sx={{
                                        fontWeight:
                                          selectedJobId === val.eja_id
                                            ? 600
                                            : 400,
                                        backgroundColor:
                                          selectedJobId === val.eja_id
                                            ? "#C0EAFF"
                                            : "#F3FBFF",
                                        // backgroundColor:
                                        // "#F3FBFF",
                                        color: "#005F8E",
                                        // background: "#FFFFFF",
                                        fontSize: "12px",
                                        borderRadius: 1,
                                        border: "1px solid #005F8E",
                                        width: "100px", // Set your desired fixed width
                                        whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                        wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                      }}
                                    />
                                  </Stack>

                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                    mx={1}
                                    my={2}
                                  >
                                    <Chip
                                      key={""}
                                      label={`${val?.salary} ${val?.currency}/${val?.salaryPeriod} `}
                                      size="small"
                                      sx={{
                                        fontWeight:
                                          selectedJobId === val.eja_id
                                            ? 600
                                            : 400,
                                        backgroundColor:
                                          selectedJobId === val.eja_id
                                            ? "#C0EAFF"
                                            : "#F3FBFF",
                                        // backgroundColor:
                                        // "#F3FBFF",
                                        color: "#005F8E",
                                        // background: "#FFFFFF",
                                        fontSize: "12px",
                                        borderRadius: 1,
                                        border: "1px solid #005F8E",
                                        width: "100px", // Set your desired fixed width
                                        whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                        wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                      }}
                                    />
                                  </Stack>
                                </Grid>
                              </Box>
                            </Grid>
                          </Grid>
                        </Card>
                      </Grid>
                    ))
                  )}
                </Grid>
              </div>
            </Grid>

            {jobDetail && jobList.length ? (
              <Grid item xs={12} sm={12} md={6} lg={6} xl={8}>
                <JobDetailedViewPostLogin
                  jobid={jobId}
                  refreshState={refreshState}
                  savefn={savefn}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default NewJobSearchPostLogin;
