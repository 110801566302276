import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { useSelector } from "react-redux";
import {profanityfn}  from "../Profanity/Profanity";

const WorkHour = ({ value, setValue, style,ref  }) => {
  const [WorkHour, setWorkHour] = useState([]);
  useEffect(() => {
    const getWorkHour = async () => {
      const result = await axiosAPI.get("/getWorkHour");
      const { success, data } = await result.data;
      if (success === true) {
        setWorkHour(data);
      } else {
        setWorkHour([]);
      }
    };
    getWorkHour();
    
  }, []);

  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)

  const handleChange = (event) => {
    setValue(profanityfn(ProfanityList,event.target.value))
  };

  
  return(
    <FormControl sx={{ width: "100%" }}>
       
       
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={value}
      onChange={handleChange}
      fullWidth
      displayEmpty
      ref={ref}
      variant="outlined"
      sx={{position:"relative", left:"2px", padding: "10px 12px",fontSize:"14px", fontFamily: "Poppins", borderColor: "#005F8E", // Change border color
        
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: '#005F8E',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#005F8E',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#000000',
        }, ...style }}
    >
      {WorkHour &&
        WorkHour.map((val, index) => {
          return (
            <MenuItem key={index} value={val.id}>
              {val.name}
            </MenuItem>
          );
        })}
    </Select>
  </FormControl>
    
  );
};

  export default WorkHour;
  