import {
  Box,
  Divider,
  Avatar,
  Chip,
  Grid,
  Paper,
  Stack,
  Typography,
  Button,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {useNavigate, useParams } from "react-router-dom";
import { axiosAPI } from "../../Axios/Axios";
import { errorNotify } from "../../CommonCode/Commonfunc";
import { useSelector } from "react-redux";

export default function JobInfo({ setJobSeekerListPage }) {
  const { id } = useParams();
  const redirect = useNavigate();
  const [jobDetails, setJobDetails] = useState({});
  const [loading, setloading] = useState(true);
  const getjobdetails = async () => {
    if (id) {
      const result = await axiosAPI.post(`/getJobInfo?&&jobid=${id}`);
      const { success, data, message } = result.data;
      if (result.data.success == undefined) {
        redirect("/employerJobList");
      }
      if (success === true) {
        setJobDetails(data);
        setloading(false);
      } else {
        setloading(false);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getjobdetails();
    setJobSeekerListPage(5);
  }, []);
  const Labels = useSelector((state) => state.allLabels.labels);

  const daysRemaining = jobDetails?.remainDays;


  return (
    <>
      {loading ? (
        <Paper elevation={0} sx={{ p: 2 }}>
          <Skeleton
            variant="rounded"
            width={`100%`}
            height={300}
            style={{ margin: `20px auto` }}
          />
          <Skeleton variant="rounded" width={`100%`} height={300} />
        </Paper>
      ) : (
        <Paper elevation={0} sx={{ p: 2 }}>
          
          <Grid container spacing={2}>
            {/* Logo Grid */}
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "top",
                  height: "100%",
                }}
              >
                <Avatar
                  src={
                    jobDetails.companyLogo ? `${jobDetails.companyLogo}` : null
                  }
                  style={{ width: 150, height: 160 }}
                >
                  {jobDetails.companyLogo ? null : (
                    <>
                      {jobDetails?.jobName
                        ? jobDetails.jobName
                            .split(" ")
                            .map((word) => word[0].toUpperCase())
                            .join("")
                        : null}
                    </>
                  )}
                </Avatar>
              </Box>
            </Grid>
            {/* Content Grid */}
            <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
              <Box>
                <Typography
                  variant="h5"
                  sx={{ color: "#005F8E", fontWeight: 600 }}
                >
                  {jobDetails?.jobName}
                </Typography>
                <Typography
                  variant="subtilte1"
                  sx={{ fontSize: "1.2rem", cursor: "pointer" }}
                >
                  {jobDetails?.employer}
                </Typography>

                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "1.2rem",
                    color: "#808080",
                    margin: 0,
                  }}
                >
                  {jobDetails?.companyAddress}, {jobDetails?.country}
                  &nbsp;
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "1.1rem", color: "#808080" }}
                >
                  {jobDetails?.createdOn} - {jobDetails?.jobseekers}{" "}
                  {jobDetails?.jobseekers == 1
                    ? Labels?.Applicant
                    : Labels?.Applicants}
                  <br />({" "}
                  <span
                    style={{
                      color:
                        daysRemaining && daysRemaining < 5 ? "red" : "#808080",
                    }}
                  >
                    {daysRemaining}{" "}
                    {daysRemaining == 1
                      ? Labels.dayRemaining
                      : Labels.daysRemaining}
                  </span>{" "}
                  )
                </Typography>
              </Box>

              <Box>
                <Stack direction="row" spacing={1}>
                  <Grid container direction="row">
                    <Grid item xs={12} sm={4} direction="column" sx={{ margin: { xs: "15px auto" } }}>
                      {jobDetails.jobTypes ? (
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          mx={1}
                          //   my={2}
                        >
                          {jobDetails.jobTypes
                            .split(",")
                            .map((jobType, i) => (
                              <Chip
                                key={jobType}
                                label={jobType}
                                size="medium"
                                sx={{
                                  color: "#005F8E",
                                  background: "#F3FBFF",
                                  borderRadius: 1,
                                  width: "100%",
                                  fontSize: "1.1rem",
                                  margin: "10px 12px 10px 0",
                                  border: "1px solid #005F8E",
                                }}
                              />
                            ))
                            .slice(0, 2)}
                        </Stack>
                      ) : null}

                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        mx={1}
                        // my={2}
                      >
                        <Chip
                          key={""}
                          label={`${jobDetails?.typePercentage}0%`}
                          size="medium"
                          sx={{
                            color: "#005F8E",
                            background: "#F3FBFF",
                            borderRadius: 1,
                            width: "100%",
                            fontSize: "1.1rem",
                            margin: "10px 12px 10px 0",
                            border: "1px solid #005F8E",
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={4} direction="column" sx={{ margin: { xs: "15px auto" } }}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        mx={1}
                        // my={2}
                        // style={{ border: '1px solid red' }}
                      >
                        <Chip
                          // key={index}
                          label={jobDetails?.officePresenceName}
                          size="medium"
                          sx={{
                            color: "#005F8E",
                            background: "#F3FBFF",
                            borderRadius: 1,
                            width: "100%",
                            fontSize: "1.1rem",
                            margin: "10px 12px 10px 0",
                            border: "1px solid #005F8E",
                          }}
                        />
                      </Stack>

                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        mx={1}
                        // my={2}
                      >
                        <Chip
                          key={""}
                          label={
                            jobDetails.officePercentage == 100
                              ? `${jobDetails.officePercentage}%`
                              : jobDetails.officePercentage == 0
                              ? `${jobDetails.remotePercentage}%`
                              : jobDetails.officePercentage > 0 &&
                                jobDetails.officePercentage < 100
                              ? `${jobDetails.officePercentage}% (${Labels.Office})`
                              : null
                          }
                          size="medium"
                          sx={{
                            color: "#005F8E",
                            background: "#F3FBFF",
                            borderRadius: 1,
                            width: "100%",
                            fontSize: "1.1rem",
                            margin: "10px 12px 10px 0",
                            border: "1px solid #005F8E",
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={4} direction="column" sx={{ margin: { xs: "15px auto" } }}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        mx={1}
                        // my={2}
                      >
                        <Chip
                          key={""}
                          label={`${jobDetails?.workingHours} hrs/${jobDetails?.hourPeriod}`}
                          size="medium"
                          sx={{
                            color: "#005F8E",
                            background: "#F3FBFF",
                            borderRadius: 1,
                            width: "100%",
                            fontSize: "1.1rem",
                            margin: "10px 12px 10px 0",
                            border: "1px solid #005F8E",
                          }}
                        />
                      </Stack>

                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        mx={1}
                        // my={2}
                      >
                        <Chip
                          key={""}
                          label={`${jobDetails?.salary} ${jobDetails?.currencySymbol}/${jobDetails?.salaryPeriodName} `}
                          size="medium"
                          sx={{
                            color: "#005F8E",
                            background: "#F3FBFF",
                            borderRadius: 1,
                            width: "100%",
                            fontSize: "1.1rem",
                            margin: "10px 12px 10px 0",
                            border: "1px solid #005F8E",
                          }}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
                <hr />
                <Stack>
                  <Typography fontSize="1.2rem" my={2}>
                    {jobDetails?.seniorityLevel}
                  </Typography>

                  <Grid container spacing={2}>
                    {jobDetails?.travelPercentage?.length ? (
                      <>
                        <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                          <Typography> {Labels.Travelling} </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                          <Typography>:</Typography>
                        </Grid>
                        <Grid item xs={7} sm={7} md={7} lg={8} xl={9}>
                          <Typography>
                            {jobDetails?.travelPercentage} %
                          </Typography>
                        </Grid>
                      </>
                    ) : null}

                    {/* ___________________________________________- */}

                    {jobDetails?.companySize?.length ? (
                      <>
                        <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                          <Typography> {Labels?.Employees} </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                          <Typography>:</Typography>
                        </Grid>
                        <Grid item xs={7} sm={7} md={7} lg={8} xl={9}>
                          <Typography>{jobDetails?.companySize}</Typography>
                        </Grid>
                      </>
                    ) : null}

                    {/* ___________________________________________- */}

                    {jobDetails?.qualifications?.length ? (
                      <>
                        <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                          <Typography> {Labels.Required} </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                          <Typography>:</Typography>
                        </Grid>
                        <Grid item xs={7} sm={7} md={7} lg={8} xl={9}>
                          <Typography fontSize="1.2rem">
                            {jobDetails.qualifications &&
                              Array.isArray(jobDetails.qualifications) &&
                              jobDetails.qualifications.map((data, index) => (
                                <>
                                  {" "}
                                  {data.educationName}
                                  {index !==
                                    jobDetails.qualifications.length - 1 && ","}
                                </>
                              ))}
                          </Typography>
                        </Grid>
                      </>
                    ) : null}

                    {/* ___________________________________________- */}
                    {jobDetails?.additionalQualification && (
                      <>
                        <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                          <Typography > {Labels?.Nicetohave} </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                          <Typography>:</Typography>
                        </Grid>
                        <Grid item xs={7} sm={7} md={7} lg={8} xl={9}>
                          <Typography>
                            {jobDetails.additionalQualification}
                          </Typography>
                        </Grid>
                      </>
                    )}

                    {/* ___________________________________________- */}
                  </Grid>
                </Stack>
                <hr />
                <Stack spacing={2} direction="row">
                  <Stack direction="column" sx={{ width: "100%" }}>
                    <Box sx={{ flexGrow: 1, width: "100%" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Typography sx={{ fontWeight: "bold" }} variant="p">
                            {Labels.languageRequired}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography sx={{ fontWeight: "bold" }} variant="p">
                            {Labels.Spoken}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography sx={{ fontWeight: "bold" }} variant="p">
                            {Labels.Written}
                          </Typography>
                        </Grid>

                        {/* Table Rows */}
                        {jobDetails?.languages?.map((lang, index) => (
                          <React.Fragment key={index}>
                            <Grid item xs={4}>
                              <Typography>{lang.language}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography>{lang.spoken}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography>{lang.written}</Typography>
                            </Grid>
                          </React.Fragment>
                        ))}
                      </Grid>
                    </Box>

                    <Stack direction="row">
                      {jobDetails.additionalLanguage?.length ? (
                        <>
                          <Typography my={2} fontSize="1.1rem" fontWeight={500}>
                            {Labels?.Nicetohave}:&nbsp;
                          </Typography>

                          {Array.isArray(jobDetails.additionalLanguage) &&
                            jobDetails.additionalLanguage.map((data, index) => (
                              <Typography my={2} fontSize="1.1rem" key={index}>
                                &nbsp;{data.language1}
                                {index !==
                                  jobDetails?.additionalLanguage.length - 1 &&
                                  ","}
                                &nbsp;
                              </Typography>
                            ))}
                        </>
                      ) : null}
                    </Stack>
                  </Stack>
                </Stack>
                <hr />
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    my: 2,
                    flexDirection: {
                      xs: "column",
                      md: "row",
                    },
                  }}
                ></Stack>
              </Box>

              <Box>
                {/* --------------------------------who is hiring-------------------------- */}
                {jobDetails.recruiterName != null ? (
                  <Stack>
                    <Box
                      sx={{
                        margin: "15px 10px",
                      }}
                    >
                      {" "}
                      <Typography
                        sx={{
                          fontWeight: 600,
                        }}
                      >
                        {Labels?.WhoisHiring}
                      </Typography>
                    </Box>

                    <Stack direction="row">
                      <Box
                        sx={{
                          minHeight: "80px",
                          maxHeight: "80px",
                          overflowY: "hidden",
                          margin: "10px",
                        }}
                      >
                        <Avatar
                          src={
                            jobDetails.recruiterLogo
                              ? `${jobDetails.recruiterLogo}`
                              : null
                          }
                          alt=" "
                          style={{ width: 70, height: 70, margin: "10px" }}
                        />
                      </Box>

                      <Box
                        sx={{
                          minHeight: "80px",
                          maxHeight: "80px",
                          overflowY: "hidden",
                          margin: "15px",
                        }}
                      >
                        {jobDetails?.recruiterName != null ? (
                          <Typography
                            variant="subtitle1"
                            sx={{
                              color: "#2B4947",
                              fontWeight: 600,
                              // color: jobId === val.eja_id ? "#fff" : "#000",
                            }}
                          >
                            {jobDetails?.recruiterName?.length > 20
                              ? jobDetails?.recruiterName.slice(0, 20) + "..."
                              : jobDetails?.recruiterName}
                          </Typography>
                        ) : null}
                        {jobDetails.designation != null ? (
                          <Typography
                            variant="body1"
                            sx={{
                              color: "#2B4947",
                              // fontWeight: 600,
                              // color: jobId === val.eja_id ? "#fff" : "#000",
                            }}
                          >
                            {jobDetails?.designation?.length > 20
                              ? jobDetails?.designation.slice(0, 20) + "..."
                              : jobDetails?.designation}
                          </Typography>
                        ) : null}
                      </Box>
                    </Stack>
                  </Stack>
                ) : null}
                {/* -------------------- Category -------------------- */}

                <Box
                  sx={{
                    margin: "15px 10px",
                  }}
                >
                  <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                    {Labels?.categories}
                  </Typography>
                  <Chip
                    label={jobDetails?.jobCategoryName}
                    size="medium"
                    sx={{
                      color: "#005F8E",
                      background: "#F3FBFF",
                      borderRadius: 1,
                      minWidth: 120,
                      fontSize: "1.1rem",
                      margin: "10px 12px 10px 0",
                      border: "1px solid #005F8E",
                    }}
                  />
                </Box>
                {/* -------------------- Job Description -------------------- */}
                {jobDetails.Jobinformations?.length ? (
                  <Box
                    sx={{
                      margin: "15px 10px",
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                      {Labels?.jobDescription}
                    </Typography>

                    {jobDetails?.whatYouWillDo ? (
                      <div>
                        <Typography
                          sx={{ fontWeight: 500 }}
                          variant="subtitle1"
                        >
                          {Labels.Whatyouwilldo}
                        </Typography>
                        <Typography variant="body2">
                          {jobDetails.whatYouWillDo}
                        </Typography>
                      </div>
                    ) : null}

                    {jobDetails?.whoWeWouldLikeToHave ? (
                      <div>
                        <Typography
                          sx={{ fontWeight: 500 }}
                          variant="subtitle1"
                        >
                          {Labels.WhoWeWouldLikeToHave}
                        </Typography>
                        <Typography variant="body2">
                          {jobDetails.whoWeWouldLikeToHave}
                        </Typography>
                      </div>
                    ) : null}

                    {jobDetails?.weWorkWith ? (
                      <div>
                        <Typography
                          sx={{ fontWeight: 500 }}
                          variant="subtitle1"
                        >
                          {Labels.WeWorkWith}
                        </Typography>
                        <Typography variant="body2">
                          {jobDetails.weWorkWith}
                        </Typography>
                      </div>
                    ) : null}

                    {jobDetails?.WouldBeAdvantageToHave ? (
                      <div>
                        <Typography
                          sx={{ fontWeight: 500 }}
                          variant="subtitle1"
                        >
                          {Labels.WouldBeAdvantageToHave}
                        </Typography>
                        <Typography variant="body2">
                          {jobDetails.WouldBeAdvantageToHave}
                        </Typography>
                      </div>
                    ) : null}
                  </Box>
                ) : null}

                <Divider />
                {jobDetails?.companyName && (
                  <Box sx={{ margin: "15px 10px" }}>
                    <Typography
                      sx={{ margin: "15px auto 0px", fontWeight: 500 }}
                      variant="subtitle1"
                    >
                      {Labels.Hiringfor} {jobDetails?.companyName}
                    </Typography>
                  </Box>
                )}

                {jobDetails?.Experience && (
                  <Box
                    sx={{
                      margin: "15px 10px",
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                      {Labels?.Experience}
                    </Typography>
                    <Typography variant="body1" sx={{ my: 2 }}>
                      {jobDetails?.experianceYears}
                    </Typography>
                  </Box>
                )}

                {jobDetails?.CompanyLink && (
                  <Box
                    sx={{
                      margin: "15px 10px",
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      {Labels?.CompanyLink}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#000000A1" }}>
                      {jobDetails.companyLink}&nbsp;
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
}
