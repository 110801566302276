import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
  Skeleton,
  Chip,
} from "@mui/material";
import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
  parseISO,
} from "date-fns";
import React, { useEffect, useState } from "react";
import JobSeekerNavbar from "../JobSeekerNavbar";
import { axiosAPI } from "../../../Axios/Axios";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import NotificationImportantOutlinedIcon from "@mui/icons-material/NotificationImportantOutlined";
import JobSeekerSidebar from "../JobSeekerSidebar";
import { errorNotify, useStyles } from "../../../CommonCode/Commonfunc";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import PublicIcon from "@mui/icons-material/Public";
import { PUBLIC_IMAGE_FOLDER } from "../../../Axios/Constant";
import dummyLogo from "../../../../Images/dummyLogo.png";
import { useNavigate } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EmployerPasswordChangeModal from "../../../Employer/EmployerProfile/EmployerPasswordChangeModal";
import MFAPopup from "../../../CommonCode/MFA/MFAPopup";
import { useScreenWidth } from "../../../CommonCode/ScreenWidth/useScreenWidth";

export default function JobseekerDashboard({ routeChange }) {
  const [recentJobs, setRecentJobs] = useState([]);
  const [isLoding, setIsLoding] = useState(true);
  const redirect = useNavigate();
  const classes = useStyles();
  // const [resettedByAdmin, setResettedByAdmin] = useState("")
  const [openPasswordReset, setopenPasswordReset] = useState(false);
  const data = useSelector(
    (state) => state.getJobseekerProfileData.jobseekerDetails
  );

  useEffect(() => {
    const postdata = {
      jobseekerid: uid,
    };

    const getprofile = async () => {
      const result = await axiosAPI.post("/profile", postdata);
      const { success, data } = result.data;
    };

    getprofile();
  }, []);

  const resettedByAdmin = localStorage.getItem("resettedByAdmin");
  const uid = localStorage.getItem("uid");
  const postdata = {
    jobseekerid: uid,
  };

  const [loading, setloading] = useState(true);
  const [appliedjobcount, setajcount] = useState(0);
  const getRecentJobs = async () => {
    try {
      setIsLoding(true);
      if (uid) {
        const result = await axiosAPI.post("/getAppliedJobs", postdata);
        const { success, data } = result.data;
        if (success) {
          setajcount(data?.applied_Jobs?.length + data?.deleted_Jobs?.length);
          setRecentJobs(data?.applied_Jobs?.reverse().slice(0, 5));
          setIsLoding(false);
          setloading(false);
        } else {
          setloading(false);
        }
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };
  //---------------- Group ----------------
  const [loading2, setloading2] = useState(true);
  const [groups, setGroups] = useState([]);
  const getGroups = async () => {
    try {
      const result = await axiosAPI.post("/getMyGroupsList");
      const { data, status } = result.data;
      if (status) {
        setGroups(data);
        setloading2(false);
      } else {
        setloading2(false);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };

  //---------------- Forum ----------------
  const [loading3, setloading3] = useState(true);

  const [myForum, setMyForum] = useState([]);
  const getMyForum = async () => {
    try {
      const result = await axiosAPI.post("/getMyForumList");
      const { data, status } = result.data;
      if (status) {
        setMyForum(data);
        setloading3(false);
      } else {
        setloading3(false);
      }
    } catch (error) {
      //// console.log(error.message);
    }
  };
  //---------------- function to check account activation----------------

  useEffect(() => {
    if (resettedByAdmin === "1") {
      setopenPasswordReset(true);
    }
    routeChange(Math.random());
    getRecentJobs();
    getGroups();
    getMyForum();
    window.scrollTo(0, 0);
  }, []);

  const Labels = useSelector((state) => state.allLabels.labels);
  const viewDetailedGroup = (id) => {
    redirect(`/groupDetail/${id}`);
  };
  const viewDetailedForum = (id) => {
    redirect(`/forumDetail/${id}`);
  };
  // -------------------openpwd change modal------------------
  // const handleOpenPasswordReset = () => {
  //   setopenPasswordReset(true);
  // };
  //// console.log("resettedByAdmin:", resettedByAdmin);
  const screenWidth = useScreenWidth();
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          position: screenWidth > 1535 ? "fixed" : "relative",
          width: screenWidth > 1535 ? "82vw" : "auto",
        }}
      >
        <Grid item xs={12} lg={7}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  borderRadius: 4,
                  margin: "10px 0",
                  padding: 3,
                  boxShadow: "none",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    className="icon-box"
                    style={{ background: "#BCFFC6", borderRadius: 8 }}
                  >
                    <WorkOutlineOutlinedIcon sx={{ color: "#219032" }} />
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <Typography variant="h5" sx={{ color: "#219032" }}>
                      {appliedjobcount}
                    </Typography>
                    <Typography variant="body1" sx={{ color: "#656464" }}>
                      {recentJobs.length == 1
                        ? Labels?.appliedJob
                        : Labels?.appliedJobs}
                    </Typography>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  borderRadius: 4,
                  margin: "10px 0",
                  padding: 3,
                  boxShadow: "none",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    className="icon-box"
                    style={{ background: "#FFEBEB", borderRadius: 8 }}
                  >
                    <NotificationImportantOutlinedIcon
                      sx={{ color: "#FF5252" }}
                    />
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <Typography variant="h5" sx={{ color: "#FF5252" }}>
                      {data?.noticationCount ? data?.noticationCount : "0"}
                    </Typography>
                    <Typography variant="body1" sx={{ color: "#656464" }}>
                      {data?.noticationCount == 1
                        ? Labels?.Notification
                        : Labels?.Notifications}
                    </Typography>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card
                sx={{
                  borderRadius: 4,
                  margin: "10px 0",
                  padding: 3,
                  boxShadow: "none",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    className="icon-box"
                    style={{ background: "#F3EBFF", borderRadius: 8 }}
                  >
                    <ChatOutlinedIcon sx={{ color: "#5289FF" }} />
                  </div>
                  <div style={{ textAlign: "right" }}>
                    <Typography variant="h5" sx={{ color: "#5289FF" }}>
                      {data?.ChatmessageCount ? data?.ChatmessageCount : "0"}
                    </Typography>
                    <Typography variant="body1" sx={{ color: "#656464" }}>
                      {data?.ChatmessageCount == 1
                        ? Labels?.Message
                        : Labels?.Messages}
                    </Typography>
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
          {/* ------------------ Jobs applied recently------------------- */}
          <Card
            sx={{
              borderRadius: 3,
              padding: 0,
              margin: "10px 0",
              boxShadow: "none",
            }}
          >
            <Stack direction="row" justifyContent="space-between" padding={1.5}>
              <Typography variant="h6" sx={{ color: "#106995" }}>
                {Labels?.JobsAppliedRecently}
              </Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#106995",
                  color: "white",
                  padding: "5px 7px",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#106995",
                  },
                  "&:active": {
                    backgroundColor: "#106995",
                    color: "white",
                  },
                  "&:disabled": { color: "white", backgroundColor: "#106995" },
                  boxShadow: "none",
                }}
                onClick={() => redirect("/appliedJobs")}
              >
                {Labels?.viewAll}
              </Button>
            </Stack>

            <Divider />
            <Stack
              style={{
                height: "62.2vh",
                overflowY: "auto",
                overflowX: "hidden",
                padding: "5px 0",
              }}
              className={classes.customScrollbar}
            >
              {loading ? (
                <>
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={450}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={450}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={450}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={450}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={450}
                    style={{ margin: "10px auto" }}
                  />
                </>
              ) : recentJobs.length === 0 ? (
                <Typography variant="body1" sx={{ p: 1 }}>
                  {Labels?.NoJobsApplied}
                </Typography>
              ) : (
                recentJobs.slice(0, 5).map((data) => (
                  <Box
                    key={data?.eja_id}
                    sx={{
                      border: "1px solid #F2F2F2",
                      borderRadius: "4px",
                      padding: 1,
                      margin: "10px 0", // Added vertical margin for space between boxes
                      cursor: "pointer",
                    }}
                    // onClick={() => redirect(`/detailedJobView/${data.eja_id}`)}
                  >
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Avatar
                        src={`${data?.companyLogo}`}
                        sx={{
                          width: screenWidth > 480 ? 80 : 40,
                          height: screenWidth > 480 ? 80 : 40,
                          borderRadius: "15px",
                          margin: "20px",
                          border: "1px solid #F2F2F2",
                        }}
                      />
                      <Box sx={{ flexGrow: 1 }}>
                        <Stack
                          direction={{ xs: "column", sm: "row" }}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          spacing={2}
                        >
                          <Box>
                            <Typography
                              variant="h6"
                              sx={{ fontWeight: 500, color: "#106995" }}
                            >
                              {data?.jobName.length > 15
                                ? data.jobName.slice(0, 13) + "..."
                                : data.jobName}
                            </Typography>
                            <Typography variant="h7" sx={{ fontWeight: 500 }}>
                              {data?.employer.length > 20
                                ? data.employer.slice(0, 18) + "..."
                                : data.employer}
                            </Typography>
                            {data?.experianceYears === 0 ? (
                              <Typography
                                variant="subtitle2"
                                sx={{ color: "#106995", mb: 1 }}
                              >
                                {Labels?.Fresher}
                              </Typography>
                            ) : (
                              <Typography variant="subtitle2">
                                {data?.experianceYears +
                                  " " +
                                  Labels?.YearsExperience}
                              </Typography>
                            )}
                          </Box>
                          <Box sx={{ alignItems: "center", display: "flex" }}>
                            {" "}
                            {data?.jobCategory && (
                              <Stack direction="row" alignItems="center">
                                <Chip
                                  label={data.jobCategory}
                                  size="medium"
                                  sx={{
                                    backgroundColor: "#F3FBFF",
                                    color: "#005F8E",
                                    // background: "#FFFFFF",
                                    fontSize: "15px",
                                    borderRadius: 1,
                                    border: "1px solid #005F8E",
                                    width: "100px", // Set your desired fixed width
                                    whiteSpace: "normal", // or "pre-wrap" if you want to preserve whitespace
                                    wordWrap: "break-word", // Allows long words to break and wrap onto the next line
                                  }}
                                />
                              </Stack>
                            )}
                          </Box>
                          <Box sx={{ alignItems: "center", display: "flex" }}>
                            <Button
                              sx={{
                                color: "#106995",
                                padding: "5px 7px",
                                textTransform: "capitalize",
                                "&:hover": {
                                  color: "#000000",
                                },
                                "&:active": {
                                  color: "106995",
                                },
                                "&:disabled": { color: "106995" },
                                boxShadow: "none",
                                marginRight: 4,
                              }}
                              onClick={() =>
                                redirect(`/detailedJobView/${data.eja_id}`)
                              }
                            >
                              view
                            </Button>
                          </Box>
                        </Stack>
                      </Box>
                    </Stack>
                  </Box>
                ))
              )}
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} lg={5}>
          {/* ------------------ Forum------------------- */}
          <Card
            sx={{
              borderRadius: 3,
              padding: 0,
              margin: "10px 0",
              boxShadow: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 10,
              }}
            >
              <Typography variant="h6" sx={{ color: "#106995" }}>
                {Labels?.Forums}
              </Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#106995",
                  color: "white",
                  padding: "5px 7px",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#106995",
                  },
                  "&:active": {
                    backgroundColor: "#106995",
                    color: "white",
                  },
                  "&:disabled": { color: "white", backgroundColor: "#106995" },
                  boxShadow: "none",
                }}
                onClick={() => redirect("/forum")}
              >
                {Labels?.viewAll}
              </Button>
            </div>
            <Divider />
            <Box sx={{ height: "35vh" }}>
              {loading2 ? (
                <>
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                </>
              ) : myForum.length === 0 ? (
                <Typography variant="body1" sx={{ p: 2, color: "#84827D" }}>
                  {Labels?.noForumFound}
                </Typography>
              ) : (
                myForum.slice(0, 5).map((val, i) => (
                  <Card
                    key={i}
                    sx={{
                      borderRadius: 1,
                      boxShadow: "none",
                      borderBottom: "1px solid #106995",
                      cursor: "pointer",
                      p: 0.5,

                      "&:hover": {
                        backgroundColor: "#ffffffe5",
                      },
                    }}
                    onClick={() => viewDetailedForum(val.forumID)}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Avatar
                          src={
                            val.logo === null || ""
                              ? `${dummyLogo}`
                              : `${val.logo}`
                          }
                          alt={val.forumName}
                          style={{
                            maxWidth: 30,
                            maxHeight: 30,
                          }}
                        />
                        <Stack>
                          <Typography
                            variant="subtitle1"
                            sx={{ color: "#000000", fontWeight: 600 }}
                          >
                            {val.forumName}
                          </Typography>
                          <Stack direction="row" alignItems="center">
                            <PublicIcon sx={{ fontSize: 17 }} />
                            <Typography
                              variant="body2"
                              sx={{ color: "#106995" }}
                            >
                              &nbsp;{val.forumTopic}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                      <Stack>
                        <Typography variant="caption">
                          {val.createdOn}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Card>
                ))
              )}
            </Box>
          </Card>
          {/* ------------------ Group------------------- */}
          <Card
            sx={{
              borderRadius: 3,
              padding: 0,
              margin: "10px 0",
              boxShadow: "none",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 10,
              }}
            >
              <Typography variant="h6" sx={{ color: "#106995" }}>
                {Labels?.Groups}
              </Typography>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#106995",
                  color: "white",
                  padding: "5px 7px",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#106995",
                  },
                  "&:active": {
                    backgroundColor: "#106995",
                    color: "white",
                  },
                  "&:disabled": { color: "white", backgroundColor: "#106995" },
                  boxShadow: "none",
                }}
                onClick={() => redirect("/myGroupList")}
              >
                {Labels?.viewAll}
              </Button>
            </div>
            <Divider />
            <Box sx={{ height: "35vh" }}>
              {loading3 ? (
                <>
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width={`98%`}
                    height={60}
                    style={{ margin: "10px auto" }}
                  />
                </>
              ) : groups.length === 0 ? (
                <Typography variant="body1" sx={{ p: 2, color: "#84827D" }}>
                  {Labels?.noGroupFound}
                </Typography>
              ) : (
                groups.slice(0, 5).map((val, i) => (
                  <Card
                    key={i}
                    sx={{
                      borderRadius: 1,
                      boxShadow: "none",
                      borderBottom: "1px solid #106995",
                      cursor: "pointer",
                      p: 0.5,

                      "&:hover": {
                        backgroundColor: "#ffffffe5",
                      },
                    }}
                    onClick={() => viewDetailedGroup(val.id)}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Avatar
                          src={`${val.grpLogoPath}`}
                          alt=" "
                          style={{
                            maxWidth: 30,
                            maxHeight: 30,
                          }}
                        />
                        <Stack>
                          <Typography
                            variant="subtitle1"
                            sx={{ color: "#000", fontWeight: 600 }}
                          >
                            {val.groupName}
                          </Typography>
                          <Stack direction="row" alignItems="center">
                            <PublicIcon sx={{ fontSize: 17 }} />
                            <Typography
                              variant="body2"
                              sx={{ color: "#106995" }}
                            >
                              &nbsp;{val.categoryName}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Stack>
                      <Stack>
                        <Typography variant="caption">
                          {val.createdOn}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Card>
                ))
              )}
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}
