import React, { useState, Fragment } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import LoginIcon from "@mui/icons-material/Login";
import LanguageIcon from "@mui/icons-material/Language";
import WorkIcon from "@mui/icons-material/Work";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, Select } from "@mui/material";
import { useEffect } from "react";
import { getAllLabels } from "../../redux/AllLabels";

export default function TemporaryDrawer() {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    right: false,
  });
  const postData = {
    language: "en",
    search: "",
    skills: "",
    city: "",
    category: "",
    type: "",
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  // ---------------------Language-------------------
  const [value, setValue] = useState("");
  const [languageCode, setLanguageCode] = useState("");
  const Language = useSelector((state) => state.getAllLanguage.languages);

  const switchLanguage = (e) => {
    setValue(e.target.value);
    const selectedLanguage = Language.find(
      (data) => data.language === e.target.value
    );
    localStorage.setItem("language", selectedLanguage.languagecode);
    setLanguageCode(selectedLanguage.languagecode);
  };
  const setLanguage = () => {
    const language = localStorage.getItem("language");
    if (language) {
      setLanguageCode(language);
      if (Language) {
        const selectedLanguage = Language.find(
          (data) => data.languagecode === language
        );
        setValue(selectedLanguage.language);
      }
    } else {
      setValue("English");
      setLanguageCode("en");
    }
  };

  useEffect(() => {
    setLanguage();
    dispatch(getAllLabels(languageCode));
  }, [languageCode]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showSubMenu_1, setShowSubMenu_1] = useState(false);
  const redirect = useNavigate();
  const redirecttoHome = () => {
    redirect("/landingpage");
  };

  const redirecttoAboutUs = () => {
    redirect("/about");
  };

  const redirecttoJobs = () => {
    redirect("/jobSearchPrelogin", { state: postData });
  };
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 300 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={redirecttoHome}>
            <ListItemIcon sx={{ color: "#000" }}>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText
              sx={{
                color: "#005F8E",

                fontSize: "18px",
              }}
              primary={Labels?.Home}
            />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton onClick={redirecttoAboutUs}>
            <ListItemIcon sx={{ color: "#000" }}>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText
              sx={{
                color: "#005F8E",

                fontSize: "18px",
              }}
              primary={Labels?.AboutUs}
            />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton onClick={redirecttoJobs}>
            <ListItemIcon sx={{ color: "#000" }}>
              <WorkIcon />
            </ListItemIcon>
            <ListItemText
              sx={{
                color: "#005F8E",

                fontSize: "18px",
              }}
              primary={Labels?.Jobs}
            />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon sx={{ color: "#000", minWidth: "45px" }}>
              <LanguageIcon />
            </ListItemIcon>
            <Select
              value={value}
              onChange={(e) => switchLanguage(e)}
              sx={{
                color: "#005F8E",
                padding: "0px 0px!important",
                minWidth: 120,
                ".MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
            >
              {Language &&
                Language.map((val, index) => {
                  return (
                    <MenuItem key={index} value={val.language}>
                      {val.language}
                    </MenuItem>
                  );
                })}
            </Select>
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => redirect("/login")}>
            <ListItemIcon sx={{ color: "#000" }}>
              <LoginIcon />
            </ListItemIcon>
            <ListItemText
              sx={{
                color: "#005F8E",

                fontSize: "18px",
              }}
              primary={Labels?.signIn}
            />
          </ListItemButton>
        </ListItem>
      </List>
      <List sx={{ paddingTop: 0 }}>
        <ListItem disablePadding>
          <ListItemButton onClick={() => redirect("/signUp")}>
            <ListItemIcon sx={{ color: "#000" }}>
              <AppRegistrationIcon />
            </ListItemIcon>
            <ListItemText
              sx={{
                color: "#005F8E",

                fontSize: "18px",
              }}
              primary={Labels?.signUp}
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            {<MenuIcon sx={{ color: "#005F8E", fontSize: "40px" }} />}
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </Fragment>
      ))}
    </div>
  );
}
