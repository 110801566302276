import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { Box } from "@mui/material";

export default function AllLanguageJobSeeker({
  value,
  setValue,
  label,
  style,
  languageName,
  setLanguageName,
}) {
  const [languages, setLanguages] = useState([]);
  useEffect(() => {
    const getAllLanguages = async () => {
      const result = await axiosAPI.get("/getAllLanguages");
      const { success, data } = result.data;
      if (success === true) {
        setLanguages(data);
      } else {
        setLanguages([]);
      }
    };
    getAllLanguages();
  }, []);
  const handleChange = (e) => {
    setValue(e.target.value);
    if (languageName) {
      const selectedLanguage = languages.find(
        (language) => language.l_id === e.target.value
      );
      setLanguageName(selectedLanguage?.language);
    }
  };

  return (
    <FormControl sx={{ width: "100%"}}>
      <Select
        value={value}
        placeholder={label}
        displayEmpty
        variant="outlined"
        onChange={handleChange}
        sx={{
          padding: "10px 12px",
          fontFamily: "Poppins",
          ...style, borderColor: "#005F8E", // Change border color
        
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: '#005F8E',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#005F8E',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#000000',
          },
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: `300px`,
            },
          },
        }}
      >
        <MenuItem value="" disabled>
          <span style={{ color: "#bcbcbc" }}>{label}</span>
        </MenuItem>
        {languages &&
          languages.map((val, index) => {
            return (
              <MenuItem key={index} value={val.l_id}>
                {val.language}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}
