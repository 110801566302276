import React from "react";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import "../Landinpage1/Style.css";
import { Icon, Stack } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import { useState } from "react";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === "light" ? "white" : "white",
    border: "0.5px solid #106995",
    borderColor: "#106995",
    padding: "10px 12px",
    fontSize: "14px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['"Poppins"'].join(","),
    "&:focus": {
      border: "0.5px solid #106995",
      boxShadow: "#d6edf7",
      borderColor: "#106995",
    },
  },
}));
const textStyle = {};
function IconLabelTextField({
  InputLabel,
  label,
  style,
  type,
  name,
  value,
  onChange,
  disabled,
  inputProps,
  onKeyDown,
  readOnly,
  required,
  errorMsg,
  multiline,
  rows,
  icon,
  display,
  countryCode,
  handleOnChange,
}) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ my: 1.5 }}
    >
      {icon ? (
        <Icon sx={{ color: "#000000", mr: 1, height: 25, width: 25 }}>{icon}</Icon>
      ) : null}
      <label style={{ color: "#106995", width: 230 }}>{InputLabel}</label>
      {required ? <span style={{ color: "red" }}>*</span> : null}
      {display ? (
        <PhoneInput
          country={"cr"}
          style={{ width: "20%", marginRight: "10px" }}
          inputProps={{ required: true, readOnly: true }}
          value={countryCode}
          onChange={handleOnChange}
          readOnly
        />
      ) : null}

      <BootstrapInput
        type={type}
        sx={{ ...textStyle, ...style }}
        placeholder={label}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        inputProps={inputProps}
        onKeyDown={onKeyDown}
        multiline={multiline}
        rows={rows}
        readOnly={readOnly}
      />
      <br />
      {errorMsg
        ? errorMsg && (
            <span style={{ color: "#ff0000", fontSize: "13px" }}>
              {errorMsg}
            </span>
          )
        : null}
    </Stack>
  );
}

export default IconLabelTextField;
