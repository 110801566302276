import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { useSelector } from "react-redux";
import { profanityfn } from "../Profanity/Profanity";

export default function JobPostingDuration({
  value,
  setValue,
  placeHolder,
  style,
}) {
  const ProfanityList = useSelector((state) => state.Profanity.wordlist);

  const handleChange = (event) => {
    setValue(profanityfn(ProfanityList, event.target.value));
  };
  const usertype = localStorage.getItem("userType");
  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <Fragment>
      <FormControl sx={{ width: "100%" }}>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          value={value ? value : ""}
          onChange={handleChange}
          fullWidth
          displayEmpty
          // readOnly={usertype === "Recruiter" ? true : false}
          variant="outlined"
          sx={{
            padding: "10px 12px",
            fontSize: "14px",
            fontFamily: "Poppins",
            borderColor: "#005F8E", // Change border color
          
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: '#005F8E',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#005F8E',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#000000',
              },
              // '.MuiSvgIcon-root ': {
              //   fill: "white !important",
              // },
            ...style,
          }}
        >
          <MenuItem disabled value="">
            <span style={{ color: "#bcbcbc" }}>{Labels.JobPostDuration} </span>
          </MenuItem>
          <MenuItem value="1">1 {Labels.Week}</MenuItem>
          <MenuItem value="2">2 {Labels.Week}</MenuItem>
          <MenuItem value="3">3 {Labels.Week}</MenuItem>
          <MenuItem value="4">4 {Labels.Week}</MenuItem>
          <MenuItem value="5">5 {Labels.Week}</MenuItem>
          <MenuItem value="6">6 {Labels.Week}</MenuItem>
          <MenuItem value="7">7 {Labels.Week}</MenuItem>
          <MenuItem value="8">8 {Labels.Week}</MenuItem>
        </Select>
      </FormControl>
    </Fragment>
  );
}
