import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "white" : "white",
    border: "0.5px solid #106995",
    borderColor: "#106995",
    fontSize: 16,
    minWidth:"270px",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ['"Poppins"'].join(","),
    "&:focus": {
      border: "0.5px solid #106995",
      boxShadow: "#d6edf7",
      borderColor: "#106995",
    },
  },
}));
const textStyle = {};

const TextfieldCustom = ({
  label,
  style,
  type,
  name,
  value,
  onChange,
  disabled,
  inputProps,
  onKeyDown,
  rows,
}) => {
  return (
    <Fragment>
      <BootstrapInput
        type={type}
        sx={{ ...textStyle, ...style }}
        placeholder={label}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        inputProps={inputProps}
        onKeyDown={onKeyDown}
        rows={rows}
      />
    </Fragment>
  );
};

export default TextfieldCustom;
