import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { getAllLanguage } from "../../redux/getLanguageSlice";
import {profanityfn}  from "../Profanity/Profanity";

const Languages = ({ value, setValue, style }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllLanguage());
  }, []);
  const Language = useSelector((state) => state.getAllLanguage.languages);
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)
  const Labels = useSelector((state) => state.allLabels.labels);

  const handleChange = (event) => {
    setValue(profanityfn(ProfanityList,event.target.value));
  };
  return (
    <Select
      value={value}
      onChange={handleChange}
      fullWidth
      variant="outlined"
      sx={{
        padding: "10px 12px",
        fontFamily: "Poppins",
        borderColor: "#005F8E", // Change border color
        
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: '#005F8E',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#005F8E',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#000000',
        },
        // '.MuiSvgIcon-root ': {
        //   fill: "white !important",
        // },
      ...style,
    }}
      displayEmpty
    >
      <MenuItem value="" disabled sx={{ color: "#C9C9C9" }}>
        <span style={{ color: "#bcbcbc" }}>{Labels.Selectalanguage}</span>
      </MenuItem>
      {Language &&
        Language.map((val, index) => {
          return (
            <MenuItem key={index} value={val.languagecode}>
              {val.language}
            </MenuItem>
          );
        })}
    </Select>
  );
};

export default Languages;
