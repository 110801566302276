import {
  Box,
  Chip,
  IconButton,
  InputAdornment,
  Dialog,
  Modal,
  TextField,
  Stack,
  Typography,
} from "@mui/material";
import React, { Fragment, useRef, useState,useEffect } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Button, Textarea } from "@mui/joy";
import { errorNotify, warningNotify,succesNotify } from "../../CommonCode/Commonfunc";
import { axiosAPI, axiosupload } from "../../Axios/Axios";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import TextfieldCustom from "../../Components/TextfieldCustom";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {profanityfn,profanitySend}  from "../../Profanity/Profanity";

const useStyles = makeStyles({
  textField: {
    "& .MuiInputBase-input": {
      textAlign: "start",
      textIndent: "10px", // Adjust the textIndent value as needed
    },
  },
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};

export default function ReplayMessageModal({
  open,
  handleClose,
  data,
  setIsApiCall,
}) {
  const [message, setMessage] = useState("");
  const [isButtonDisable, setButtonDisable] = useState(false);


  const sendChatProfanity = (e) => {
    const data = {
      // "parentID": data.id,
      // "toUser": data.fromUser==1?data.fromUser:data.toUser,
      "message": message,
  };

    let profanityDetected;
    for (const key in data) {
      if (profanitySend(ProfanityList, data[key])) {
        profanityDetected = true;
        break;
      } else {
        profanityDetected = false;
      }
    }

    if (profanityDetected == false) {
      sendMessage(e);
    } else {
      warningNotify(Labels.ProfanityLabel);
    }
  };
  useEffect(()=>{
    if(!open){
      setMessage('')
    }
  },[open])
  const sendMessage = async () => {
    setButtonDisable(true);
    if (!message) {
      errorNotify(Labels.writeMessage);
      setButtonDisable(false);
      return;
    } else if (message.length > 1000) {
      errorNotify(Labels.messageValidation);
      setButtonDisable(false);
      return;
    } else {
      const result = await axiosAPI.post("/message", {
        parentID: data.id,
        toUser: data.fromUser==1?data.fromUser:data.toUser,
        message: message,
      });
      const { success, message: responseMessage } = result.data;
      setMessage("");
      if (success) {
        succesNotify(responseMessage);
        setButtonDisable(false);
        setIsApiCall(Math.random());
        handleClose(false);
      } else {
        setButtonDisable(false);
        errorNotify(responseMessage);
      }
    }
  };

  const Labels = useSelector((state) => state.allLabels.labels);
  const ProfanityList = useSelector((state)=> state.Profanity.wordlist)

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      maxWidth="xs"
      onClose={() => handleClose(false)}
      PaperProps={{
        sx: {
          width: "90%",
          padding: 3,
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ p: 2 }}
      >
        <HighlightOffIcon
          sx={{
            color: "#005F8E",
            cursor: "pointer",
          }}
          onClick={() => handleClose(false)}
        />
      </Stack>
      <Typography variant="body1" sx={{ fontWeight: 500 }}>
        {Labels?.To} :&nbsp;
        <Chip label={data?.toUserName} />
      </Typography>
      <Box
        sx={{
          margin: "10px 0px",
          maxHeight: "80vh",
          overflowY: "auto",
          overflowX: "hidden",
          p: 1.5,
        }}
      >
        <br />
        <Textarea
          minRows={5}
          placeholder={Labels.Typehere}
          value={message}
          onChange={(e) => setMessage(profanityfn(ProfanityList,e.target.value))}
        />
      </Box>
      <Stack direction="row" justifyContent="center" sx={{ p: 1.5 }}>
        <Button
          variant="solid"
          fullWidth
          sx={{
            background: "#005F8E",
            color: "white",
            borderRadius: 3,
            "&:hover": {
              backgroundColor: "#005F8E",
            },
            "&:active": {
              backgroundColor: "#005F8E",
            },
            "&:disabled": { color:"white",
              backgroundColor: "#005F8E",
            },
          }}
          disabled={isButtonDisable}
          onClick={sendChatProfanity}
        >
          {Labels?.Send} 
        </Button>
      </Stack>
    </Dialog>
  );
}
