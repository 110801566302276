import React, { Fragment, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { axiosAPI } from "../Axios/Axios";
import { useSelector } from "react-redux";

const Qualification = ({ style, value, setValue }) => {
  const [Qualifications, setQualifications] = useState([]);
  useEffect(() => {
    const getQualifications = async () => {
      const result = await axiosAPI.get("/getEducation");
      const { success, data } = await result.data;
      if (success === true) {
        setQualifications(data);
      } else {
        setQualifications([]);
      }
    };
    getQualifications();
  }, []);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const Labels = useSelector((state) => state.allLabels.labels);
  return (
    <Fragment>
      <FormControl sx={{ width: "100%" }}>
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          value={value ? value : ""}
          onChange={handleChange}
          fullWidth
          displayEmpty
          variant="outlined"
          sx={{ padding: "10px 12px", fontFamily: "Poppins", ...style ,
            borderColor: "#005F8E", // Change border color
        
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: '#005F8E',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#005F8E',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#000000',
          }, 
          }}
        >
          <MenuItem value={""} disabled >
            {Labels?.Qualifications}
          </MenuItem>
          {Qualifications &&
            Qualifications.map((val, index) => {
              return (
                <MenuItem key={index} value={val.id}>
                  {val.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </Fragment>
  );
};

export default Qualification;
